import {Card, Row, Col} from 'antd';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import React, { useEffect, useState } from 'react';
import { useDispatch,useSelector } from "react-redux";
import { Chart } from 'primereact/chart';
import moment from "moment";
const GraphMain = (props) => {
    const numberOfWeeks=moment().isoWeeksInYear();
    console.log("Number of weeks in a year====>",numberOfWeeks);
    const dashBoardData=useSelector((state)=>state.dashboardRed);
    console.log("dashboard data inside the graph main====>",dashBoardData);
    let labelHour=[];
    let dataSetHours=[];
    let labelMonth=[];
    let dataSetMonth=[];
    let labelPerMonthPerYear=[];
    let dataSetPerMonthPerYear=[];
    let labelPerYear=[];
    let dataSetPerYear=[];
    let labelPerWeek=[];
    let dataSetPerWeek=[];
    for(let i=1;i<=numberOfWeeks;i++){
        labelPerWeek.push(i.toString());
        dataSetPerWeek.push(0)
    }
    dashBoardData.totalOrdersPerWeek.forEach(item => {
        let index=item._id-1;
        dataSetPerWeek.splice(index,1,item.netOrders);
      });
    console.log("laberPerWeek=====>",labelPerWeek);
    console.log("dataSetperWeek====>",dataSetPerWeek);
    for(let i=0;i<dashBoardData.totalOrdersPerYear.length;i++){
        let value=dashBoardData.totalOrdersPerYear[i]._id;
        labelPerYear.unshift(value.toString());
        // dataSetPerYear.push(0);
    }
    dashBoardData.totalOrdersPerYear.forEach(item => {
        dataSetPerYear.unshift(item.netOrders);
      });
    console.log("labelPerYear=====>",labelPerYear);
    console.log("datasetperyear=====>",dataSetPerYear)
    // for(let i=0;i<24;i++){
    for(let i=0;i<=24;i++){
      labelHour.push(i.toString());
      dataSetHours.push(0)
    }           
    dashBoardData.totalOrdersPerHour.forEach(item => {
      dataSetHours.splice(item._id,1,item.netOrders);
    });
    for(let i=1;i<=31;i++){
        labelMonth.push(i.toString());
        dataSetMonth.push(0)
      } 
      const newDate=moment().month()+1;
      console.log("today month====>",newDate);
      if(newDate==1 || newDate==3 || newDate==5 ||  newDate==7 || newDate==10 || newDate==12){
        dataSetMonth.pop();
        labelMonth.pop()
      }
    if(newDate==2){
        dataSetMonth.splice(dataSetMonth.length - 2, 2);
        labelMonth.splice(labelMonth.length-2,2)
    }       
      dashBoardData.totalOrdersPerMonth.forEach(item => {
        let index=item._id-1;
        dataSetMonth.splice(index,1,item.netOrders);
      });
      console.log("labelMonth====>",labelMonth);
      console.log("dataSetMonths=====>",dataSetMonth);
      for(let i=1;i<13;i++){
        labelPerMonthPerYear.push(i.toString());
        dataSetPerMonthPerYear.push(0)
      }           
      dashBoardData.totalOrdersPerMonthPerYear.forEach(item => {
        let index=item._id-1;
        dataSetPerMonthPerYear.splice(index,1,item.netOrders);
      });
  const [basicData,setBasicData] = useState({
    labels:labelHour,
    datasets: [
        {
            label: "Today's Order Stats",
            data:dataSetHours,
            fill: false,
            borderColor: '#02A858',
            tension: .4
        }
    ]
});
const [basicData2,setBasicData2] = useState({
    labels:labelPerWeek,
    datasets: [
        {
            label: 'Daily Order Statistics',
            data:dataSetPerWeek,
            fill: false,
            borderColor: '#02A858',
            tension: .4
        }
    ]
});
const [basicData3,setBasicData3] = useState({
    labels:labelMonth,
    datasets: [
        {
            label: 'Weekly Order Statistics',
            data:dataSetMonth ,
            fill: true,
            borderColor: '#02A858', 
              tension: .4
        }
    ]
});   
const [basicData4,setBasicData4] = useState({
    labels:labelPerMonthPerYear,
    datasets: [
        {
            label: 'Monthly Order Statistics',
            data:dataSetPerMonthPerYear ,
            fill: true,
            borderColor: '#02A858', 
              tension: .4
        }
    ]
});
const [basicData5,setBasicData5] = useState({
    labels:labelPerYear,
    datasets: [
        {
            label: 'Yearly Order Statistics',
            data:dataSetPerYear ,
            fill: true,
            borderColor: '#02A858', 
              tension: .4
        }
    ]
});  
             
console.log("BasicData=====>",basicData);
const getLightTheme = () => {
    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: 1.5,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    return {
        basicOptions
    }
}

const { basicOptions} = getLightTheme();


  return (
    <>
      <Row className='graph-display'>
      <Col>
      <h4>Today's Order Stats</h4>
      <Card bordered={false} className="card">
      <Chart type="line" data={basicData} options={basicOptions}/>
      </Card>
      </Col>
      <Col>
      <h4>Daily Order Statistics</h4>
      <Card
      bordered={false}>
      <Chart type="line" data={basicData3} options={basicOptions}/>
      </Card>
      </Col>    
      <Col>
      <h4>Weekly Order Statistics</h4>
      <Card
      bordered={false}>
      <Chart type="line" data={basicData2} options={basicOptions}/>
      </Card>
      </Col>
      </Row>
      <Row className='graph-display graph-display-big pt-5'>
      <Col>
      <h4>Monthly Order Statistics</h4>
      <Card bordered={false} className="card">
      <Chart type="line" data={basicData4} options={basicOptions}/>
      </Card>
      </Col>   
      <Col>
      <h4>Yearly Order Statistics</h4>
      <Card bordered={false} className="card">
      <Chart type="line" data={basicData5} options={basicOptions}/>
      </Card>
      </Col>  
      </Row>
    </>
  );
};

export default GraphMain;