import { render } from "react-dom";
import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Modal, Button } from "antd";
import './points.css'

const CustomPopupConfirm = (props) => {
    const handleOk = () => {
        props.action(props.productId);
        console.log("delete this data", props.productId);
        //props.isPopup('false')
      };
    
      const handleCancel = () => {
        props.cancel();
        console.log("cancel delete this data",)
      };

  return (
    <div className="abcd">
     <Modal
     className="abcd"
      title="Confirmation"
      visible={props.visible}
      footer={null}
    //   onOk={handleOk}
      onCancel={handleCancel}
    >
      <p><bold>Are you sure you want to delete this points?</bold></p>
      <Button className="model-button" onClick={()=> handleOk()}>Ok</Button>
      <Button className="model-button" onClick={()=>handleCancel()}>Cancel</Button>
    </Modal>
    </div>
  )
}

export default CustomPopupConfirm
