import moment from "moment";
const formatDateTwoWeeks=(date)=>{
    let copyDate=date.clone();
     let startDate=date.subtract(13, "days");
     startDate=startDate.startOf("day").toString();
     startDate=moment(startDate).format();
    let  endDate=copyDate.endOf("day").toString();
    endDate=moment(endDate).format();    
    let formattedDate={
        startDate:startDate,
        endDate:endDate
    }

    return formattedDate;
 }
 
 export default formatDateTwoWeeks;