import React,{useState} from "react";
import { Form, Input} from 'antd';
import "antd/dist/antd.css";
import { Modal, Button } from "antd";
import endpoint from "../../../helpers/Endpoint";
import axios from "axios";
import openNotification from "../../../commonFuncs/notification";

const OTPVerificationModal= (props) => {
  let inputForm={
   otp:"",
   newPassword:"",
   
  }
  let errorOTP={
    otp:false,
    newPassword:false,
    formValidation:false
    
  }
  const [inputFormOTP,setInputFormOTP]=useState(inputForm)
  const [errorObjOTP,setErrorObjOTP]=useState(errorOTP);
  const [show, setShow] = useState(props.visible);
  const handleCancel=()=>{
    setShow(false);
  }
const loginHandle=async(e)=>
    {
      e.preventDefault();
    let formValidation=true;
      for(let key in inputFormOTP){
       if(inputFormOTP[key]==""){
        setErrorObjOTP((prevObj)=>({...prevObj,[key]:true}));
        formValidation=false;
       }
       else{
        setErrorObjOTP((prevObj)=>({...prevObj,[key]:false}));
       }
     }
    let result;
    console.log("formValidation----->",formValidation);
    if(formValidation){
      
       try{
        result=await axios.post(`${endpoint}/api/v1/treeDriveAdmin/appadmin/resetforgetpassword`,{
          email:props.email,
          otp:inputFormOTP.otp,
          newPassword:inputFormOTP.newPassword
        });
      
      
        console.log("result in reset password----->",result);
        if(result.data.status===200){
          props.modalClose1();
          openNotification("success","Success",result.data.message)
        }else if(result && result.data.status!=200){
          openNotification("error","Error",`${result.data.message}`)
        }
       }catch(err){
        console.log("errr----->",err);
        openNotification("error","Error Occured",err.message)
       }
     }
    }
  return (
    <Modal 
      title=" Create New Password"
      visible={props.visible}
      // visible={show}
      onCancel={props.handleCancelOTP}
      footer={[
        <Button key="submit" type="primary" onClick={loginHandle}>
        Submit
      </Button>,
        
      ]}
    >
      
       <Form.Item 
        label="OTP Code"
        name="otp"
        placeholder="Select a option and change input text above"
        value={inputFormOTP.otp} 
        onChange={(e)=>{
          console.log(e.target.value)
          setInputFormOTP((prevState)=>({
            ...prevState,otp:e.target.value
          }))
          }} 
        rules={[
          {
            required: true,
            message: 'Please input your OTP!',
          },
        ]}
      >
        <Input />
        {errorObjOTP.otp?<span style={{color:"red"}}>Fill the OTP</span>:""}
      </Form.Item>
      <Form.Item 
        // name="Create New Password"
        label="New Password"
        name="password"
        value={inputFormOTP.newPassword} 
        onChange={(e)=>{
          console.log(e.target.value)
          setInputFormOTP((prevState)=>({
            ...prevState,newPassword:e.target.value
          }))
          }} 
        rules={[
          {
            required: true,
            message: 'Please input your new password!',
          },
        ]}
      >
        <Input.Password />
        {errorObjOTP.newPassword?<span style={{color:"red"}}>Fill New Password</span>:""}
      </Form.Item>
    </Modal>
  );
};
export default OTPVerificationModal;
