// import moment from "moment";
// const formatDate=(date)=>{
//    let startDate=date.startOf("day").toString();
//     startDate=moment(startDate).format();
//    let  endDate=date.endOf("day").toString();
//     endDate=moment(endDate).format();
//    let formattedDate={
//        startDate:startDate,
//        endDate:endDate
//    }
//    return formattedDate;
// }

// export default formatDate;

import moment from "moment";
const formatDate=(date,dmy)=>{
   let startDate=date.startOf(dmy).toString();
    startDate=moment(startDate).format();
   let  endDate=date.endOf(dmy).toString();
    endDate=moment(endDate).format();
   let formattedDate={
       startDate:startDate,
       endDate:endDate
   }
   return formattedDate;
}

export default formatDate;