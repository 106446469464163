import React, { useState } from "react";
import { Row, Col, Input, Button, Form, Select, notification } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import api from "../../commonFuncs/api";
import { City } from "country-state-city";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};
export default function AddCity() {
  let navigate = useNavigate();
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState("");
  let cities = City.getCitiesOfCountry("NO");

  console.log("all cities ", cities);

  const onChange = (value) => {
    console.log(`selected VALUE IS  ${value}`);
    cities.filter((val) => {
      if (val.name.toLowerCase() == value.toLowerCase()) {
        setLatitude(val.latitude);
        setLongitude(val.longitude);
        setStateCode(val.stateCode);
        setCountryCode(val.countryCode);
      }
    });
    setSelectedCity(value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const addCityApi = async (data) => {
    const token = localStorage.getItem("token");

    const res = await api.post(
      `/api/v1/treeDriveAdmin/appsettings/TFLcityAdded`,
      data,
      {
        headers: {
          "x-auth-token": token
        }
      }
    );
    if (res.status == 200) {
      notification.open({
        message: "Your city saved successfully!",
        duration: 3
      });
      navigate("/citylist");
    }
  };

  const AddCityCharges = async () => {
    let data = {};
    data.name = selectedCity;
    data.latitude = latitude;
    data.longitude = longitude;
    data.stateCode = stateCode;
    data.countryCode = countryCode;
    data.deliveryCharges = deliveryCharges;
    addCityApi(data);
  };

  return (
    <div className="add-r-main bg-white pb-2">
      <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
        <Col>
          <h3>Add New City</h3>
        </Col>
        <Col>
          <NavLink to="/citylist" className="common-btn">
            Return to the List
          </NavLink>
        </Col>
      </Row>
      <div className="form-r-main p-3">
        <Form {...layout} name="nest-messages" className="add-r-form p-3">
          <Row>
            <Col className="col-6">
              <Form.Item
                name="city-name"
                rules={[
                  {
                    required: true,
                    message: "Please input City Name!"
                  }
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select City"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {cities.map((city) => (
                    <Option key={city._id} value={city.name} label={city.name}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="col-6">
              <Form.Item
                name="Delivery Charges"
                rules={[
                  {
                    required: true,
                    message: "The delivery Charges is not valid !"
                  }
                ]}
              >
                <Input
                  placeholder="Delivery Charges"
                  onChange={(e) => setDeliveryCharges(e.target.value)}
                  type="Number"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className=" justify-content-center">
            <Col className="col-6">
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
                <Button
                  type="primary"
                  onClick={AddCityCharges}
                  htmlType="submit"
                >
                  Add
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
