import React, { useEffect, useRef, useState } from "react";
import { Input, Table, Button, notification, Row, Icon, Col, Form } from "antd";
import api from "../../commonFuncs/api";
import { NavLink, useNavigate } from "react-router-dom";
const { TextArea } = Input;

const AddDelivery = () => {
  let navigate = useNavigate();
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [price, setprice] = useState("");
  const [minWeight, setMinWeight] = useState("");
  const [maxWeight, setMaxWeight] = useState("");
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");
  const [validation, setValidation] = useState(false);
  let [FileError, setFileError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  // /addTFLProducts
  const addValue2 = async (data) => {
    if (data) {
      const token = localStorage.getItem("token");

      const res = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/addDeliveryCharges`,
        data,
        {
          headers: {
            "x-auth-token": token
          }
        }
      );
      if (res.status == 200) {
        notification.open({
          message: "Your information saved successfully!",
          duration: 3
        });
        navigate("/postenCharges");
      }
    } else {
      setFileError("**please select valid data.");
    }
  };

  const addValue = () => {
    let data = {};
    let dimensions = {};
    dimensions.length = length;
    dimensions.breadth = breadth;
    dimensions.height = height;

    let weight = {};
    weight.min = minWeight * 1000;
    weight.max = maxWeight * 1000;

    data.type = type;
    data.weight = weight;
    data.description = description;
    data.price = price;
    data.dimensions = dimensions;

    addValue2(data);
  };

  return (
    <div className="user-list">
      <div className="add-r-main bg-white pb-2">
        <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
          <Col>
            <h3>
              Add Delivery
              <br />
              Charges
            </h3>
          </Col>
          <Col>
            <NavLink to="/postenCharges" className="common-btn">
              Return to the List
            </NavLink>
          </Col>
        </Row>

        <div className="form-r-main p-2">
          <Form onFinish={handleSubmit} className="form-horizontal box">
            <Row gutter={24} className="flex">
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="type"
                  label="Posten Type"
                  rules={[
                    {
                      required: true,
                      message: "type is required!"
                    }
                  ]}
                >
                  <Input
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                    type="Text"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="price"
                  label="Delivery Charges"
                  rules={[
                    {
                      required: true,
                      message: "price is required!"
                    }
                  ]}
                >
                  <Input
                    onChange={(e) => setprice(e.target.value)}
                    value={price}
                    type="Number"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="weight"
                  label="Weight(in kg)"
                  rules={[
                    {
                      required: true,
                      message: "Weight is required!"
                    }
                  ]}
                >
                  <div className="flex">
                    <Input
                      onChange={(e) => setMinWeight(e.target.value)}
                      value={minWeight}
                      placeholder="minimum weight"
                      type="Number"
                    />
                    <Input
                      onChange={(e) => setMaxWeight(e.target.value)}
                      value={maxWeight}
                      placeholder="maximum weight"
                      type="Number"
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="dimesions"
                  label="Maximum Size(in cm)"
                  rules={[
                    {
                      required: true,
                      message: "Product Dimensions is required!"
                    }
                  ]}
                >
                  <div className="flex">
                    <Input
                      onChange={(e) => setLength(e.target.value)}
                      value={length}
                      placeholder="length"
                      type="Number"
                    />
                    <Input
                      onChange={(e) => setBreadth(e.target.value)}
                      value={breadth}
                      placeholder="breadth"
                      type="Number"
                    />
                    <Input
                      onChange={(e) => setHeight(e.target.value)}
                      value={height}
                      placeholder="height"
                      type="Number"
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Description is required!"
                    }
                  ]}
                >
                  <TextArea
                    rows={4}
                    allowClear
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row align="center">
            <Col>
              <Button
                type="primary"
                className="city-search m-4"
                onClick={() => addValue()}
              >
                ADD
              </Button>{" "}
              {FileError.length > 0 ? (
                <p className="errorMsg">{FileError} </p>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddDelivery;
