import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Space,
  DatePicker,
  Select,
  Alert,
  Popover,
  Switch
} from "antd";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchOrdersPerRestaurant } from "./../../reducers/restuarants/orderPerResRed";
import { fetchOrdersTotalPerRestaurant } from "./../../reducers/restuarants/orderTotalPerResRed";
import api from "../../commonFuncs/api";
import startEndDay from "./../../commonFuncs/moment/endStart";
import formatDateTwoWeeks from "./../../commonFuncs/moment/endStartTwoWeeks";
const OrderPerRestaurant = (props) => {
  const [option, setOption] = useState("ALL");
  const [optionChange, setOptionChange] = useState(false);
  const [dateChange, setDateChange] = useState(false);
  const [dateSelected, setDateSelected] = useState(moment(new Date()));
  const [startDate, setStartDate] = useState(
    moment(dateSelected.startOf("day").toString()).format()
  );
  const [endDate, setEndDate] = useState(
    moment(dateSelected.endOf("day").toString()).format()
  );

  const [editedOrders, setEditedOrders] = useState([]);

  let navigate = useNavigate();
  let location = useLocation();
  console.log("location in per res======>", location.state);
  let branchId = location.state.singleRestaurantId;
  const branchName = location.state.singleRestaurantName;
  const branchLogo = location.state.logo;
  const orders = useSelector((state) => state.ordersPerRestaurant);
  const orderTotal = useSelector((state) => state.ordersTotalPerRes);
  console.log("orderTotal in reducer=====>", orderTotal);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchOrderPerRestaurant();
    fetchOrderTotalPerRestaurant();
  }, [dateChange, optionChange]);
  const fetchOrderPerRestaurant = async () => {
    try {
      let result = await api.post(
        `/api/v1/treeDriveAdmin/restaurant/perrestaurantorder`,
        {
          branchId: branchId,
          startDate: startDate,
          endDate: endDate,
          orderStatus: option
        }
      );
      console.log("result in result per order ====>", result);
      dispatch(fetchOrdersPerRestaurant(result.data.data.orders));

      let resOrders = result.data.data.orders;

      let varEditedOrders = [];

      resOrders.map((e) => {
        let beforeTax = 0;
        let afterTax = 0;
        let tdNetProfit = 0;
        let resIncome = 0;

        let feePerKm = 0;

        let percentVal =
          (e.tdFeesFromRestaurant / 100) * e.totalPrice +
          (e.tdFeesFromRestaurant / 100) * e.totalPrice * 0.25;

        let orcFeesFromRestaurantAbsCalc =
          (e.orcFeesFromRestaurant / 100) * e.totalPrice +
          (e.orcFeesFromRestaurant / 100) * e.totalPrice * 0.25;

        console.log(
          "---tdfeesfromrest, orcfeesabscalc----",
          percentVal,
          orcFeesFromRestaurantAbsCalc
        );

        if (e.orderType == "Drive Out" && e.iDriveMyself == false) {
          // beforeTax = percentVal + e.tdServiceCharge + e.tdDriveOutCharge + e.tdDiffBetIncOriginal;
          beforeTax =
            percentVal +
            e.tdServiceCharge +
            e.tdDriveOutCharge +
            e.tdEnhPercentage +
            orcFeesFromRestaurantAbsCalc +
            e.tdStaticDriveOut;
          afterTax = beforeTax / 1.25;

          //tdNetProfit = afterTax - e.tdDriveOutCharge;
          tdNetProfit = afterTax - e.DriverEarningInThisOrder?.earning;
          resIncome = e.totalPrice - afterTax;

          //e.driverFee.extraFreePerKm, e.driverFee.flatRateDistance

          if (e.driverFee.flatRateDistance) {
            let distanceForDriver = e.distance - e.driverFee.flatRateDistance;
            feePerKm =
              distanceForDriver > 0
                ? distanceForDriver * e.driverFee.extraFeePerKm
                : 0;
          }
        }

        if (e.orderType == "Drive Out" && e.iDriveMyself == true) {
          beforeTax =
            percentVal +
            e.tdServiceCharge +
            e.tdEnhPercentage +
            orcFeesFromRestaurantAbsCalc;
          afterTax = beforeTax / 1.25;

          tdNetProfit = afterTax; //- e.tdDriveOutCharge;

          resIncome = e.totalPrice - afterTax;
        }

        if (e.orderType == "Take Away") {
          beforeTax =
            percentVal +
            e.tdServiceCharge +
            e.tdEnhPercentage +
            orcFeesFromRestaurantAbsCalc;
          afterTax = beforeTax / 1.25;

          tdNetProfit = afterTax - e.tdDriveOutCharge;
          // tdNetProfit = 0;

          resIncome = e.totalPrice - afterTax;
        }

        let t = {
          ...e,
          beforeTax: beforeTax,
          afterTax: afterTax,
          tdNetProfit: tdNetProfit,
          resIncome: resIncome,
          feePerKm: feePerKm
        };

        console.log("---ORderStatus---", t.orderStatus, t.beforeTax);
        varEditedOrders.push(t);
      });

      console.log("----Edited Orders----", varEditedOrders);
      setEditedOrders(varEditedOrders);
    } catch (err) {
      console.log("error--->", err.message);
    }
  };
  const fetchOrderTotalPerRestaurant = async () => {
    try {
      let result = await api.post(
        `/api/v1/treeDriveAdmin/restaurant/restaurantordertotal`,
        {
          branchId: branchId,
          startDate: startDate,
          endDate: endDate,
          orderStatus: option
        }
      );
      console.log("result in total orderresult per order ====>", result);
      dispatch(fetchOrdersTotalPerRestaurant(result.data.data));
    } catch (err) {
      console.log("error in total order--->", err.message);
    }
  };
  // const deliveredTime=(time)=>{
  //     let hours=Math.floor(time/60);
  //     let min=time - hours*60;
  //     min=Math.ceil(min);
  //    if(time<0){
  //       hours=0;
  //       min=0;
  //    }
  //    let formatTime={
  //      hours:Math.floor(hours),
  //      minutes:min
  //    }
  //    return formatTime;
  //   }
  const onDateSelect = (date, dateString, dmy = "Bi-Weekly") => {
    let formattedDate;
    if (dmy == "Bi-Weekly") {
      formattedDate = formatDateTwoWeeks(date);
      setOption("ALL");
    } else {
      formattedDate = startEndDay(date, dmy);
    }
    setStartDate(formattedDate.startDate);
    setEndDate(formattedDate.endDate);
    setDateSelected(date);
    setDateChange(!dateChange);
  };
  const { Option } = Select;
  const onOptionsSelect = (e) => {
    setOption(e);
    setOptionChange(!optionChange);
  };
  const columns = [
    {
      title: "S/N",
      key: "ordersn",
      // fixed: "left",
      width: "1.7%",
      render: (record, item, index) => {
        return <>{index + 1}</>;
      }
    },
    {
      title: "Order Time, Status & Type",
      key: "  orderStatusRes",
      width: "6%",
      // fixed: "left",
      render: (record, item, index) => {
        let orderStatus = item.orderStatus;
        let color = "";
        console.log(`which status`, orderStatus);
        switch (orderStatus) {
          case "DELIVERED":
            color = "green";
            break;
          case "PENDING":
            color = "orange";
            break;
          case "IN-PROCESS":
            color = "deeppink";
            break;
          case "CANCEL":
            color = "red";
            break;
          default:
            color = "black";
        }
        // let text =
        return (
          <div>
            <div>
              ID: &nbsp;
              {item._id}
            </div>
            <div>
              {moment(item.createdAt).format(" DD/MMM/YYYY, ddd, hh:mm A")}
            </div>
            <div>
              Status: &nbsp;
              {/* <span style="color:`${color}`"> */}
              <span style={{ color: `${color}` }}>
                {item.orderStatus.toUpperCase() != "  Delivered"
                  ? item.orderStatus.toUpperCase()
                  : " Handed to Driver"}
              </span>
              {/* {item.orderStatus.toUpperCase() != "  Delivered"
                ? item.orderStatus.toUpperCase()
                : " Handed to Driver"} */}
            </div>
            <div>
              Type: &nbsp;
              {item.orderType.toUpperCase()}
            </div>
          </div>
        );
      }
    },
    {
      title: "Product Original Price",
      key: "originalPrice",
      width: "4%",
      render: (record, item, index) => {
        return (
          <>
            {"Kr. "}
            {Number(item.taxPriceOriginal).toFixed(2)}
          </>
        );
      }
    },
    {
      title: "Percent Increase In Price (%)",
      width: "4%",
      key: "percent increase in price",
      render: (record, item, index) => {
        return (
          <>
            {Number(item.tdExtraPriceIncrease).toFixed(2)}
            {" %"}
          </>
        );
      }
    },
    {
      // title: "Increased  Price Kr",
      title: "Product Price in TD Panel",
      width: "4%",
      key: "increased in price in Kr",
      render: (record, item, index) => {
        return (
          <>
            {"Kr. "}
            {Number(item.taxPriceInc).toFixed(2)}
          </>
        );
      }
    },
    {
      title: "Discount (-)",
      key: "discountInKr",
      width: "4%",
      render: (record, item, index) => {
        return (
          <>
            {"- Kr. "}
            {item.orderType == "Drive Out"
              ? Number(item.tdDiscountAbs).toFixed(2)
              : Number(item.tdDiscountPickUpAbs).toFixed(2)}
          </>
        );
      }
    },
    {
      title: "Service Charge (+) ",
      key: "service charge",
      width: "4%",
      render: (record, item, index) => {
        return (
          <>
            {"+ Kr. "}
            {Number(item.tdServiceCharge).toFixed(2)}
          </>
        );
      }
    },
    {
      title: "Distance Travelled",
      key: "distance travelled",
      width: "4%",
      render: (record, item, index) => {
        return (
          <div style={{ color: "#B8B8B8" }}>
            {item?.distance.toFixed(2)}
            {" Km"}
          </div>
        );
      }
    },
    {
      // title: "Travelled",
      title: "Distance for Flat Rate",
      width: "4%",
      key: "flat distance travelled",
      render: (record, item, index) => {
        return (
          <div style={{ color: "#B8B8B8" }}>
            {Number(item.tdFlatDistanceTravelled).toFixed(2)}
            {" Km"}
          </div>
        );
      }
    },
    {
      // title: "Fee",
      title: "Fixed Fees",
      key: "flat distance travelled",
      width: "4%",
      render: (record, item, index) => {
        return (
          <div style={{ color: "#B8B8B8" }}>
            {" Kr. "}
            {Number(item.tdFlatRateDriveOut).toFixed(2)}
          </div>
        );
      }
    },
    {
      title: "Extra Distance Fee",
      key: "flat distance travelled",
      width: "4%",
      render: (record, item, index) => {
        return (
          <div style={{ color: "#B8B8B8" }}>
            {" Kr. "}
            {Number(item.tdExtraRateDriveOut).toFixed(2)}
          </div>
        );
      }
    },
    {
      title: "Total Drive Out Charge",
      key: "totalDriveOutCharge",
      width: "3%",
      render: (record, item, index) => {
        return (
          <>
            {"+ Kr. "}
            {Number(item.tdDriveOutCharge).toFixed(2)}
          </>
        );
      }
    },
    {
      // title: "Price Paid By Customer",
      title: "* Invoice Price",
      key: "pricePaidByCustomer",
      width: "4%",
      render: (record, item, index) => {
        return (
          <>
            {"= Kr. "}
            {Number(item.taxPrice).toFixed(2)}
          </>
        );
      }
    },
    {
      title: "Fees From Restaurant to TD %",
      key: "FeesFromRestaurant",
      width: "3%",
      render: (record, item, index) => {
        return (
          <div style={{ color: "#B8B8B8" }}>
            {Number(item.tdFeesFromRestaurant).toFixed(2)}
            {" %"}
          </div>
        );
      }
    },
    {
      title: "TD's earning",
      children: [
        {
          title: "Before Tax",
          key: "Earning by treeDrive",
          width: "4%",
          render: (record, item, index) => {
            return (
              <div style={{ color: "#B8B8B8" }}>
                {"Kr. "}
                {/* {Number(item.tdIncomeToBeTaxed).toFixed(2)} */}
                {Number(item.beforeTax).toFixed(2)}
              </div>
            );
          }
        },
        {
          title: "After Tax",
          key: "Earning by treeDriveAfterTaxed",
          width: "4%",
          render: (record, item, index) => {
            return (
              <div style={{ color: "#B8B8B8" }}>
                {"Kr. "}
                {/* {Number(item.tdIncomeAfterTaxed).toFixed(2)} */}
                {Number(item.afterTax).toFixed(2)}
              </div>
            );
          }
        }
      ]
    },
    {
      title: "* Driver's",
      children: [
        {
          title: "Delivery Status",
          key: "Delieverystatusbydriver",
          width: "4%",
          render: (record, item, index) => {
            if (item.iDriveMyself) {
              return <>{"Delivery done by Restaurant"}</>;
            }
            return (
              <>
                {item.orderType == "Drive Out"
                  ? item?.delieveryStatus
                  : "Not Applicable"}
              </>
            );
          }
        },
        {
          title: "Fee /Km",
          key: "Driver Fee Per Km",
          width: "3.5%",
          render: (record, item, index) => {
            if (item.iDriveMyself) {
              return (
                <div style={{ color: "#B8B8B8" }}>
                  {"Delivery done by Restaurant"}
                </div>
              );
            }
            return (
              <div style={{ color: "#B8B8B8" }}>
                {"Kr. "}
                {item.orderType == "Drive Out"
                  ? item.driverId
                    ? Number(item?.driverId?.driverFee?.perKm).toFixed(2)
                    : "Not Confirmed by Driver"
                  : "Pick Up Order"}
              </div>
            );
          }
        },

        {
          title: "Fee /Km (New Calc)",
          key: "Driver Fee Per Km",
          width: "3.5%",
          render: (record, item, index) => {
            if (item.iDriveMyself) {
              return (
                <div style={{ color: "#B8B8B8" }}>
                  {"Delivery done by Restaurant"}
                </div>
              );
            }
            return (
              <div style={{ color: "#B8B8B8" }}>
                {"Kr. "}
                {item.orderType == "Drive Out"
                  ? item.driverId
                    ? Number(item?.feePerKm).toFixed(2)
                    : "Not Confirmed by Driver"
                  : "Pick Up Order"}
              </div>
            );
          }
        },

        {
          title: "Fee /Delivery",
          width: "3.5%",
          key: "Driver Fee Per Del",
          render: (record, item, index) => {
            if (item.iDriveMyself) {
              return (
                <div style={{ color: "#B8B8B8" }}>
                  {"Delivery done by restaurant"}
                </div>
              );
            }
            return (
              <div style={{ color: "#B8B8B8" }}>
                {"Kr. "}
                {item.orderType == "Drive Out"
                  ? item.driverId
                    ? Number(item?.driverId?.driverFee?.perDel).toFixed(2)
                    : "Not Confirmed By Driver"
                  : "Pick Up Order"}
              </div>
            );
          }
        },
        {
          title: "Earning ",
          key: "Earning by driver",
          width: "3.5%",
          render: (record, item, index) => {
            if (item.iDriveMyself) {
              return <>{"Delivery done by Restaurant"}</>;
            }
            if (item.orderType == "Drive Out") {
              return (
                <>
                  {}
                  {item.delieveryStatus == "delievered" ? (
                    <>
                      Kr.&nbsp;
                      {Number(item?.DriverEarningInThisOrder?.earning).toFixed(
                        2
                      )}
                      {/* {Number(item.tdDriveOutCharge).toFixed(2)} */}
                    </>
                  ) : item.delieveryStatus == "confirmed" ? (
                    "Delivery Pending"
                  ) : (
                    "0.0"
                  )}
                </>
              );
            }
            return <>{"Pick up order"}</>;
          }
        }
        // {
        //   title: "Earning ",
        //   key: "Earning by driver",
        //   width: "5%",
        //   render: (record, item, index) => {
        //     if (item.iDriveMyself) {
        //       return <>{"Delivery done by Restaurant"}</>;
        //     }
        //     return (
        //       <>
        //         {"Kr. "}
        //         {item.orderType == "Drive Out"
        //           ? item.delieveryStatus == "delievered"
        //             ? Number(item?.DriverEarningInThisOrder?.earning).toFixed(2)
        //             : // : 0}
        //               "Not yet Delivered"
        //           : ""}
        //       </>
        //     );
        //   }
        // }
      ]
    },

    // {
    //   title: "* Restaurant's Earning",
    //   key: "earningByRestaurantKr",
    //   width: "3%",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {"Kr. "}
    //         {/* {Number(item.tdRestaurantEarning).toFixed(2)} */}
    //         {Number(item.resIncome).toFixed(2)}
    //       </>
    //     );
    //   }
    // },

    // {
    //   title: "* Fees",
    //   children: [
    //     {
    //       title: "To ORC %",
    //       width: "3%",
    //       key: "FeesToOrc%",
    //       render: (record, item, index) => {
    //         return (
    //           <div style={{ color: "#B8B8B8" }}>
    //             {Number(item.orcFeesFromRestaurant).toFixed(2)}
    //             {" %"}
    //           </div>
    //         );
    //       }
    //     },
    //     {
    //       title: "From Restaurant to TD %",
    //       key: "FeesFromRestaurant",
    //       width: "3%",
    //       render: (record, item, index) => {
    //         return (
    //           <div style={{ color: "#B8B8B8" }}>
    //             {Number(item.tdFeesFromRestaurant).toFixed(2)}
    //             {" %"}
    //           </div>
    //         );
    //       }
    //     }
    // {
    //   title: "To ORC including 25% tax",
    //   key: "FeesToOrcKr",
    //   width: "5.8%",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {"Kr. "}
    //         {Number(item.orcFeesFromRestaurantAbs).toFixed(2)}
    //       </>
    //     );
    //   }
    // },
    // {
    //   title: "From Restaurant to TD Including 25% tax",
    //   key: "FeesToRestaurantKr",
    //   width: "5.8%",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {"Kr. "}
    //         {Number(item.tdFeesFromRestaurantAbs).toFixed(2)}
    //       </>
    //     );
    //   }
    // },
    //   ]
    // },
    {
      title: "Net Profit by TD",
      fixed: "right",
      width: "3%",
      key: "Net Profit By Tree Drive",
      render: (record, item, index) => {
        return (
          <>
            {"Kr. "}
            {/* {item.tdProfit.toFixed(2)} */}
            {item.tdNetProfit.toFixed(2)}
          </>
        );
      }
    }
    // {
    //   title: "Increase In Price Kr",
    //   key: "increase in price in Kr",
    //   width: "5%",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {"Kr. "}
    //         {Number(item.tdDiffBetIncOriginal).toFixed(2)}
    //       </>
    //     );
    //   }
    // },
    // {
    //   title: "Difference between Price paid by cutomer and original Price",
    //   key: "differencepricePaidByCustomer",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {"Kr."}
    //         {Number(item.tdDiffBetPaidAndOriginal).toFixed(2)}
    //       </>
    //     );
    //   }
    // },
    // {
    //   title: "Discount on Increased Price %",
    //   key: "discountPercentage",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {item.orderType == "Drive Out"
    //           ? Number(item.tdDiscount).toFixed(2)
    //           : Number(item.tdDiscountPickUp).toFixed(2)}
    //         {" %"}
    //       </>
    //     );
    //   }
    // },
    // {
    //   title: "Percent Increase In Price (%)",
    //   key: "percent increase in price",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {Number(item.tdExtraPriceIncrease).toFixed(2)}
    //         {" %"}
    //       </>
    //     );
    //   }
    // },
    // {
    //   title: "Increase In Price Kr",
    //   key: "increase in price in Kr",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {"Kr. "}
    //         {Number(item.tdDiffBetIncOriginal).toFixed(2)}
    //       </>
    //     );
    //   }
    // },
    // {
    //   title: "Extra Distance Travelled",
    //   key: "distance travelled",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {item.tdExtraDistanceTravelled.toFixed(2)}
    //         {" Km."}
    //       </>
    //     );
    //   }
    // },
    // {
    //   title: "Extra Distance Fees",
    //   key: "distance travelled",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {" Kr."}
    //         {Number(item.tdExtraRateDriveOut).toFixed(2)}
    //       </>
    //     );
    //   }
    // },
  ];

  const footerColumn = [
    {
      title: "S/N",
      key: "Sn no in footer",
      render: (record, item, index) => {
        return <>{index + 1}</>;
      },
      width: "5%"
    },
    {
      title: "TOTAL",
      children: [
        {
          title: "Orders",
          key: "totalOrders",
          width: "3%",
          render: (record, item, index) => {
            return <>{item.totalOrder}</>;
          }
        },
        {
          // title: "Original Price",
          title: "Confirmed TD sales @ ORC Panel",
          key: "totalOriginalPrice",
          width: "8%",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {Number(item.totalTaxPriceOriginal).toFixed(2)}
              </>
            );
          }
        },
        {
          title: "Fees from ",
          children: [
            {
              title: "ORC including 25% tax",
              key: "totalFeesToOrc",
              width: "8.66%",
              render: (record, item, index) => {
                return (
                  <>
                    {"Kr. "}
                    {Number(item.totalFeesToOrc).toFixed(2)}
                  </>
                );
              }
            },
            {
              title: "TD from Restaurant",
              key: "totalFeesToRes",
              width: "7.66%",
              render: (record, item, index) => {
                return (
                  <>
                    {"Kr. "}
                    {Number(item.totalFeesToTd).toFixed(2)}
                  </>
                );
              }
            }
          ]
        },
        {
          // title: "Increased Price",
          title: "Product Price in TD Panel",
          key: "totalIncreasedPrice",
          width: "8.33%",
          render: (record, item, index) => {
            let totalEarning = 0;
            return (
              <>
                {"Kr. "}
                {Number(item.totalTaxPriceInc).toFixed(2)}
              </>
            );
          }
        },
        {
          title: "Delivery Discount (-)",
          key: "totalDeliveryDiscount",
          width: "8.66%",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {Number(item.totalDelDiscount).toFixed(2)}
              </>
            );
          }
        },
        {
          title: "PickUp Discount (-)",
          key: "totalPickUpDiscount",
          width: "8.66%",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {Number(item.totalPickDiscount).toFixed(2)}
              </>
            );
          }
        },
        {
          title: "Service Charge (+) ",
          key: "totalServiceCharge",
          width: "8.33%",
          render: (record, item, index) => {
            let totalEarning = 0;
            return (
              <>
                {"Kr. "}
                {Number(item.totalTdServiceCharge)}
              </>
            );
          }
        },
        {
          title: "DriveOut Charge (+)",
          key: "totalDriveOutCharge",
          width: "8.66%",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {Number(item.totalTdDriveOutCharge).toFixed(2)}
              </>
            );
          }
        },
        {
          title: "Income",
          children: [
            {
              title: "Before Tax",
              key: "totalIncomeToBeTaxed",
              width: "7.66%",
              render: (record, item, index) => {
                return (
                  <>
                    {"Kr. "}
                    {Number(item.totalTdIncomeToBeTaxed).toFixed(2)}
                  </>
                );
              }
            },
            {
              title: "After Tax",
              key: "totalIncomeAfterTaxed",
              width: "7.66%",
              render: (record, item, index) => {
                return (
                  <>
                    {"Kr. "}
                    {Number(item.totalTdIncomeAfterTaxed).toFixed(2)}
                  </>
                );
              }
            }
          ]
        },
        {
          title: "Payment to Drivers",
          key: "totalPaymentToDrivers",
          width: "8.66%",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {Number(item.totalDriverEarning).toFixed(2)}
              </>
            );
          }
        },
        {
          title: "Earning by Restaurant",
          key: "totalEarningByRestaurant",
          width: "9.66%",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {Number(item.totalRestaurantEarning).toFixed(2)}
              </>
            );
          }
        },
        {
          title: "Profit to TD",
          key: "totalProfitToTreeDrive",
          width: "8.66%",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {Number(item.totalTdProfit).toFixed(2)}
              </>
            );
          }
        }
      ]
    }

    // {
    //   title: "Original price paid by Customer excluding DriveOut Charges",
    //   key: "totalOriginalPaidByCustomer",
    //   width: "16.66%",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {"Kr. "}
    //         {Number(item.totalTaxPriceOriginal).toFixed(2)}
    //       </>
    //     );
    //   }
    // },
    // {
    //   title: "Diff b/w Increased Price & Original Price",
    //   key: "totalDiffBetweenIncAndOriginalPrice",
    //   width: "16.66%",
    //   render: (record, item, index) => {
    //     return (
    //       <>
    //         {"Kr. "}
    //         {Number(item.totalDiffBetOriginalInc).toFixed(2)}
    //       </>
    //     );
    //   }
    // },
    //   {
    //     title: 'Tree DriveOut Charge Extra',
    //     key: 'treeDriveOutChargeExtra',
    //     width:"16.66%",
    //     render:(record,item,index)=>{
    //       let totalEarning=0;
    //          console.log("Total Earning=====>",totalEarning);
    //       return (<>{"Kr. "}{Number(totalEarning).toFixed(2)}</>)
    //     }

    // },
  ];
  const footerData = [
    {
      // total:totalEarning
    }
  ];
  const content = (
    <div className="impPop">
      <ol>
        <li>Fees is calculated including 25% Tax.</li>
        <li>
          <b>Invoice Price</b>: Updated when Order Status is "DELIVERED".
        </li>
        <li>
          <b>Driver's Earning</b>: Updated on the basis of Driver's Delivery
          Status.
        </li>
        <li>
          <b>Net Profit</b>: Calculated after all status updation.
        </li>
        <li>
          <b>Restaurant's Earning</b>: Updated after Restaurant's Delivery
          Status. <br />
          <b>R.E = Original Price - [fees((ORC+25%) + (TD+25%))]</b>
        </li>
      </ol>
    </div>
  );
  return (
    <>
      <div className="user-list">
        <Row className="justify-content-between">
          <Col>
            <>{branchName}'s Orders</>
          </Col>
          <Col>
            <Button onClick={() => navigate(-1)} className="common-btn2">
              Go Back
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-between py-3">
          <Col>
            <Button
              className="flex"
              onClick={() => onDateSelect(moment(), moment().format())}
            >
              14 Days Orders History: (
              {moment().subtract(13, "days").format("DD/MM/YYYY ")}
              <p>&nbsp;to{moment().endOf("day").format(" DD/MM/YYYY")})</p>
            </Button>{" "}
          </Col>
          <Col className="flex orderPerReDate">
            <div>
              <Space direction="vertical">
                <DatePicker
                  onChange={(date, dateString) =>
                    onDateSelect(date, dateString, "day")
                  }
                />
              </Space>
              <Space direction="vertical">
                <DatePicker
                  onChange={(date, dateString) =>
                    onDateSelect(date, dateString, "month")
                  }
                  picker="month"
                />
              </Space>
              <Select
                defaultValue="ALL"
                style={{
                  width: 120
                }}
                onChange={onOptionsSelect}
              >
                <Option value="ALL">ALL</Option>
                <Option value="PENDING">PENDING</Option>
                <Option value="IN-PROCESS">IN-PROCESS</Option>
                <Option value="DELIVERED">DELIVERED</Option>
                <Option value="CANCEL">CANCEL</Option>
              </Select>
            </div>

            <Popover
              content={content}
              title="* Imp Points"
              placement="bottomRight"
              //  trigger="click"
            >
              <Button
                style={{
                  backgroundColor: "#1890ff",
                  color: "#fff",
                  padding: "0 50px"
                }}
              >
                * Imp Points
              </Button>
            </Popover>
          </Col>
        </Row>
        <Row>
          <Table
            className="user-table mt-3"
            columns={columns}
            dataSource={editedOrders}
            scroll={{ x: 3500, y: 400 }}
            striped
            bordered
            hover
            pagination={{
              pageSize: 10
            }}
            // scroll={{ x: true }}
          />

          <Table
            scroll={{ x: true }}
            columns={footerColumn}
            dataSource={orderTotal}
            pagination={false}
            bordered
          />
        </Row>
      </div>
    </>
  );
};

export default OrderPerRestaurant;
