import {createSlice} from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const dashBoardSlice=createSlice({
    name:"dashboard",
    initialState:{
        totalOrders:0,
        totalDrivers:0,
        totalUsers:0,
        totalBranches:0,
        totalOrdersPerHour:[],
        totalOrdersPerMonth:[],
        totalOrdersPerWeek:[],
        totalOrdersPerMonthPerYear:[],
        totalOrdersPerYear:[]
    },
    reducers:{
        fetchDashBoard:(state,action)=>{
            console.log("action payload in dashboard====>",action.payload);
            state.totalBranches=action.payload.totalBranches;
            state.totalUsers=action.payload.totalUsers;
            state.totalOrders=action.payload.totalOrders;
            state.totalDrivers=action.payload.totalDrivers;
            state.totalOrdersPerHour=action.payload.totalOrdersPerHour;
            state.totalOrdersPerMonth=action.payload.totalOrdersPerMonth;
            state.totalOrdersPerWeek=action.payload.totalOrdersPerWeek;
            state.totalOrdersPerMonthPerYear=action.payload.totalOrdersPerMonthPerYear;
            state.totalOrdersPerYear=action.payload.totalOrdersPerYear;
           
        }
    }
});

export const {fetchDashBoard}=dashBoardSlice.actions;
export default dashBoardSlice.reducer;