import React,{useState} from "react";
import {Modal,Form,Input,Button} from "antd";
import { useNavigate } from "react-router-dom";
import endpoint from "../../helpers/Endpoint";
import openNotification from "../../commonFuncs/notification";
import axios from "axios";
import api from "./../../commonFuncs/api";
const ResetPassword=(props)=>{
    let navigate=useNavigate();
    const inputForm={
        currentPassword:"",
        newPassword:""
    }
    const errorObj={
        currentPassword:"",
        newPassword:""
    }
    const [inputFormObj,setInputFormObj]=useState(inputForm);
    const [errorFormObj,setErrorFormObj]=useState(errorObj);
    const [loading,setLoading]=useState(false);
    const handleCancel=()=>{
        props.handleCancelReset()
    }
    const handleSubmit=async(e)=>{
        console.log("inside handleOk reset Modal----->");
        e.preventDefault();
        let formValidation=true;
        for(let key in inputFormObj){
            if(inputFormObj[key]===""){
                formValidation=false;
                setErrorFormObj((prevState)=>({...prevState,[key]:true}));
            }else{
                setErrorFormObj((prevState)=>({...prevState,[key]:false}));
            }
        }

        console.log("formValidation in reset password------>",formValidation);
        console.log("inputformobj-------->",inputFormObj);
        if(formValidation){
            setLoading(true);
            let result;
            try{
            //   result=await axios.post(`${endpoint}/api/v1/treeDriveAdmin/appadmin/resetpassword`,{
            //    currentPassword:inputFormObj.currentPassword,
            //    newPassword:inputFormObj.newPassword
            //  },{
            //      headers:{
            //          "x-auth-token":localStorage.getItem("token")
            //      }
            //  });
            result=await api.post(`/api/v1/treeDriveAdmin/appadmin/resetpassword`,{
              currentPassword:inputFormObj.currentPassword,
              newPassword:inputFormObj.newPassword
            });
             if(result && result.data.status===200){
                setLoading(false)
            //    localStorage.clear();
               handleCancel();
               openNotification("success","Success","Successfully Reset the password.")
            //    navigate("/");
              }else if(result && result.data.status!=200){
               openNotification("error","Error Occurred!!",result.data.message)
               setLoading(false)
              }
            }catch(err){
              console.log("errr----->",err.message);
              openNotification("error","Error Occured",err.message)
            }
           }

    }
    return(
        <>
    <Modal
    className="resetpass"
    title="Reset Password" 
    visible={props.visible} onCancel={handleCancel} footer={
        [
          <Button key="submit" type="primary"  onClick={(e)=>handleSubmit(e)} loading={loading}>
            Submit
          </Button>
        ]}>
    <Form
      name="resetPasswordForm"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      autoComplete="off"
    >
      <Form.Item
        label="Current Password"
        name="currentPassword"
        placeholder="Please Enter Your Current Password"
        value={inputFormObj.currentPassword}
        onChange={(e)=>setInputFormObj((prevState)=>({...prevState,currentPassword:e.target.value}))}
        rules={[
          {
            required: true,
            message: 'Please input your Current Password',
          },
        ]}
      >
        <Input.Password />
        {errorFormObj.currentPassword?<span style={{color:"red"}}>"Please fill the current password"</span>:""}
      </Form.Item>

      <Form.Item
        label="New Password"
        name="newPassword"
        placeholder="Please Enter Your New Password"
        value={inputFormObj.newPassword}
        onChange={(e)=>setInputFormObj((prevState)=>({...prevState,newPassword:e.target.value}))}
        rules={[
          {
            required: true,
            message: 'Please input your New Password!',
          },
        ]}
      >
        <Input.Password />
        {errorFormObj.newPassword?<span style={{color:"red"}}>"Please fill the new password"</span>:""}
      </Form.Item>
    </Form>
    </Modal>
        </>
    )
}

export default ResetPassword;