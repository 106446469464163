import { Row, Col, Descriptions, Image, Avatar, Badge } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import s3url from "../../helpers/s3";
export default function ViewUserData() {
  let location = useLocation();
  const { singleUser } = location.state;
  console.log("passed data from userListMain====>", singleUser);
  console.log(
    "Object.keys(singleUser.userDetails[0]).length====>",
    Object.keys(singleUser).length
  );
  return (
      <div className="add-r-main bg-white pb-5">
        <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
          <Col>
            <h3>User Data</h3>
          </Col>
          <Col className=" flex justify-content-end">
            <NavLink to="/userlistmain" className="common-btn">
              Return to the List
            </NavLink>
          </Col>
        </Row>
        <Row className="p-5 flex flex-column">
          <Descriptions
            layout="vertical"
            bordered
          >
            <Descriptions.Item label="First Name">
              {Object.keys(singleUser).length > 0 ? singleUser.firstName : ""}
            </Descriptions.Item>
            <Descriptions.Item label="Last Name">
              {Object.keys(singleUser).length > 0 ? singleUser.lastName : ""}
            </Descriptions.Item>
            <Descriptions.Item label="E-mail">
              {Object.keys(singleUser).length > 0 ? singleUser.email : ""}
            </Descriptions.Item>
            <Descriptions.Item label="Phone No." span={1}>
              {Object.keys(singleUser).length > 0 ? singleUser.phoneNumber : ""}
            </Descriptions.Item>
            <Descriptions.Item label="City">
              {Object.keys(singleUser).length > 0
                ? singleUser?.address?.city
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="State">
              {" "}
              {Object.keys(singleUser).length > 0
                ? singleUser?.address?.state
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="Country">
              {Object.keys(singleUser).length > 0
                ? singleUser?.address?.country
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="PIN">
              {" "}
              {Object.keys(singleUser).length > 0
                ? singleUser?.address?.pinCode
                : ""}
            </Descriptions.Item>
            {Object.keys(singleUser).length > 0 ? (
              <Descriptions.Item label="Address">
                {singleUser?.address?.houseNumber},{singleUser?.address?.line1},
                {singleUser.address.line2},{singleUser.address.street}
              </Descriptions.Item>
            ) : (
              ""
            )}
          </Descriptions>
        </Row>
        <Row className="justify-content-center">
            {Object.keys(singleUser).length > 0 ? (
              <NavLink
                to="/orderhistoryuser"
                state={{
                  singleUserId: singleUser._id,
                  singleUserName: singleUser.firstName
                }}
                className="common-btn mx-5"
              >
                Order History
              </NavLink>
            ) : (
              ""
            )}
        </Row>
      </div>
  );
}
