import {createSlice} from "@reduxjs/toolkit";

const driverPaymentSlice=createSlice({
    name:"driverPayment",
    initialState:[],
    reducers:{
        fetchDriverPayment:(state,action)=>{
            console.log("action payload====>",action.payload);
            // state=[...state,action.payload];
            state=action.payload;
            return state;
            // state.push(action.payload)
            // return state;
        }
    }
});

export const {fetchDriverPayment}=driverPaymentSlice.actions;
export default driverPaymentSlice.reducer;