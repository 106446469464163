import {
  UserOutlined
} from '@ant-design/icons';
import {Row, Col, Divider, Avatar, Input, Button, message, Upload, Form, Switch } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */


const onChange = (value) => {
  console.log(`selected ${value}`);
};

const onSearch = (value) => {
  console.log('search:', value);
};

const props = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text',
  },

  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }

    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};


export default function AddRestaurants() {
  const onFinish = (values) => {
    console.log(values);
  };

      return (
        <>
          <div className='add-r-main bg-white pb-2'>
            <Row className='justify-content-between align-items-center add-r-head px-4 py-3'>
              <Col>
                <h3>Add New Restaurant</h3>
              </Col>
              <Col>
              <NavLink to='/restaurantlistmain' className="common-btn">Return to the List</NavLink>
              </Col>
            </Row>
            {/* <Divider></Divider> */}
            {/* <Row className='justify-content-center'>
              <Col className='col-6'>
              <div className='profile-photo flex flex-column align-items-center'>
            <Avatar className='user-photo' size={150}  icon={<UserOutlined />} />
            <Upload {...props} className='pt-3'>
              <Button className="common-btn">Click to Upload</Button>
            </Upload>
            <p>Files types accepted: .jpg, .jpeg, .png, .bmp recommended size (512 X 512) pixels</p>
            </div>
              </Col>
            </Row> */}
            <div className='form-r-main p-3'>
            <Form {...layout} name="nest-messages" className='add-r-form p-3'>
            <Row>
              <Col className='col-6'>
                <Form.Item name="restaurant-name"
                rules={[
                {
                required: true,
                message: 'Please input Resturant Name!',
                },
                ]}
                >
                <Input placeholder="Resturant Name"/>
                </Form.Item>
              </Col>
              <Col className='col-6'>
                <Form.Item name="restaurant-name"
                rules={[
                {
                required: true,
                message: 'Please input Resturant Type!',
                },
                ]}>
                <Input  placeholder="Resturant Type"/>
                </Form.Item>
              </Col>
            </Row>
            
            <Row>
              <Col className='col-6'>
              <Form.Item name="email"
              rules={[
              {
              type: 'email',
              message: 'The input is not valid E-mail!',
              },
              {
              required: true,
              message: 'Please input your Taccountant E-mail!',
              },
              ]}>
              <Input placeholder="Taccountant E-mail"/>
              </Form.Item>
              </Col>
              <Col className='col-6'>
              <Form.Item name="email"
              rules={[
              {
              required: true,
              message: 'Please input your Branch Code!',
              },
              ]}>
              <Input placeholder="Brach Code"/>
              </Form.Item>
              </Col>
            </Row>

            <Row>
            <Col className='col-6'>
            <Form.Item>
            <Input placeholder="Website"/>
            </Form.Item>
            </Col>
            <Col className='col-6'>
            <Form.Item>
            <Input placeholder="Email"/>
            </Form.Item>
            </Col>
            </Row>
              
            <Row>
            <Col className='col-12'>
            <Form.Item
        name="Address"
        rules={[
          {
            type: 'array',
            required: true,
            message: 'Please select Restaurant Address!',
          },
        ]}
      >
      <Input placeholder="Address Line 1"/>
      </Form.Item>
            </Col>
            </Row>

            <Row>
            <Col className='col-12'>
            <Form.Item
              name="Address">
              <Input placeholder="Address Line 2"/>
             </Form.Item>
            </Col>
            </Row>
      
            <Row>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input phone number!',
          },
        ]}
      >
        <Input placeholder="Phone Number"/>
      </Form.Item>
            </Col>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input Tax number!',
          },
        ]}
      >
        <Input placeholder="Tax Number"/>
      </Form.Item>
            </Col>
            </Row>

            <Row>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input City!',
          },
        ]}
      >
        <Input placeholder="City"/>
      </Form.Item>
            </Col>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input State!',
          },
        ]}
      >
        <Input placeholder="State"/>
      </Form.Item>
            </Col>
            </Row>

            <Row>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input PIN!',
          },
        ]}
      >
        <Input placeholder="PIN"/>
      </Form.Item>
            </Col>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input Country!',
          },
        ]}
      >
        <Input placeholder="Country"/>
      </Form.Item>
            </Col>
            </Row>

            <Row>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input Longitude!',
          },
        ]}
      >
        <Input placeholder="Longitude"/>
      </Form.Item>
            </Col>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input Latitude!',
          },
        ]}
      >
        <Input placeholder="Latitude"/>
      </Form.Item>
            </Col>
            </Row>

            <Row>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input Drive-out Charges!',
          },
        ]}
      >
        <Input placeholder="Td Flat Distance Drive Out Charge"/>
      </Form.Item>
            </Col>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input Account Number!',
          },
        ]}
      >
        <Input placeholder="Account Number"/>
      </Form.Item>
            </Col>
            </Row>

            <Row>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'adad',
          },
        ]}
      >
        <Input placeholder="Td Flat Distance in Kms"/>
      </Form.Item>
            </Col>
            <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input Merchant Serial Number POS!',
          },
        ]}
      >
        <Input placeholder="Td Extra Drive Out Charge"/>
      </Form.Item>
            </Col>
            </Row>
            <Row>
              <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input Merchant Serial Number POS!',
          },
        ]}
      >
        <Input placeholder="Td Distance Allowed By Restaurant"/>
      </Form.Item>
              </Col>
              <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input Merchant Serial Number POS!',
          },
        ]}
      >
        <Input placeholder="Td Service Charge"/>
      </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className='col-6'>
              <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input Merchant Serial Number POS!',
          },
        ]}
      >
        <Input placeholder="Td Extra Price Increase in %"/>
      </Form.Item>
              </Col>
            </Row>

            <Row>
            <Col className='col-12 justify-content-evenly flex'>
            <Switch checkedChildren="Pick-up" unCheckedChildren="Pick-up" defaultChecked />
            <Switch checkedChildren="Delivery" unCheckedChildren="Delivery" defaultChecked />
            <Switch checkedChildren="Tree Drive" unCheckedChildren="Tree Drive" defaultChecked />
            <Switch checkedChildren="Coupons" unCheckedChildren="Coupons" defaultChecked />
            </Col>
            </Row>

            <Row className='pt-3'>
              <Col className='col-12'>
              <Form.Item>
                <Input.TextArea placeholder="Description" />
              </Form.Item>
              </Col>
            </Row>

            <Row className=' justify-content-center'>
              <Col className='col-6'>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
              <Button type="primary" htmlType="submit">Add</Button>
              </Form.Item>
              </Col>
            </Row>
          </Form>
            </div>
          </div>
        </>
      );
    };

