import { Row, Col, Descriptions, Image, Avatar, Badge } from "antd";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import s3url from "../../helpers/s3";
export default function ViewTflProd() {
  let location = useLocation();
  console.log("data", location.state);
  let data = location.state;

  return (
    <div className="add-r-main bg-white pb-2">
      <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
        <Col>
          <h3>Product Data</h3>
        </Col>
        <Col className="flex justify-content-end">
          <NavLink to="/tflPoints" className="common-btn">
            Return to the List
          </NavLink>
        </Col>
      </Row>
      <div className="p-5">
        <Row className=" flex flex-column">
          <div className="display-data p-5 flex flex-column  justify-content-center">
            <Descriptions layout="vertical" bordered className="pt-3">
              <Descriptions.Item label="Product Name" span={2}>
                {data.productName ? data.productName : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Product Image" span={2}>
                {" "}
                <Avatar
                  shape="square"
                  size="large"
                  src={
                    <Image
                      src={`${s3url}/${data.url}`}
                      style={{ maxWidth: 200 }}
                    />
                  }
                />
              </Descriptions.Item>
              <Descriptions.Item label="Required Coins" span={2}>
                {data.RequiredPts ? data.RequiredPts : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Available Quantity" span={2}>
                {data.availableQuantity ? data.availableQuantity : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Sales Price" span={2}>
                Kr. {data.amount ? data.amount : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Purchase Price" span={2}>
                Kr. {data.purchasePrice ? data.purchasePrice : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Weight (in gm)" span={2}>
                {data.weight ? data.weight : ""}
              </Descriptions.Item>

              <Descriptions.Item label="Dimesions (in cm)" span={2}>
                {data.dimensions
                  ? `${data.dimensions.length} X ${data.dimensions.breadth} X ${data.dimensions.height}`
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Description">
                {data.description ? data.description : ""}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Row>
      </div>
    </div>
  );
}
