import { Row, Col, Input, Button, message, Form, Switch } from "antd";
import React, { useEffect, useState } from "react";
import api from "../../commonFuncs/api";
import openNotification from "../../commonFuncs/notification";

const propsupload = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text"
  },

  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
};

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};
/* eslint-disable no-template-curly-in-string */

// const validateMessages = {
//   required: '${label} is required!',
//   types: {
//     email: '${label} is not a valid email!',
//     number: '${label} is not a valid number!',
//   },
//   number: {
//     range: '${label} must be between ${min} and ${max}',
//   },
// };
/* eslint-enable no-template-curly-in-string */

// const onChange = (value) => {
//   console.log(`selected ${value}`);
// };

// const onSearch = (value) => {
//   console.log('search:', value);
// };

// const props = {
//   name: 'file',
//   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
//   headers: {
//     authorization: 'authorization-text',
//   },

//   onChange(info) {
//     if (info.file.status !== 'uploading') {
//       console.log(info.file, info.fileList);
//     }

//     if (info.file.status === 'done') {
//       message.success(`${info.file.name} file uploaded successfully`);
//     } else if (info.file.status === 'error') {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
// };

export default function AddDrivers(props) {
  const [blockDriver, setBlockDriver] = useState(
    props.driver != undefined ? props.driver.isBlocked : true
  );
  const [verifiedDriver, setVerifiedDriver] = useState(
    props.driver != undefined ? props.driver.isDriverVerified : false
  );
  const [driverData, setDriverData] = useState(props.driver || {});

  const [inputExtraFee, setInputExtraFee] = useState(
    props.driver && props.driver.driverFee.extraFeePerKm
      ? props.driver.driverFee.extraFeePerKm
      : 0
  );
  const [inputDistanceForFlatRate, setInputDistanceForFlatRate] = useState(
    props.driver && props.driver.driverFee.flatRateDistance
      ? props.driver.driverFee.flatRateDistance
      : 0
  );

  ///console.log("props----->",props)

  const [errorObj, setErrorObj] = useState({});
  const [formData, setFormData] = useState({});

  const onFinish = (values) => {
    console.log(values);
  };

  useEffect(() => {
    let formValues = {
      firstName: driverData.firstName || "",
      lastName: driverData.lastName || "",
      phoneNumber: driverData.phoneNumber || "",
      email: driverData.email || "",
      licenseNumber: driverData.licenseNumber || "",
      houseNumber: driverData?.address?.houseNumber || "",
      street: driverData?.address?.street || "",
      pinCode: driverData?.address?.pinCode || "",
      city: driverData?.address?.city || "",
      state: driverData?.address?.state || "",
      country: driverData?.address?.country || "",
      vehicleType: driverData?.vehicle?.vehicleType || "",
      vehicleRegNumber: driverData?.vehicle?.vehicleRegNumber || "",
      vehicleColor: driverData?.vehicle?.vehicleColor || "",
      description: driverData.description || ""
      // driverFeePerKm:driverData?.driverFee?.perKm || "",
      // driverFeePerDel:driverData?.driverFee?.perDel || ""
    };

    let errorValues = {
      firstName: false,
      lastName: false,
      phoneNumber: false,
      email: false,
      licenseNumber: false,
      houseNumber: false,
      street: false,
      pinCode: false,
      city: false,
      state: false,
      country: false,
      vehicleType: false,
      vehicleRegNumber: false,
      vehicleColor: false,
      description: false
      // driverFeePerKm:false,
      // driverFeePerDel:false
    };

    if (props.edit) {
      //in formValues driver id is added
      formValues.driverId = driverData._id;
      // formValues.driverFeePerKm=driverData?.driverFee?.perKm || "";

      formValues.driverFeePerDel = driverData?.driverFee?.perDel || "";
      formValues.range1Min = driverData?.driverFeeRange?.range1Min || "";
      formValues.range1Max = driverData?.driverFeeRange?.range1Max || "";
      formValues.perKmFee1 = driverData?.driverFeeRange?.perKmFee1 || "";
      formValues.range2Min = driverData?.driverFeeRange?.range2Min || "";
      formValues.range2Max = driverData?.driverFeeRange?.range2Max || "";
      formValues.perKmFee2 = driverData?.driverFeeRange?.perKmFee2 || "";
      formValues.range3Min = driverData?.driverFeeRange?.range3Min || "";
      formValues.range3Max = driverData?.driverFeeRange?.range3Max || "";
      formValues.perKmFee3 = driverData?.driverFeeRange?.perKmFee3 || "";
      // errorValues.driverFeePerKm=false;
      errorValues.range1Min = false;
      errorValues.range1Max = false;
      errorValues.perKmFee1 = false;
      errorValues.range2Min = false;
      errorValues.range2Max = false;
      errorValues.perKmFee2 = false;
      errorValues.range3Min = false;
      errorValues.range3Max = false;
      errorValues.perKmFee3 = false;
      errorValues.driverFeePerDel = false;
    }
    if (props.add) {
      formValues.password = "";
      // formValues.driverFeePerKm="1";
      // formValues.driverFeePerDel="1";
      errorValues.password = false;
    }

    setErrorObj(errorValues);
    setFormData(formValues);
  }, []);

  let firstLoad = true;

  const onBlockChange = (checked) => {
    setBlockDriver(checked);
    props.onBlockStatusChange(checked, props.driver._id);
  };

  const verifyDriver = (checked) => {
    setVerifiedDriver(checked);
    props.onVerifiedDriver(checked, props.driver._id);
  };
  // const handleErrorObj=(e,key)=>{

  //   if(e.target.value==""){
  //     setErrorObj((prevState)=>({
  //       ...prevState,key:true
  //     }))
  //   }else{
  //    setErrorObj((prevState)=>({
  //      ...prevState,key:false
  //    }))
  //   }
  // }
  const editForm = async (formData) => {
    console.log(inputExtraFee, inputDistanceForFlatRate);

    formData.driverExtraFeePerKm = inputExtraFee;
    formData.driverFlatRateDistance = inputDistanceForFlatRate;

    console.log("---Submit form data---", formData);

    try {
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appdriver/editdriver`,
        {
          formData: formData
        }
      );
      if (result.data.status == 200) {
        openNotification("success", "Success", result.data.message);
      } else {
        openNotification("error", "Failure", result.data.message);
      }
    } catch (err) {
      console.log("error--->", err.message);
      openNotification("error", "ERROR", err.message);
    }
  };

  const addForm = async (formData) => {
    console.log(inputExtraFee, inputDistanceForFlatRate);

    formData.driverExtraFeePerKm = inputExtraFee;
    formData.driverFlatRateDistance = inputDistanceForFlatRate;

    console.log("---Submit form data---", formData);

    try {
      // setFormData(())
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appdriver/signupdriver`,
        {
          formData: formData
        }
      );
      if (result.data.status == 200) {
        openNotification("success", "Success", result.data.message);
      } else {
        openNotification("error", "Failure", result.data.message);
      }
    } catch (err) {
      console.log("error--->", err.message);
      openNotification("error", "ERROR", err.message);
    }
  };

  const handleFormSubmit = async () => {
    firstLoad = false;
    console.log("inside the form sumbit--->", formData);

    let validation = true;
    for (let key in formData) {
      console.log("key in formData---->", key);
      if (formData[key] == "") {
        console.log("formDAta key---->", formData[key]);
        setErrorObj((prevState) => ({
          ...prevState,
          [key]: true
        }));
        validation = false;
      } else {
        setErrorObj((prevState) => ({
          ...prevState,
          [key]: false
        }));
      }
    }
    console.log("validation----->", validation);
    console.log("error obj----->", errorObj);
    console.log("formData======>", formData);
    if (validation && props.edit) {
      console.log("validation inside if----->", validation);
      editForm(formData);
      // return ;
    } else if (validation && props.add) {
      console.log("Inside add form Validation=====>", validation);

      addForm(formData);
    }
  };

  const setExtraFee = (val) => {
    if (!isNaN(val)) {
      //console.log(typeof(val), val, isNaN(val));
      setInputExtraFee(val);
    }
  };

  const setDistanceForFlatRate = (val) => {
    if (!isNaN(val)) {
      setInputDistanceForFlatRate(val);
    }
  };

  return (
    <div className="add-r-main bg-white pb-2 addDriverPage">
      <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
        <Col>
          <h3>{props.edit ? "Edit New Driver" : "Add New Driver"}</h3>
        </Col>
        <Col>
          <Button
            onClick={() => {
              props.handlePageChange("LIST");
            }}
            className="common-btn2"
          >
            Return to the List
          </Button>
        </Col>
      </Row>
      {/* <Divider></Divider> */}
      {/* <Row className='justify-content-center'>
              <Col className='col-6'>
              <div className='profile-photo flex flex-column align-items-center'>
            <Avatar className='user-photo' size={150}  icon={<UserOutlined />} />
            <Upload {...props} className='pt-3'>
              <Button className="common-btn">Click to Upload</Button>
            </Upload>
            <p>Files types accepted: .jpg, .jpeg, .png, .bmp recommended size (512 X 512) pixels</p>
            </div>
              </Col>
            </Row> */}
      <div className="form-r-main p-3">
        <Form
          {...layout}
          name="nest-messages"
          className="add-r-form p-3"
          onFinish={handleFormSubmit}
        >
          <Row>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="first-name" label="First Name">
                <Input
                  defaultValue={driverData.firstName || ""}
                  onChange={(e) => {
                    console.log("inside the first Name---->", e.target.value);
                    //  handleErrorObj(e,"firstName")
                    setFormData((prevState) => ({
                      ...prevState,
                      firstName: e.target.value
                    }));
                  }}
                  placeholder="First Name"
                />
                {errorObj.firstName ? (
                  <p style={{ color: "red" }}>Please Enter First Name</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="last-name" label="Last Name">
                <Input
                  defaultValue={driverData.lastName || ""}
                  onChange={(e) => {
                    console.log("inside the last Name---->");
                    // handleErrorObj(e.target.value,"lastName")
                    setFormData((prevState) => ({
                      ...prevState,
                      lastName: e.target.value
                    }));
                  }}
                  placeholder="Last Name"
                />
                {errorObj.lastName ? (
                  <p style={{ color: "red" }}>Please Enter The Last Name</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="email" label="E-mail">
                <Input
                  // addonBefore="E-Mail"
                  defaultValue={driverData.email || ""}
                  onChange={(e) => {
                    console.log("inside the email----->");
                    // handleErrorObj(e.target.value,"email");
                    setFormData((prevState) => ({
                      ...prevState,
                      email: e.target.value
                    }));
                  }}
                  placeholder="E-mail"
                />
                {errorObj.email ? (
                  <p style={{ color: "red" }}>Please Enter Email</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="phone" label="Contact Number">
                <Input
                  defaultValue={driverData.phoneNumber || ""}
                  onChange={(e) => {
                    console.log("phoneNumber----->");
                    // handleErrorObj(e.target.value,"phoneNumber");
                    setFormData((prevState) => ({
                      ...prevState,
                      phoneNumber: e.target.value
                    }));
                  }}
                  placeholder="Contact Number"
                />
                {errorObj.phoneNumber ? (
                  <p style={{ color: "red" }}>Please Enter Phone Number</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="houseNumber" label="Address House No.">
                <Input.TextArea
                  defaultValue={driverData?.address?.houseNumber || ""}
                  onChange={(e) => {
                    console.log("inside address1---->", e.target.value);
                    // handleErrorObj(e.target.value,"houseNumber");
                    setFormData((prevState) => ({
                      ...prevState,
                      houseNumber: e.target.value
                    }));
                  }}
                  placeholder="Address House Number"
                />
                {errorObj.houseNumber ? (
                  <p style={{ color: "red" }}>Please Enter House Number</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="streetName" label="Address Street">
                <Input.TextArea
                  defaultValue={driverData?.address?.street || ""}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      street: e.target.value
                    }));
                  }}
                  placeholder="Address Street"
                />
                {errorObj.street ? (
                  <p style={{ color: "red" }}>Please Enter Street</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="city" label="City">
                <Input
                  defaultValue={driverData?.address?.city || ""}
                  onChange={(e) => {
                    console.log(
                      "inside the city formItem---->",
                      e.target.value
                    );
                    //  handleErrorObj(e,"city");
                    setFormData((prevState) => ({
                      ...prevState,
                      city: e.target.value
                    }));
                  }}
                  placeholder="City"
                />
                {errorObj.city ? (
                  <p style={{ color: "red" }}>Please Enter City</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item label="State" name="state">
                <Input
                  defaultValue={driverData?.address?.state || ""}
                  onChange={(e) => {
                    console.log(
                      "inside the state formItem---->",
                      e.target.value
                    );
                    // handleErrorObj(e,"state");
                    setFormData((prevState) => ({
                      ...prevState,
                      state: e.target.value
                    }));
                  }}
                  placeholder="State"
                />
                {errorObj.state ? (
                  <p style={{ color: "red" }}>Please Enter State</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="pin" label="PIN">
                <Input
                  defaultValue={driverData?.address?.pinCode || ""}
                  onChange={(e) => {
                    console.log("inside the pin formItem---->", e.target.value);
                    // handleErrorObj(e,"pinCode");
                    setFormData((prevState) => ({
                      ...prevState,
                      pinCode: e.target.value
                    }));
                  }}
                  placeholder="PIN"
                />
                {errorObj.pinCode ? (
                  <p style={{ color: "red" }}>Please Enter Pin Code</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="country" label="Country">
                <Input
                  defaultValue={driverData?.address?.country || ""}
                  onChange={(e) => {
                    console.log(
                      "inside the country formItem---->",
                      e.target.value
                    );
                    // handleErrorObj(e,"country");
                    setFormData((prevState) => ({
                      ...prevState,
                      country: e.target.value
                    }));
                  }}
                  placeholder="Country"
                />
                {errorObj.country ? (
                  <p style={{ color: "red" }}>Please Enter Country</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="vehicletype" label="Vehicle Type">
                <Input
                  defaultValue={driverData?.vehicle?.vehicleType || ""}
                  onChange={(e) => {
                    console.log(
                      "inside the state formItem---->",
                      e.target.value
                    );
                    // handleErrorObj(e,"vehicleType");
                    setFormData((prevState) => ({
                      ...prevState,
                      vehicleType: e.target.value
                    }));
                  }}
                  placeholder="Vehicle Type"
                />
                {errorObj.vehicleType ? (
                  <p style={{ color: "red" }}>Please Enter vehicle Type</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="vehicleno" label="Vehicle No.">
                <Input
                  defaultValue={driverData?.vehicle?.vehicleRegNumber || ""}
                  onChange={(e) => {
                    console.log(
                      "inside the vehicle formItem---->",
                      e.target.value
                    );
                    // handleErrorObj(e,"vehicleNumber");
                    setFormData((prevState) => ({
                      ...prevState,
                      vehicleRegNumber: e.target.value
                    }));
                  }}
                  placeholder="Vehicle No."
                />
                {errorObj.vehicleRegNumber ? (
                  <p style={{ color: "red" }}>
                    Please Enter vehicle Registration Number
                  </p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="licenceno" label="License No">
                <Input
                  defaultValue={driverData?.licenseNumber || ""}
                  onChange={(e) => {
                    console.log(
                      "inside the license formItem---->",
                      e.target.value
                    );
                    // handleErrorObj(e,"licenseNumber");
                    setFormData((prevState) => ({
                      ...prevState,
                      licenseNumber: e.target.value
                    }));
                  }}
                  placeholder="Licence No."
                />
                {errorObj.licenseNumber ? (
                  <p style={{ color: "red" }}>Please Enter licenseNumber</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="vehiclecolor" label="Vehicle Color">
                <Input
                  defaultValue={driverData?.vehicle?.vehicleColor || ""}
                  onChange={(e) => {
                    console.log(
                      "inside the vehicle color formItem---->",
                      e.target.value
                    );
                    // handleErrorObj(e,"vehicleColor");
                    setFormData((prevState) => ({
                      ...prevState,
                      vehicleColor: e.target.value
                    }));
                  }}
                  placeholder="Vehicle Color"
                />
                {errorObj.vehicleColor ? (
                  <p style={{ color: "red" }}>Please Enter vehicle color</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} style={{ padding: "10px" }}>
              <Form.Item name="driverFee" label="Driver Fee">
                <Input
                  defaultValue={driverData?.driverFee?.perDel || 0}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      driverFeePerDel: e.target.value
                    }));
                  }}
                  placeholder="driver Fees Per Delievery"
                />
                {errorObj.driverFeePerDel ? (
                  <p style={{ color: "red" }}>
                    Please Enter Driver Fees For Each Delivery.
                  </p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} style={{ padding: "10px" }}>
              <Form.Item name="inputExtraFeePerKm" label="Extra Fee Per KM">
                <Input
                  defaultValue={inputExtraFee}
                  value={inputExtraFee}
                  placeholder="Type Extra Fee"
                  onChange={(e) => {
                    //setFormData((prevState)=>({...prevState, inputExtraFee:e.target.value}))
                    setExtraFee(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} style={{ padding: "10px" }}>
              <Form.Item
                name="inputDistanceForFlatRate"
                label="Distance For Flat Rate"
              >
                <Input
                  defaultValue={inputDistanceForFlatRate}
                  value={inputDistanceForFlatRate}
                  placeholder="Type Distance"
                  onChange={(e) => {
                    setDistanceForFlatRate(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24} style={{ padding: "10px" }}>
              {props.add ? (
                <Form.Item name="password" label="Password">
                  <Input
                    onChange={(e) => {
                      console.log("password=====>", formData.password);
                      setFormData((prevState) => ({
                        ...prevState,
                        password: e.target.value
                      }));
                      // setPassword((e.target.value)
                      // setFormData((prevState)=>({...prevState,password:e.target.value}))
                    }}
                    placeholder="password"
                  />
                  {errorObj.password ? (
                    <p style={{ color: "red" }}>Please Enter password.</p>
                  ) : (
                    ""
                  )}
                </Form.Item>
              ) : (
                ""
              )}
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}></Col>
          </Row>

          {props.edit && (
            <>
              <Row>
                <Col className="col-4">
                  <Form.Item name="range1Min" label="Range 1 Minimum ">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range1Min || ""}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range1Min: e.target.value
                        }));
                      }}
                      placeholder="Range 1 Minimum "
                    />
                    {errorObj.range1Min ? (
                      <p style={{ color: "red" }}>
                        Please Enter Minium value 1 for driver fees per km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="range1Max" label="Range 1 Max">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range1Max || ""}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range1Max: e.target.value
                        }));
                      }}
                      placeholder="Range 1 Max"
                    />
                    {errorObj.range1Max ? (
                      <p style={{ color: "red" }}>
                        Please Enter Maximum 1 Value for driver Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="perKmFee1" label="Driver Fees Per Km">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.perKmFee1 || ""}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          perKmFee1: e.target.value
                        }));
                      }}
                      placeholder="Driver Fees Per Km"
                    />
                    {errorObj.perKmFee1 ? (
                      <p style={{ color: "red" }}>
                        Please Enter Driver Fees Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="col-4">
                  <Form.Item name="range2Min" label="Range 2 Minimum ">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range2Min || ""}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range2Min: e.target.value
                        }));
                      }}
                      placeholder="Range 2 Minimum "
                    />
                    {errorObj.range2Min ? (
                      <p style={{ color: "red" }}>
                        Please Enter Minium value 2 for driver fees per km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="range2Max" label="Range 2 Max">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range2Max || ""}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range2Max: e.target.value
                        }));
                      }}
                      placeholder="Range 2 Max"
                    />
                    {errorObj.range2Max ? (
                      <p style={{ color: "red" }}>
                        Please Enter Maximum 2 Value for driver Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="perKmFee2" label="Driver Fees Per Km">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.perKmFee2 || ""}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          perKmFee2: e.target.value
                        }));
                      }}
                      placeholder="Driver Fees Per Km"
                    />
                    {errorObj.perKmFee1 ? (
                      <p style={{ color: "red" }}>
                        Please Enter Driver Fees Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {props.edit && (
            <>
              <Row>
                <Col className="col-4">
                  <Form.Item name="range3Min" label="Range 3 Minimum ">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range3Min || ""}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range3Min: e.target.value
                        }));
                      }}
                      placeholder="Range 3 Minimum "
                    />
                    {errorObj.range3Min ? (
                      <p style={{ color: "red" }}>
                        Please Enter Minium value 3 for driver fees per km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="range3Max" label="Range 3 Max">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range3Max || ""}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range3Max: e.target.value
                        }));
                      }}
                      placeholder="Range 3 Max"
                    />
                    {errorObj.range3Max ? (
                      <p style={{ color: "red" }}>
                        Please Enter Maximum 3 Value for driver Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="perKmFee3" label="Driver Fees Per Km">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.perKmFee3 || ""}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          perKmFee3: e.target.value
                        }));
                      }}
                      placeholder="Driver Fees Per Km"
                    />
                    {errorObj.perKmFee3 ? (
                      <p style={{ color: "red" }}>
                        Please Enter Driver Fees Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Form.Item label="Description">
              <Input.TextArea
                defaultValue={driverData?.description || ""}
                onChange={(e) => {
                  console.log(
                    "inside the description formItem---->",
                    e.target.value
                  );
                  setFormData((prevState) => ({
                    ...prevState,
                    description: e.target.value
                  }));
                }}
                placeholder="Description"
              />
            </Form.Item>
          </Row>

          {props.edit ? (
            <>
              <Row>
                <Col className="col-12 justify-content-evenly flex">
                  <Switch
                    checkedChildren="Vehicle"
                    unCheckedChildren="Vehicle"
                    defaultChecked
                  />
                  <Switch
                    checkedChildren="VERIFIED"
                    unCheckedChildren="UNVERIFIED"
                    onChange={(checked) => {
                      verifyDriver(checked, props.driver._id);
                    }}
                    checked={verifiedDriver}
                  />
                  <Switch
                    className="block-check"
                    onChange={(checked) => {
                      onBlockChange(checked, props.driver._id);
                    }}
                    checkedChildren="Blocked"
                    unCheckedChildren="Un-blocked"
                    checked={blockDriver}
                  />
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}

          <Row className=" justify-content-center pt-5">
            {/* <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}> */}
            <Button type="primary" htmlType="submit">
              {props.edit ? "Save" : "ADD"}
            </Button>
            {/* </Form.Item> */}
          </Row>
        </Form>
      </div>
    </div>
  );
}
