import {createSlice} from "@reduxjs/toolkit";

const orderSlice=createSlice({
    name:"driverOrders",
    initialState:[],
    reducers:{
        fetchDriverOrders:(state,action)=>{
            state=action.payload;
            return state;
        }
    }
});

export const {fetchDriverOrders}=orderSlice.actions;
export default orderSlice.reducer;