import {createSlice} from "@reduxjs/toolkit";

const restaurantsReportsSlice=createSlice({
    name:"restaurantReportFetch",
    initialState:[],
    reducers:{
        fetchRestReports:(state,action)=>{
            state=action.payload;
            return state;
        }
    }
});

export const {fetchRestReports}=restaurantsReportsSlice.actions;
export default restaurantsReportsSlice.reducer;