import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Row,
  Col,
  Divider,
  DatePicker,
  Tooltip,
  Select,
  Button,
  Tag
} from "antd";
import moment from "moment";
import api from "../../../commonFuncs/api";
import s3url from "../../../helpers/s3";
import { NavLink } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import TableSumX from './../../Table/TableSumX'


const Daily = (props) => {
    const [option, setOption] = useState("ALL");
  const [allData, setAllData] = useState([]);
  const [onlineDate, setOnlineDate] = useState("");
  const [filterOrdered, setFilteredOrder] = useState([])
  const [loading, setLoading] = useState(false);
  const [statusChanged, setStatusChanged] = useState(false)
  const { Option } = Select;


  useEffect(() => {
    //fetchAllOrders();
    console.log("filter data is-----", filterOrdered)
}, [filterOrdered])

  useEffect(() => {
    console.log("statusChanged--------------")
    setLoading(true);
    const date = onlineDate ? onlineDate : moment().date()._d;
    filterDate(date);
}, [statusChanged])

  const fetchAllOrders = async (data) => {
    try {
     console.log("api data", data)
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/getInvoices`,
      data
      );
      console.log("result in oppogjor=====>", result.data.data);
      setAllData(result.data.data)
      setFilteredOrder(result.data.data)
      setLoading(false)
    } catch (err) {
      console.log("error--->", err.message);
    }
  };


  const filteredResult = (e) => {
    console.log("cjkkklk====", e)
    setOption(e);

    if (e.toLowerCase() != "all") {
        let filterData = allData.filter((val) => {
            return val.orderStatus.toLowerCase() == e.toLowerCase();
        })
        setFilteredOrder(filterData)
    } else {
        setFilteredOrder(allData)
    }
}


const filterDate = (date) => {
  setOnlineDate(date)
  const startDate = moment(date);
  //console.log("date =====", startDate)
  const endDate = moment(date).add(1, "days");
  let postData = {
      startDate: startDate,
      endDate: endDate
  }
  console.log("======data=====", postData)
  fetchAllOrders(postData)
}

const columns = [
  {
      title: "S.No",
      key: "orderNumber",
      width: "5%",
      fixed: "center",
      render: (record, item, index) => {
            return <span>{index + 1}</span>;
      }

  },
  {
    title: "Invoice Number",
    key: "invoiceNumber",
    width: "9%",
    render: (text, record) =>{
      return <span>{ record.invoiceNumber}</span>
    }
},
   {
      title: "Order Date",
      key: "orderDate",
      width: "12%",
      render: (text, record) =>{
        return <span>{ `${moment(record.createdAt).format("MMMM DD, YYYY")}`}</span>
      }
  },
  { title: "Order Time",
    key: "orderTime",
    width: "10%",
    render: (text, record) =>{
      return <span>{ `${new Date(record.createdAt).toLocaleTimeString()}`}</span>
    }
  },
  {
      title: "No. of Products",
      key: "noProducts",
      width: "9%",
      render: (text, record) =>{
        return <span>{ record.items.length}</span>
      }
  },
  {
      title: "Order From",
      key: "OrderFrom",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span> {record.customerType == "AND"  ? "Android" : "IOS"}</span>
            <span> - {record.orderFrom == "TDP"  ? "TD" : "Web"}</span>
          </>
        )
      }
  },
  {
      title: "Total",
      key: "total",
      width: "10%",
      render: (text, record) => {
          return <span> {`Kr. ${record.totalAmount.toFixed(2)}`} </span>;
      }
  }, {
      title: "Action",
      //key: "view",
      width: "10%",
      // className:"view-title",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="View" placement="top" color={"blue"}>
            <span>
            
              <NavLink
                to="/tflDailyReport"
                state={{ record: record  }}
              >
                <EyeOutlined />
              </NavLink>
            </span>
          </Tooltip>
        </Space>
      )
  }
]


let totalOrderPrice = 0;
filterOrdered.map((e)=>{
  totalOrderPrice += e.totalAmount;
})

const ReportSummaryData = [
  {
      key: "-1",
      render: () => "test"
  }
];


const ReportSummary = (totalOrderPrice) => [
  {
      title: "S.No",
      key: "S.No",
      width: "73%",
      render: () => <strong>Total</strong>

  },
  {
      title: "Total",
      key: "total",
      render: () => {
        return (
          <b>Kr. {totalOrderPrice.toFixed(2)}</b>
        )
      }

  }
]


  return (
    <>
    <div className="op-table">
      <Row className="justify-content-between">
        <h4>TFL Daily Report</h4>
        <Col>
          <Space direction="vertical">
          <DatePicker
                onChange={(date, dateString) => {
                filterDate(date)
                }}
                format="YYYY-MM-DD"
                placeholder="Select day"
          />
          </Space>
          <Space direction="vertical">
          </Space>
        </Col>
        
      </Row>
      <Divider />
          <TableSumX
          scroll={{ x: true }}
          className="user-table2"
          loading={loading}
          columns={columns}
          data={filterOrdered}
          summaryData={ReportSummaryData}
          summaryColumns={ReportSummary(totalOrderPrice)}
          striped
          bordered
          hover
          rowKey={props.rowKey}
        />
    </div>
  </>
  )
}

export default Daily
