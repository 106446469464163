import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import { Layout } from 'antd';

import './dash.css';

import logo from "../../assets/logo.png";

import React, { useState } from 'react';

import SideNav from './sidebarnav/SideNav';
import ProfileDrop from './topheader/ProfileDrop';

const { Header, Sider } = Layout;

const MainDash = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Layout className="main">
      <Sider trigger={null} collapsible collapsed={collapsed} className='sideCollpase'>
        <div className="logo" align="center">
          <img src={logo} style={{ width: "60px" }} />
        </div>
        <SideNav />
      </Sider>
      <Layout className="site-layout">
        <Header>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
          <ProfileDrop />
        </Header>
        <div className="body-main">
          {console.log("children props---->", props)}
          {props.Component != undefined ? <props.Component /> : ""}
        </div>
      </Layout>
    </Layout>
  );
};

export default MainDash;