import {configureStore} from "@reduxjs/toolkit";
import driverReducer from "./driversReducer.js";
import driverOrders from "./orders/orderPerDriverRed";
import driverPayment from "./drivers/driverPayRed";
import restaurantReducer from "./restuarants/restReducer";
import orderPerRestaurantRed from "./../reducers/restuarants/orderPerResRed";
import orderTotalPerRestaurantRed from "./../reducers/restuarants/orderTotalPerResRed";
import restReportRed from "./reports/restaurantReport/restReportRed";
import oppgjorRed from "./oppgjor/oppgjorRed";
import dashboardRed from "./dashboard/dashboardRed.js";
import allUsersRed from "./users/allUsersRed";
import singleUserOrderRed from "./users/singleUserOrderRed";
const store=configureStore({
   
    reducer:{
        drivers:driverReducer,
        driverOrder:driverOrders,
        driverPayment:driverPayment,
        restaurantReducer:restaurantReducer,
        ordersPerRestaurant:orderPerRestaurantRed,
        ordersTotalPerRes:orderTotalPerRestaurantRed,
        restReports:restReportRed,
        oppgjorRed:oppgjorRed,
        dashboardRed:dashboardRed,
        allUsersRed:allUsersRed,
        singleUserOrderRed:singleUserOrderRed
    }
})

export default store;