import {Row, Col} from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';

export  default function EditUser(){
    const onFinish = (values) => {
        console.log(values);
      };
    
          return (
            <>
              <div className='add-r-main bg-white pb-2'>
              <Row className='justify-content-between align-items-center add-r-head px-4 py-3'>
                <Col>
                  <h3>Edit User Details</h3>
                </Col>
                <Col className='col-3 flex justify-content-between'>
                <NavLink to='/viewuser' className="common-btn">Save</NavLink>
                <NavLink to='/userlistmain' className="common-btn">Return to the List</NavLink>
                </Col>
              </Row>
              </div>
            </>
          );
};