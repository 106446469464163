import React, { useEffect, useRef, useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Table,
  Row,
  Col,
  Input,
  Button,
  notification,
  Form,
  InputNumber,
  Popconfirm,
  Typography
} from "antd";
import AddPoints from "./AddPoints";
import api from "../../commonFuncs/api";
import CustomPopupConfirm from "./CustomPopupConfirm";

//---------------------------------------- cell edit --------------------------------------------- //
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Point = (props) => {
  const [form] = Form.useForm();
  let [allData, setAllData] = useState([]);
  const [pageStatus, setPageStatus] = useState("LIST");
  const [inputType, setInputType] = useState("NoEditable");
  const [addId, setAddId] = useState();
  const [isPopup, setPopup] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [nok, setNok] = useState("");
  const [Id, setId] = useState("");
  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    listPoint();
  }, []);

  //-------------------- new key added (for row wise editing  ) --------------------- //

  allData = allData.map((obj, index) => {
    obj["key"] = index.toString();
    return obj;
  });

  const edit = (record) => {
    console.log("edit", record);
    setId(record._id);
    form.setFieldsValue({
      key: "",
      SNo: "",
      points: "",
      nok: "",
      ...record
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };

  // ============ get api for print all the points ============== //
  const indexPage = () => {
    setPageStatus("LIST");
    listPoint();
  };

  const listPoint = async () => {
    try {
      let result = await api.get(
        `/api/v1/treeDriveAdmin/appsettings/settings/getPoints`
      );
      setAllData(result.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // ============= model open when click on delete btn =================== //

  const showConformationDialog = (id) => {
    setAddId(id);
    setPopup(true);
  };
  const cancelConformationDialog = () => {
    setPopup(false);
  };

  // ======================= delete api =============================== //

  const deleteApi = async () => {
    console.log("id == ", addId);
    try {
      let res = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/settings/deletePoints`,
        { id: addId }
      );

      if (res.status == 200) {
        setPopup(false);
        notification.open({
          message: "Your data  deleted successfully!",
          //icon: <Icon type="check-circle" style={{ color: "green" }} />,
          duration: 2
        });
        listPoint();
      }
    } catch (error) {
      console.log("something went wrong");
      setPopup(false);
    }
  };

  // ======================= edit  api =============================== //

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      console.log("row", row.nok);
      setNok(row.nok);
      const newData = [...allData];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        setAllData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setAllData(newData);
        setEditingKey("");
      }
      // if(nok && Id){
      let res = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/settings/editPoints`,
        { id: Id, nok: row.nok }
      );
      if (res.status == 200) {
        setPopup(false);
        notification.open({
          message: "Your data  deleted successfully!",
          duration: 2
        });
        listPoint();
        //  }
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "S.No",
      fixed: "left",
      key: "SNo",
      width: "15%",
      editable: false,
      render: (record, item, index) => {
        return Number(record.key) + 1;
      }
    },
    {
      title: "Coins",
      fixed: "left",
      key: "points",
      // width: "30%",
      dataIndex: "points",
      editable: false
    },
    {
      title: "NOK Conversion in gift cards",
      key: "nok",
      // width: "30%",
      editable: true,
      dataIndex: "nok"
    },
    {
      title: "Action",
      dataIndex: "action",
      editable: false,
      width: "30%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Typography.Link>
            <Typography.Link
              className="ml-4"
              disabled={editingKey !== ""}
              onClick={() => showConformationDialog(record._id)}
            >
              <DeleteOutlined />
            </Typography.Link>
          </>
        );
      }
    }
  ];

  //==================== edit cell code (antd) ======================== //
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "nok" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  const addPage = () => {
    return (
      <div className="user-list">
        <AddPoints indexPage={indexPage} />
      </div>
    );
  };
  const listPage = () => {
    return (
      <div className="user-list">
        <Row className="justify-content-between py-2">
          <Col>
            <h4>Coin Redemption Table</h4>
          </Col>
          <Col>
            <Button
              className="common-btn2"
              onClick={() => {
                setPageStatus("ADD");
              }}
            >
              Add New Points
            </Button>
          </Col>
        </Row>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell
              }
            }}
            bordered
            dataSource={allData}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: cancel
            }}
            scroll={{ x: "max-content", y: "max-content" }}
          />
        </Form>
      </div>
    );
  };

  return (
    <>
      {pageStatus === "LIST"
        ? listPage()
        : pageStatus === "EDIT"
        ? addPage()
        : addPage()}
      <CustomPopupConfirm
        visible={isPopup}
        cancel={cancelConformationDialog}
        productId={addId}
        action={deleteApi}
      />
    </>
  );
};

export default Point;
