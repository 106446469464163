import {createSlice} from "@reduxjs/toolkit";

const orderTotalPerRestaurantSlice=createSlice({
    name:"orderTotalPerRestaurant",
    initialState:[],
    reducers:{
        fetchOrdersTotalPerRestaurant:(state,action)=>{
            state=action.payload;
            return state;
        }
    }
});

export const {fetchOrdersTotalPerRestaurant}=orderTotalPerRestaurantSlice.actions;
export default orderTotalPerRestaurantSlice.reducer;