import { Row, Col, Button, Table, DatePicker, Space } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDriverPayment } from "./../../reducers/drivers/driverPayRed";
import moment from "moment";
import api from "../../commonFuncs/api";
import openNotification from "./../../commonFuncs/notification";
import dateFormat from "./../../commonFuncs/dateFormat";
import startEndDay from "./../../commonFuncs/moment/endStart";
export default function AllOrders(props) {
  const { handlePageChange, driver } = props;
  console.log("driver data in view payemnt history of driver===>", driver);
  const [paymentFetched, setPaymentFetched] = useState(false);
  const [dateSelected, setDateSelected] = useState(moment(new Date()));
  const [dateChange, setDateChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(dateSelected.startOf("day").toString()).format()
  );
  const [endDate, setEndDate] = useState(
    moment(dateSelected.endOf("day").toString()).format()
  );
  let totalEarning = 0;
  const dispatch = useDispatch();
  const paymentData = useSelector((state) => state.driverPayment);
  useEffect(() => {
    fetchPayment(driver._id);
  }, [dateChange]);
  const fetchPayment = async (driverId) => {
    try {
      setLoading(true);
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appdriver/paymentPerDriver`,
        {
          driverId: driverId,
          startDate: startDate,
          endDate: endDate
        }
      );
      if (result.data.status == 200) {
        openNotification("success", "SUCCESS", result.data.message);
        setPaymentFetched(true);
        //   paymentData.push(result.data.data.driver);
        setLoading(false);
        console.log("data source inside the fetch==>", result.data.data.driver);
        dispatch(fetchDriverPayment(result.data.data.driver));
      } else {
        openNotification("error", "ERROR", result.data.message);
        setPaymentFetched(false);
        setLoading(false);
      }
      console.log("reulst in driver payment fetch===>", result.data);
    } catch (err) {
      console.log("error in payment driver fethc===>", err.message);
      setPaymentFetched(false);
      setLoading(false);
      openNotification("error", "ERROR", err.message);
    }
  };

  const onDateSelect = (date, dateString, dmy) => {
    let formattedDate = startEndDay(date, dmy);
    setStartDate(formattedDate.startDate);

    setEndDate(formattedDate.endDate);

    setDateSelected(date);
    setDateChange(!dateChange);
  };
  console.log("dataSource======>", paymentData);
  console.log("loading status====>", loading);
  const columns = [
    {
      title: "TOTAL",
      children: [
        {
          title: "Earning Before",
          key: "totalEarningBefore",
          render: (record, item, index) => {
            return item.totalEarningBeforePayment;
          }
        },
        {
          title: "Pending Before",
          key: "totalPendingBefore",
          render: (record, item, index) => {
            return item.totalPendingBeforePayment;
          }
        }
      ]
    },
    {
      title: "Amount Paid",
      key: "paid amount",
      render: (record, item, index) => {
        return item.paidAmount;
      }
    },
    {
      title: "Paid On Date",
      key: "paidondate",
      render: (record, item, index) => {
        return moment(new Date(item.paidOn)).format("dddd DD/MMM/YYYY hh:mm A");
      }
    },
    {
      title: "TOTAL",
      children: [
        {
          title: "Pending After ",
          key: "totalPendingAfter",
          render: (record, item, index) => {
            return item.totalPendingAfterPayment;
          }
        },
        {
          title: "Earning After ",
          key: "totalEarningAfter",
          render: (record, item, index) => {
            return item.totalEarningAfterPayment;
          }
        }
      ]
    }
  ];
  const footerColoumn = [
    {
      title: "Total Earning",
      key: "totalEarning",
      render: (record, item, index) => {
        return "Kr. " + item.total;
      }
    }
  ];
  const footerData = [
    {
      total: totalEarning
    }
  ];

  return (
    <>
      <Row className="justify-content-between align-items-center">
        <Col>
          <h3>
            {driver.firstName} {driver.lastName}'s Payment History
          </h3>
        </Col>
        <Col className="col-2 flex justify-content-end">
          <Button
            onClick={() => {
              handlePageChange("VIEW");
            }}
            className="common-btn2"
          >
            Return
          </Button>
        </Col>
      </Row>
      <Row className="py-3">
        <Space direction="vertical">
          <DatePicker
            onChange={(date, dateString) =>
              onDateSelect(date, dateString, "day")
            }
          />
        </Space>
        <Space direction="vertical">
          <DatePicker
            onChange={(date, dateString) =>
              onDateSelect(date, dateString, "month")
            }
            picker="month"
          />
        </Space>
      </Row>

      <Table
        className="user-table"
        loading={loading}
        columns={columns}
        dataSource={paymentData}
        striped
        bordered
        hover
        pagination={{
          pageSize: 10
        }}
      />
    </>
  );
}
