import React, { useEffect, useRef, useState } from "react";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  LeftCircleOutlined,
} from "@ant-design/icons";
import {
  Table, Space, Row, Col, Rate, Tag,
  Input, Button, Tooltip, notification, Icon,
  Form, InputNumber, Popconfirm, Typography } from "antd";
import AddDonation from "./AddDonation";
import api from "../../commonFuncs/api";
import CustomPopupConfirm from "./CustomPopupConfirm";
import './Donation.css'
const { TextArea } = Input;

//---------------------------------------- cell edit --------------------------------------------- //
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number"  ? <InputNumber  /> : <TextArea 
        autoSize={{
          minRows: 2,
          maxRows: 50,
          minCols: 5,
          maxCols:10}} />;
  return (
    
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};




const Donation = () => {
    const [form] = Form.useForm();
    let  [allData, setAllData] = useState([]);
    const [pageStatus, setPageStatus] = useState("LIST");
    const [inputType, setInputType] = useState("NoEditable");
    const [addId, setAddId] = useState();
    const [isPopup, setPopup] = useState(false);
    const [editingKey, setEditingKey] = useState("");
    const [nok, setNok] = useState("");
    const [Id, setId] = useState("");
    const isEditing = (record) => record.key === editingKey;
  
  
    useEffect(() => {
      listPoint();
    }, []);
  
    //-------------------- new key added (for row wise editing  ) --------------------- //
  
    allData = allData.map((obj , index )=> {
      obj['key'] = index.toString();
      return obj;
    });
  
    const edit = (record) => {
      console.log("edit", record)
      setId(record._id);
      form.setFieldsValue({
        key:"",
        SNo:"",
        points:"",
        requiredPoints: "",
        ...record,
      });
      setEditingKey(record.key);
    };
    const cancel = () => {
      setEditingKey("");
    };
  
  
    // ============ get api for print all the points ============== //
    const indexPage = () => {
      setPageStatus("LIST");
      listPoint();
    };
  
  
    const listPoint = async () => {
      try {
        let result = await api.get(
          `/api/v1/treeDriveAdmin/appsettings/AllDonations`
        );
        setAllData(result.data.data);
      } catch (error) {
        console.log(error);
      }
    };
  
  
    // ============= model open when click on delete btn =================== //
  
    const showConformationDialog = (id) => {
      setAddId(id);
      setPopup(true);
    };
    const cancelConformationDialog = () => {
      setPopup(false);
    };
  
    // ======================= delete api =============================== //
  
    const deleteApi = async () => {
      console.log("id == ", addId);
      try {
        let res = await api.post(
          `/api/v1/treeDriveAdmin/appsettings/deleteDonation`,
          { id: addId }
        );
  
        if (res.status == 200) {
          setPopup(false);
          notification.open({
            message: "Your data  deleted successfully!",
            //icon: <Icon type="check-circle" style={{ color: "green" }} />,
            duration: 2,
          });
          listPoint();
        }
      } catch (error) {
        console.log("something went wrong");
        setPopup(false);
      }
    };
  
  
  
  // ======================= edit  api =============================== //
  
   
const save = async (key) => {
  try {
    const row = await form.validateFields();
    const newData = [...allData];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setAllData(newData);
      setEditingKey("");
    } else {
      newData.push(row);
      setAllData(newData);
      setEditingKey("");
    }
    if(editingKey){
      console.log("row", row)

      let payload = {};
      payload.id = Id;
      payload.donationType = row.donationType;
      payload.description = row.description;
      payload.NOK =  row.NOK;
      const token = localStorage.getItem("token");
    let res = await api.post(
      `/api/v1/treeDriveAdmin/appsettings/updateDonation`,
    payload
      , {
        headers: {
          // "Content-Type": "application/json",
          "x-auth-token": token,
        },
      },
    );
    if (res.status == 200) {
      setPopup(false);
      notification.open({
        message: "Your data updated successfully!",
        //icon: <Icon type="check-circle" style={{ color: "green" }} />,
        duration: 2,
      });
      listPoint();
    }
    }
  } catch (errInfo) {
    console.log("Validate Failed:", errInfo);
    // notification.open({
    //   message: "Select your data again!",
    //   //icon: <Icon type="check-circle" style={{ color: "green" }} />,
    //   duration: 2,
    // });
  }
};
  


    const columns = [
      {
        title: "SNo",
        fixed: "left",
        key: "SNo",
        width: "10%",
        editable: false,
        render: (record, item, index) => {
          return Number(record.key) + 1  ;
        },
      },
      {
        title: "Donation ",
        fixed: "left",
        key: "donationType",
        width: "20%",
        dataIndex: "donationType",
        editable: true,
      },
      {
        title: "Donation NOK",
        key: "requiredPoints",
        width: "15%",
        editable: true,
        dataIndex: "NOK",
      },
      {
        title: "Description",
        key: "description",
        width: "25%",
        dataIndex: "description", 
        editable: true,
      },
      {
        title: "Action",
        dataIndex: "action",
        editable: false,
        width: "10%",
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
          );
        },
      },
      {
        title: "Action",
        fixed: "right",
        key: "action",
        width: "10%",
        render: (_, record) => (
          <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => showConformationDialog(record._id)
              }
            >
              Delete
            </Typography.Link>
        )
      
      },
    ];
    
    
    // const columns = [
    //   {
    //     title: "SNo",
    //     fixed: "left",
    //     key: "SNo",
    //     width: "8%",
    //     editable: false,
    //     render: (record, item, index) => {
    //       return Number(record.key) + 1  ;
    //     },
    //   },
    //   {
    //     title: "Donation For",
    //     fixed: "left",
    //     key: "donation",
    //     width: "20%",
    //     dataIndex: "donationType",
    //     editable: false,
    //   },
    //   {
    //     title: "Donation NOK",
    //     key: "nok",
    //     width: "12%",
    //     editable: true,
    //     dataIndex: "NOK",
    //     align: 'center',
    //   },
    //   {
    //     title: "Description",
    //     fixed: "left",
    //     key: "description",
    //     width: "40%",
    //     dataIndex: "description",
    //     editable: false,
    //   },
    //   {
    //     title: "Action",
    //     dataIndex: "action",
    //     editable: false,
    //     width: "10%",
    //     render: (_, record) => {
    //       const editable = isEditing(record);
    //       return editable ? (
    //         <span>
    //           <Typography.Link
    //             onClick={() => save(record.key)}
    //             style={{
    //               marginRight: 8,
    //             }}
    //           >
    //             Save
    //           </Typography.Link>
    //           <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //             <a>Cancel</a>
    //           </Popconfirm>
    //         </span>
    //       ) : (
    //         <Typography.Link
    //           disabled={editingKey !== ""}
    //           onClick={() => edit(record)}
    //         >
    //           Edit
    //         </Typography.Link>
    //       );
    //     },
    //   },
    //   {
    //     title: "Action",
    //     fixed: "right",
    //     key: "action",
    //     width: "10%",
    //     render: (_, record) => (
    //       <Typography.Link
    //           disabled={editingKey !== ""}
    //           onClick={() => showConformationDialog(record._id)}
    //         >
    //           Delete
    //         </Typography.Link>
    //     )
      
    //   },
    // ];
  
  
 
//==================== edit cell code (antd) ======================== //
const mergedColumns = columns.map((col) => {
  if (!col.editable) {
    return col;
  }
  return {
    ...col,
    onCell: (record) => ({
      record,
      inputType: col.dataIndex === "NOK" ? "number" : "text",
      dataIndex: col.dataIndex,
      title: col.title,
      editing: isEditing(record),
    }),
  };
});


  
  
  
    const addPage = () => {
      return (
        <>
          <div className="user-list">
            <Row className="justify-content-center py-2" >
              <div className="row">
                <div className="col-12">
                  <AddDonation indexPage={indexPage} />
                </div>
              </div>
            </Row>
          </div>
        </>
      );
    };
    const listPage = () => {
      return (
        <div className="user-list">
          <Row className="justify-content-between py-2" >
            <Col>
              <h4>Donation Table</h4>
            </Col>
            <Col>
              <Button
                className="common-btn2"
                onClick={() => {
                  setPageStatus("ADD");
                }}
              >
                Add New Donation
              </Button>
            </Col>
          </Row>
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={allData}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
              />
            </Form>
        </div>
      );
    };
  
    return (
      <>
        {pageStatus === "LIST" ? listPage() : pageStatus === "EDIT" ? addPage(): addPage()}
        <CustomPopupConfirm
          visible={isPopup}
          cancel={cancelConformationDialog}
          productId={addId}
          action={deleteApi}
        />
      </>
    );
}

export default Donation
