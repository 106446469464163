import { Card, Row, Col} from 'antd';
import React from 'react';
const TopCard = (props) => {
  return (
    <>
     <Row className='top-card'>
     <Col>
      <h4>{props.title}</h4>
      <Card
      bordered={false}>
      <div className='flex justify-content-between top-icon align-items-center'>
      <p>{props.text}</p>
      <span>{props.icon}</span>
      </div>
    </Card>
      </Col>
     </Row>
    </>
  );
};

export default TopCard;