import {createSlice} from "@reduxjs/toolkit";

const singleUserSlice=createSlice({
    name:"singleUserOrder",
    initialState:{},
    reducers:{
        fetchSingleUserOrder:(state,action)=>{
            console.log("action payload in single User order====>",action.payload);
            // state=[...state,action.payload];
            state=action.payload;
            return state;
            // state.push(action.payload)
            // return state;
        }
    }
});

export const {fetchSingleUserOrder}=singleUserSlice.actions;
export default singleUserSlice.reducer;