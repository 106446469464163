import React, { useEffect, useState } from "react";
import { Button, Table, Row, Col, Space, DatePicker, Select } from "antd";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchSingleUserOrder } from "./../../reducers/users/singleUserOrderRed";
import api from "../../commonFuncs/api";
import s3url from "./../../helpers/s3";
import startEndDay from "./../../commonFuncs/moment/endStart";
import formatDateTwoWeeks from "./../../commonFuncs/moment/endStartTwoWeeks";

export default function UserOrderHistory() {
  const [option, setOption] = useState("ALLORDERS");
  const [optionChange, setOptionChange] = useState(false);
  const [dateChange, setDateChange] = useState(false);
  const [page, setPage] = useState(1);
  const [dateSelected, setDateSelected] = useState(moment(new Date()));
  const [startDate, setStartDate] = useState(
    moment("2020-01-01T00:00:00+05:30").format()
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format()
  );
  console.log("inside the app user history====>");
  let navigate = useNavigate();
  let location = useLocation();
  const { singleUserId, singleUserName } = location.state;
  console.log(
    "passed data from user order history view====>",
    singleUserId,
    singleUserName
  );
  const singleUserOrder = useSelector((state) => state.singleUserOrderRed);
  console.log("order of the single user  of the app=====>", singleUserOrder);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchUserOrder();
  }, [dateChange, optionChange]);
  const fetchUserOrder = async () => {
    try {
      console.log("hitting the single user order api=====>");
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appuser/appusersallorders`,
        {
          appUserId: singleUserId,
          startDate: startDate,
          endDate: endDate,
          orderStatus: option
        }
      );
      console.log("result in the single user order history api ====>", result);
      dispatch(fetchSingleUserOrder(result.data.data));
    } catch (err) {
      console.log("error--->", err.message);
    }
  };
  const onDateSelect = (date, dateString, dmy = "Bi-Weekly") => {
    let formattedDate;
    if (dmy == "Bi-Weekly") {
      formattedDate = formatDateTwoWeeks(date);
      setOption("ALL");
    } else {
      setOption("NOTALL");
      formattedDate = startEndDay(date, dmy);
    }
    console.log("formated date ", formattedDate)
    setStartDate(formattedDate.startDate);
    setEndDate(formattedDate.endDate);
    setDateSelected(date);
    setDateChange(!dateChange);
  };
  const { Option } = Select;
  const onOptionsSelect = (e) => {
    setOption(e);
    setOptionChange(!optionChange);
  };
  const deliveredTime = (time) => {
    let hours = Math.floor(time / 60);
    let min = time - hours * 60;
    min = Math.ceil(min);
    if (time < 0) {
      hours = 0;
      min = 0;
    }
    let formatTime = {
      hours: Math.floor(hours),
      minutes: min
    };
    return formatTime;
  };
  const columns = [
    {
      title: "S.No",
      key: "ordersn",
      render: (value, item, index) => {return (page - 1) * 10 + (index +1)}
      // render: (record, item, index) => {
      //   return index + 1
      // }
    },
    //     {
    //   title:"Logo",
    //   key:"logo",
    //   render:(record,item,index)=>{
    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "space-evenly",
    //           alignItems: "center"
    //         }}
    //       >
    //         <img
    //           src={`${s3url}/${item.restaurantLogo[0]}`}
    //           style={{ maxWidth: 80, maxHeight: 100 }}
    //         />
    //       </div>
    //     );
    //   }
    //     // return <h3>{item.restaurantLogo[0]}</h3>
    // },
    {
      title: "Restaurant Name",
      key: "RestauanrtName",
      render: (record, item, index) => {
        return <h5>{item?.branchId?.name}</h5>
      }
    },
    {
      title: "Order Time",
      key: "orderTime",
      render: (record, item, index) => {
        return (
          <>
              {moment(item.createdAt).format("dddd DD/MMMM/YYYY hh:mm A")}
          </>
        );
      }
    },
    {
      title: "Total Price of Products",
      key: "totalpriceofproducts",
      render: (record, item, index) => {
        return (
          <>
            Kr. {item?.taxPriceInc.toFixed(2)}
          </>
        );
      }
    },
    {
      title: "Discount",
      key: "discount",
      render: (record, item, index) => {
        return (
          <>
              Kr. {(item.tdDiscountAbs + item.tdDiscountPickUpAbs).toFixed(2)}
          </>
        );
      }
    },
    {
      title: "Service Charges",
      key: "servicecharge",
      render: (record, item, index) => {
        return (
          <>
            Kr. {item?.tdServiceCharge.toFixed(2)}
          </>
        );
      }
    },
    {
      title: "DriveOut Charges",
      key: "driveoutcharge",
      render: (record, item, index) => {
        return (
          <>
            Kr. {item?.tdDriveOutCharge.toFixed(2)}
          </>
        );
      }
    },
    {
      title: "Total Amount Paid",
      key: "totalamountpaid",
      render: (record, item, index) => {
        return (
          <>
            Kr. {item?.totalPrice.toFixed(2)}
          </>
        );
      }
    },
    {
      title: "Total Distance",
      key: "totaldistance",
      render: (record, item, index) => {
        return (
          <>
            {item?.distance.toFixed(2)} Km
          </>
        );
      }
    },
    {
      title: "Order Type",
      key: "orderType",
      render: (record, item, index) => {
        return (
          <>
            { item.orderType}
          </>
        );
      }
    },
    {
      title: "Current Status From Restaurant",
      key: "currentStatusRes",
      render: (record, item, index) => {
        return (
          <>
              { item.orderType == "Drive Out" && item.orderStatus.toUpperCase() != "DELIVERED"
                ? item.orderStatus.toUpperCase()
                : item.orderType == "Take Away" ? 
                item.orderStatus.toUpperCase() :
                "Handed to Driver"}
          </>
        );
      }
    },
    {
      title: "Current Status From Driver",
      key: "currentStatusDriver",
      render: (record, item, index) => {
        return (
          <>
            { item.orderType == "Drive Out"  && item.orderStatus.toUpperCase() != "CANCEL" ? 
            <>
              <h5>CURRENT STATUS:&nbsp;{item.delieveryStatus.toUpperCase()}</h5>
              <h5>
                CONFIRMED AT: &nbsp;
                {item.confirmedAt
                  ? moment(item.confirmedAt).format("dddd DD/MMMM/YYYY hh:mm A")
                  : "Not Confirmed"}
              </h5>
              <h5>
                PICKED AT: &nbsp;
                {item.pickedAt
                  ? moment(item.pickedAt).format("dddd DD/MMMM/YYYY hh:mm A")
                  : "Not Picked"}
              </h5>
              <h5>
                DELIEVERED AT: &nbsp;
                {item.appOrderDeliveryTime
                  ? moment(item.appOrderDeliveryTime).format(
                      "dddd DD/MMMM/YYYY hh:mm A"
                    )
                  : "Not Delievered"}
              </h5>
              {item.appOrderDeliveryTime ? (
                <h5>
                  TOTAL TIME TAKEN:&nbsp;{deliveredTime(item.delieveredInTime).hours}
                  {" hours "}
                  {deliveredTime(item.delieveredInTime).minutes}
                  {" minutes"}
                </h5>
              ) : (
                ""
              )}
            </>
            :
            //item.orderStatus.toUpperCase() == "CANCEL" ? 
            item.orderType == "Drive Out"  && item.orderStatus.toUpperCase() == "CANCEL"? 
            <>
              <h5>CURRENT STATUS:&nbsp;{item.delieveryStatus.toUpperCase()}</h5>
            </> :
            ""
            }
          </>
        );
      }
    },
    {
      title: "Driver Details",
      key: "driverdetails",
      render: (record, item, index) => {
        return (
          <>
            {item.orderType == "Drive Out" && item?.delieveryStatus != "notConfirmed" ? (
              <>
                <h5>
                  Name:&nbsp;
                  {item?.driverId?.firstName + " " + item?.driverId.lastName}
                </h5>
                <h5>Phone:&nbsp;{item?.driverId?.phoneNumber}</h5>
              </>
            ) : item.orderType == "Take Away"  ?(
              <h5></h5>
            )
            :
            item.orderStatus.toUpperCase() == "CANCEL" ? (
            <>
              <h5></h5>
            </> )
            :
            (
             <h5> "No Driver is Assigned"</h5>
            )}
          </>
        );
      }
    }
  ];
  return (
    <>
      <div className="user-list">
        <Row className="justify-content-between">
          <Col>
          {
            option == "ALLORDERS" ? 
            <h4>{singleUserName} All Orders</h4> :
            <h4>{singleUserName} Orders</h4>
          }
           
          </Col>
          <Col>
            <Button onClick={() => navigate(-1)} className="common-btn2">
              Go Back
            </Button>
          </Col>
        </Row>

        <Row className="py-3 justify-content-between userHistoryPage">
          <Col className="col-4 flex justify-content-between p-0">
            <Button className="flex " onClick={() => onDateSelect(moment(), moment().format())}>
              14 Days Orders History. ( 
              {moment().subtract(13, "days").format("DD/MM/YYYY")}
              &nbsp;to&nbsp;{moment().endOf("day").format("DD/MM/YYYY")})
            </Button>
          </Col>
          <Col className="col-4 p-0 flex justify-content-end">
          <Space direction="vertical">
            <DatePicker
              onChange={(date, dateString) =>
                onDateSelect(date, dateString, "day")
              }
            />
          </Space>
          <Space direction="vertical">
            <DatePicker
              onChange={(date, dateString) =>
                onDateSelect(date, dateString, "month")
              }
              picker="month"
            />
          </Space>
          </Col>
        </Row>
        <Row>
        <Table
            scroll={{ x: true }}
            className="user-table"
            columns={columns}
            dataSource={singleUserOrder.data}
            striped
            bordered
            hover
            pagination={{
              pageSize: 10,
              onChange(current) {
        setPage(current);
      }
            }}
          />
        </Row>
      </div>
    </>
  );
}
