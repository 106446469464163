import { Row, Col, Descriptions, Image, Avatar, Badge } from "antd";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

export default function ViewCity() {
  let location = useLocation();
  console.log("data", location.state);
  let data = location.state;
  return (
    <>
      <div className="add-r-main bg-white pb-2">
        <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
          <Col>
            <h3>City's Data</h3>
          </Col>
          <Col className="col-3 flex justify-content-end">
            <NavLink to="/citylist" className="common-btn">
              Return to the List
            </NavLink>
          </Col>
        </Row>
        <div className="p-5">
          <Row className=" flex flex-column">
            <div className="display-data p-5 flex flex-column  justify-content-center">
              <Descriptions layout="vertical" bordered className="pt-3">
                <Descriptions.Item label="City Name" span={2}>
                  {data.name ? data.name : ""}
                </Descriptions.Item>
                <Descriptions.Item label="Delivery Changes">
                  {data.deliveryCharges ? data.deliveryCharges : ""}
                </Descriptions.Item>
                <Descriptions.Item label="Longitude">
                  {data.longitude ? data.longitude : ""}
                </Descriptions.Item>
                <Descriptions.Item label="Latitude" span={2}>
                  {data.latitude ? data.latitude : ""}
                </Descriptions.Item>
                <Descriptions.Item label="Country">
                  {data.countryCode == "NO" ? "Norway" : ""}
                </Descriptions.Item>
                <Descriptions.Item label="State Code">
                  {data.stateCode ? data.stateCode : ""}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
}
