import { render } from "react-dom";
import React,{useState} from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Modal, Button,Radio,Space, notification  } from "antd";
import { NavLink , useNavigate} from 'react-router-dom';
import api from "../../commonFuncs/api";

const CustomPopupConfirm = (props) => {
  let navigate=useNavigate();
  const [selectedValue, setSelectedValue] = useState("Out of Stock");

  const IncompeleteOrder = async (data) => {
    try {
     console.log("api data", data)
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/confirmTflOrders`,
      data
      );
      props.cancel();
      if(result.status == 200){
        notification.open({
          message: "Order cancelled successfully!",
          duration: 3,
        });
        navigate("/tflOrders");
      }
    } catch (err) {
      console.log("error--->", err.message);
    }
  };

    const handleOk = () => {
        //props.action(props.productId);
       // console.log("delete this data", props.productId);
        let postData = {};
        postData = {   
          orderId: props.productId,
          orderStatus: "CANCEL",
          deliveryWithIn:  "",
          cancelReason: selectedValue ? selectedValue : "Out Of Stock",
        };
     // }
      
      console.log("post data ----", postData);
      IncompeleteOrder(postData);
      };
    
      const handleCancel = () => {
        props.cancel();
        console.log("cancel delete this data",)
      };

      const onChange = (e) => {
        console.log("selcted value ==== > ", e.target.value)
        setSelectedValue(e.target.value);
      };

      const modalFooter = (
        <div className= "modelFooter" style={{display: 'flex', justifyContent: 'right'}}>
          <Button type="primary" className="float-right" onClick={handleOk}>
            OK
          </Button>
        </div>
      );

  return (
    <div className="abcd">
     <Modal
     className="abcd"
      title="Cancel Confirmation"
      visible={props.visible}
      onCancel={handleCancel}
      footer={modalFooter}
      cancelButtonProps={{ style: { display: 'none' } }} // This hides the Cancel button
    >
      {/* <p><bold>Are you sure you want to delete this order?</bold></p> */}
      <p><bold>Select Cancel Reason : </bold></p>
      <Radio.Group  defaultValue="Out of Stock" onChange={onChange} value={selectedValue}  >
      <Space direction="vertical">
      <Radio value="Out of Stock">Out of Stock</Radio>
          <Radio value="Can't Deliver to this Address">Can't Deliver to this Address</Radio>
          <Radio value="Incorrect Pricing or Information">Incorrect Pricing or Information</Radio>
          <Radio value="Not Accepting orders">Not Accepting orders</Radio>
      </Space>
        </Radio.Group>
      {/* <Button className="model-button" onClick={()=> handleOk()}>Ok</Button>
      <Button className="model-button" onClick={()=>handleCancel()}>Cancel</Button> */}
    </Modal>
    </div>
  )
}

export default CustomPopupConfirm
