import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Tag,
  Descriptions,
  Image,
  Avatar,
  Badge,
  Table,
  Button,
  Modal,
  DatePicker,
  Select,
  notification,
  Steps
} from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import coin_pic from "../../../assets/coin_pic.png";
import moment from "moment";

const Single = () => {
  let location = useLocation();
  let navigate = useNavigate();
  let data = location.state.record;
  let coinsVal = location.state.record.pointsConvVal
    ? location.state.record.pointsConvVal
    : 0;
  console.log("coinsVal", coinsVal);

  let dataArr = [];
  dataArr.push(data);

  let totalProductPrice = 0;
  data.items.map((e) => {
    totalProductPrice += e.totalAmount;
  });

  const columns = () => [
    {
      title: "S.No",
      key: "orderNumber",
      width: "5%",
      fixed: "center",
      render: (record, item, index) => {
        return <>{index + 1}</>;
      }
    },
    {
      title: "Product Name",
      key: "Product Name",
      width: "15%",
      render: (text, record, index) => {
        return <span>{record.name}</span>;
      }
    },
    {
      title: "Total Dimension",
      key: "price",
      width: "20%",
      render: (text, record) => {
        return (
          <>
            Total length : {record.totalLength}
            <br />
            Total Breadth : {record.totalBreadth}
            <br />
            Total Height : {record.totalHeight}
          </>
        );
      }
    },
    {
      title: "Total Weight",
      key: "price",
      width: "15%",
      render: (text, record) => `${record.totalWeight / 1000} kg`
    },
    {
      title: "Price",
      key: "price",
      width: "10%",
      render: (text, record) => `Kr. ${record.amount}`
    },
    {
      title: "Quantity",
      key: "Quantity",
      width: "10%",
      render: (text, record) => `${record.quantity}`
    },
    {
      title: "Total Price",
      key: "Total Price",
      width: "20%",
      render: (text, record) => {
        return <span> Kr. {record.totalAmount}</span>;
      }
    }
  ];

  const columns2 = (totalProductPrice, data) => [
    {
      title: "Product Total Price",
      key: "Product Name",
      // width: "15%",
      render: () => `Kr. ${totalProductPrice.toFixed(2)}`
    },
    {
      title: "Delivery Charges",
      key: "price",
      render: (text, record) => `Kr. ${record.deliveryCharges}`
    },
    {
      //title: `Discount ( ${data.coinsUsed ? data.coinsUsed : 0} ${<FaCoins />} used)`,
      title: (
        <span>
          Discount
          <span className="ml-2">{data.coinsUsed ? data.coinsUsed : 0}</span>
          <img
            src={coin_pic}
            style={{ width: "16px", marginTop: "-5px" }}
            className="mr-1 ml-1"
          />
          used
        </span>
      ),
      key: "discount",
      render: (text, record) => `Kr. ${record.discount ? record.discount : 0}`
    },
    {
      title: "Total Price",
      key: "Total Price",
      render: (text, record) => {
        return <span> Kr. {record.totalAmount}</span>;
      }
    }
  ];

  return (
    <div className="add-r-main bg-white pb-2">
      <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
        <Col>
          <h3>
            Invoice Number :{" "}
            {Object.keys(data).length > 0 ? data.invoiceNumber : ""}
          </h3>
        </Col>
        <Col className="flex justify-content-end">
          {/* <NavLink to='/edituserdata' className="common-btn">Edit</NavLink> */}
          <NavLink to="/tflReport" className="common-btn">
            Return to the List
          </NavLink>
        </Col>
      </Row>
      <Row className="m-3">
        <Col className="col-6">
          <span style={{ textAlign: "start", marginRight: "50px" }}>
            <>
              <h4>
                <b>User Information </b>
              </h4>
              <p>
                <span style={{ fontWeight: 700 }}>Name : </span>
                <span style={{ minWidth: "100px", display: "inline-block" }}>
                  {Object.keys(data).length > 0 ? data.customerDetail.name : ""}
                </span>
                <br />
                <span style={{ fontWeight: 700 }}>Email Id : </span>
                <span style={{ minWidth: "100px", display: "inline-block" }}>
                  {Object.keys(data).length > 0
                    ? data.customerDetail.email
                    : ""}
                </span>
                <br />
                <span style={{ fontWeight: 700 }}>Phone Number : </span>
                <span style={{ minWidth: "100px", display: "inline-block" }}>
                  +
                  {Object.keys(data).length > 0
                    ? `${
                        data.customerDetail.countryCode +
                        " " +
                        data.customerDetail.phoneNo
                      }`
                    : ""}
                </span>
                <br />
                <span style={{ fontWeight: 700 }}>Address : </span>
                <span style={{ minWidth: "100px", display: "inline-block" }}>
                  {Object.keys(data).length > 0
                    ? data.customerDetail.address
                    : ""}
                </span>
              </p>
            </>
          </span>
        </Col>
        <Col className="col-6 ">
          <span style={{ textAlign: "end", marginRight: "50px" }}>
            {data.transaction && data.transaction.vendorName == "Vipps" ? (
              <>
                <h4>
                  <b>Paid With Vipps</b>
                </h4>
              </>
            ) : (
              data.transaction.card && (
                <>
                  <h4>
                    <b>Card Details</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 700 }}>Card : </span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {data.transaction.card.masked_pan}
                    </span>
                    <br />
                    <span style={{ fontWeight: 700 }}>Card Type : </span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {data.transaction.card.brand}
                    </span>
                    <br />
                    <span style={{ fontWeight: 700 }}>Expiry Date : </span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {data.transaction.card.expiry_date}
                    </span>
                    <br />
                    {data.transaction.card.approvedCode ? (
                      <>
                        <span style={{ fontWeight: 700 }}>
                          Approved code :{" "}
                        </span>
                        <span
                          style={{ minWidth: "100px", display: "inline-block" }}
                        >
                          {data.transaction.card.approvedCode
                            ? data.transaction.card.approvedCode
                            : ""}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </>
              )
            )}
          </span>
        </Col>
      </Row>

      <Row className="px-5" gutter={24}>
        <Col xs={12}>
          <h4>Product Information : </h4>
        </Col>
        <Col xs={12}>
          <p style={{ textAlign: "end", marginRight: "10px" }}>
            1
            <img
              src={coin_pic}
              style={{ width: "16px", marginTop: "-5px" }}
              className="mr-1 "
            />{" "}
            = Kr. {coinsVal}{" "}
          </p>
        </Col>

        <Table
          scroll={{ x: true }}
          className="user-table2"
          bordered
          dataSource={data.items}
          pagination={false}
          columns={columns()}
        ></Table>
      </Row>
      <Row className=" flex flex-column pl-5 pr-5 mt-3">
        <div className="display-data  flex flex-column  justify-content-center">
          <Table
            scroll={{ x: true }}
            className="user-table2"
            bordered
            dataSource={dataArr}
            pagination={false}
            columns={columns2(totalProductPrice, data)}
          ></Table>
        </div>
      </Row>
    </div>
  );
};

export default Single;
