import {createSlice} from "@reduxjs/toolkit";

const allUsersSlice=createSlice({
    name:"allUsers",
    initialState:[],
    reducers:{
        fetchallUsers:(state,action)=>{
            console.log("action payload in allusers====>",action.payload);
            // state=[...state,action.payload];
            state=action.payload;
            return state;
            // state.push(action.payload)
            // return state;
        }
    }
});

export const {fetchallUsers}=allUsersSlice.actions;
export default allUsersSlice.reducer;