import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {
  Table,
  Space,
  Row,
  Col,
  Avatar,
  Image,
  Rate,
  Tag,
  Input,
  Button,
  Tooltip
} from "antd";

import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import api from "../../commonFuncs/api";
import s3url from "./../../helpers/s3";
import { fetchRestaurants } from "./../../reducers/restuarants/restReducer";
const mockVal = (str, repeat = 1) => ({
  value: str.repeat(repeat)
});

export default function RestaurantListMain(props) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  const restaurants = useSelector((state) => state.restaurantReducer);
  console.log("restaurants reducer=====>", restaurants);
  const navigate = useNavigate();
  useEffect(() => {
    fetchResautants();
  }, []);
  const fetchResautants = async () => {
    try {
      let result = await api.get(
        `/api/v1/treeDriveAdmin/restaurant/restaurantfetch`
      );
      console.log("result in restaurantss========>", result.data.data);
      dispatch(fetchRestaurants(result.data.data));
    } catch (err) {
      console.log("error--->", err.message);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block"
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      )
  });

  const columns = [
    {
      title: "S.No",
      key: "sno",
      fixed: "left",
      render: (record, item, index) => {
        return index + 1;
      }
    },
    {
      title: "Cover Photo",
      key: "coverphoto",
      fixed: "left",
      render: (record, item, index) => {
        return (
          <div className="flex justify-content-evenly align-items-center">
            <img src={`${s3url}/${item.logo}`} style={{ maxWidth: 80 }} />
          </div>
        );
      }
    },
    // {
    //   title: "Restaurant Name",
    //   dataIndex: "storename",
    //   fixed: "left",
    //   key: "storename",
    //   render: (record, item, index) => {
    //     return item.name;
    //   }
    // },
    {
      title: "Restaurant Details",
      key: "address",
      render: (record, item, index) => {
        console.log("item====>", item);
        return (
          <div className="flex justify-content-evenly flex-column">
            <p style={{ fontWeight: 700 }}>{item.name}</p>
            <p>
              {" "}
              {item.address.line1},{item.address.line2},{item.address.city},
              {item.address.state},{item.address.pin}
            </p>
          </div>
        );
      }
    },
    {
      title: "Contact Details",
      key: "address",
      render: (record, item, index) => {
        console.log("item====>", item);
        return (
          <div className="flex justify-content-evenly flex-column">
            <p>{item.email}</p>
            <p>{item.phoneNo}</p>
          </div>
        );
      }
    },
    // {
    //   title: "Restaurant Address",
    //   key: "address",
    //   render: (record, item, index) => {
    //     console.log("item====>", item);
    //     return (
    //       <>
    //         {item.address.line1},{item.address.line2},{item.address.city},
    //         {item.address.state},{item.address.pin}
    //       </>
    //     );
    //   }
    // },
    // {
    //   title: "Email",
    //   key: "email",
    //   render: (record, item, index) => {
    //     return item.email;
    //   }
    // },
    // {
    //   title: "Phone Number",
    //   key: "phone",
    //   render: (record, item, index) => {
    //     return item.phoneNo;
    //   }
    // },

    {
      title: "Action",
      // fixed: "right",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="View" placement="top" color={"blue"}>
            <span>
              <NavLink
                to="/viewrestaurantdata"
                state={{ singleRestaurant: record }}
                className=""
              >
                <EyeOutlined />
              </NavLink>
            </span>
          </Tooltip>
        </Space>
      )
    }
  ];

  return (
    <div className="user-list">
      <Row className="justify-content-between">
        <Col>
          <h4>List of Restaurants</h4>
        </Col>
        {/* <Col>
        <NavLink to='/addrestaurants' className="common-btn">Add New Restaurant</NavLink>
        </Col> */}
      </Row>
      <Row>
        <Table
          className="user-table"
          scroll={{ x: true }}
          columns={columns}
          dataSource={restaurants}
          striped
          bordered
          hover
          pagination={{
            pageSize: 10
          }}
        />
      </Row>
    </div>
  );
}
