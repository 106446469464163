import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Row,
  Col,
  Divider,
  DatePicker,
  Tooltip,
  Select,
  Button
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchOppgjorReports } from "./../../reducers/oppgjor/oppgjorRed";
import api from "./../../commonFuncs/api";
import s3url from "./../../helpers/s3";
import { fetchRestaurants } from "./../../reducers/restuarants/restReducer";
import startEndDay from "./../../commonFuncs/moment/endStart";
import formatDateTwoWeeks from "./../../commonFuncs/moment/endStartTwoWeeks";
export default function Oppgjor() {
  const [option, setOption] = useState("ALL");
  const [optionChange, setOptionChange] = useState(false);
  const [dateChange, setDateChange] = useState(false);
  const [dateSelected, setDateSelected] = useState(moment(new Date()));
  const [startDate, setStartDate] = useState(
    moment(dateSelected.startOf("day").toString()).format()
  );
  const [endDate, setEndDate] = useState(
    moment(dateSelected.endOf("day").toString()).format()
  );
  const dispatch = useDispatch();
  const oppogjorReports = useSelector((state) => state.oppgjorRed);
  const restaurants = useSelector((state) => state.restaurantReducer);
  console.log("oppogjor reports reducer =====>", oppogjorReports);
  useEffect(() => {
    fetchOppgjorReport();
    // fetchResautants();
  }, [dateChange, optionChange]);
  // const fetchResautants=async ()=>{
  //   try{
  //     let result=await api.get(`/api/v1/treeDriveAdmin/restaurant/restaurantfetch`);
  //     console.log("result in restaurantss========>",result.data.data);
  //     dispatch(fetchRestaurants(result.data.data));
  //   }catch(err){
  //     console.log("error--->",err.message);
  //   }
  // }
  const fetchOppgjorReport = async () => {
    try {
      console.log("startDate in oppogjor=====>", startDate);
      console.log("endDate in oppogjor=======>", endDate);
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appoppogjor/oppogjorreports`,
        {
          startDate: startDate,
          endDate: endDate
        }
      );
      console.log("result in oppogjor=====>", result);
      dispatch(fetchOppgjorReports(result.data.data));
    } catch (err) {
      console.log("error--->", err.message);
    }
  };
  const onDateSelect = (date, dateString, dmy = "Bi-Weekly") => {
    let formattedDate;
    if (dmy == "Bi-Weekly") {
      formattedDate = formatDateTwoWeeks(date);
      setOption("ALL");
    } else {
      formattedDate = startEndDay(date, dmy);
    }
    setStartDate(formattedDate.startDate);
    setEndDate(formattedDate.endDate);
    setDateSelected(date);
    setDateChange(!dateChange);
  };
  const { Option } = Select;
  const onOptionsSelect = (e) => {
    setOption(e);
    setOptionChange(!optionChange);
  };
  const columns = [
    {
      title: "S/N",
      key: "Sno",
      width: "2.5%",
      fixed: "left",
      render: (record, item, index) => {
        return <>{index + 1}</>;
      }
    },
    {
      title: "Restaurant",
      key: "restaurantDetail",
      id: "opp-logo",
      // fixed: "left",
      render: (record, item, index) => {
        return (
          <div className="flex justify-content-center align-items-center flex-column text-center">
            <img
              src={`${s3url}/${item.restaurantLogo[0]}`}
              style={{ maxWidth: 70 }}
            />
            {item.restaurantName[0]}
          </div>
        );
      }
      // return <>{item.restaurantLogo[0]}</>
    },
    // {
    //   title: "Restaurant Name",
    //   key: "RestauanrtName",
    //   fixed: "left",
    //   width: "7%",
    //   render: (record, item, index) => {
    //     return <>{item.restaurantName[0]}</>;
    //   }
    // },
    {
      title: "TOTAL",
      children: [
        {
          title: "Orders",
          width: "3.5%",
          key: "totalOrders",
          render: (record, item, index) => {
            return <>{item.totalOrders}</>;
          }
        },
        {
          // title: "Original Price",
          title: "Confirmed TD Sales @ ORC Panel",
          key: "totalOriginalPrice",
          render: (record, item, index) => {
            return (
              <>
                {/* <Tooltip title="(Increased Price - Increased Price)" placement="top" color={"blue"}> */}
                {"Kr. "}
                {item.totalTaxPriceOriginal.toFixed(2)}
                {/* </Tooltip> */}
              </>
            );
          }
        },
        {
          title: "Fees",
          children: [
            {
              title: "To ORC",
              key: "totalFeesOrc",
              render: (record, item, index) => {
                return (
                  <div style={{ color: "#B8B8B8" }}>
                    {"Kr. "}
                    {item.totalFeesToOrcData.toFixed(2)}
                  </div>
                );
              }
            },
            {
              title: "From Restaurant to TD",
              key: "totalFeesTreeDrive",
              render: (record, item, index) => {
                return (
                  <div style={{ color: "#B8B8B8" }}>
                    {"Kr. "}
                    {item.totalFeesToTd.toFixed(2)}
                  </div>
                );
              }
            }
          ]
        },
        {
          title: "Diff b/w Original & Increased Price",
          key: "totalDifferenceBetweenIncAndOriginal",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {item?.tdDiffBetIncOriginal.toFixed(2)}
              </>
            );
          }
        },
        {
          // title: "Increased Price",
          title: "TD Sales @ TD Panel",
          key: "totalIncreasedPrice",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {item.totalTaxPriceInc.toFixed(2)}
              </>
            );
          }
        },
        {
          title: "Service Charge (+)",
          key: "totalServiceCharge",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {item.totalServiceCharge.toFixed(2)}
              </>
            );
          }
        },
        {
          title: "DriveOut Charges (+)",
          key: "totalDriveOutCharge",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {item.totalDriveOutCharge.toFixed(2)}
              </>
            );
          }
        },
        {
          title: "Discount",
          children: [
            {
              title: "Delivery",
              key: "totalDelDiscount",
              render: (record, item, index) => {
                return (
                  <div style={{ color: "#B8B8B8" }}>
                    {"Kr. "}
                    {Number(item.totalDelDiscount).toFixed(2)}
                  </div>
                );
              }
            },
            {
              title: "Pickup",
              key: "totalPickDiscount",
              render: (record, item, index) => {
                return (
                  <div style={{ color: "#B8B8B8" }}>
                    {"Kr. "}
                    {Number(item.totalPickDiscount).toFixed(2)}
                  </div>
                );
              }
            }
          ]
        },
        {
          title: "Discount (-)",
          key: "totalDiscount",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {Number(item.totalDiscount).toFixed(2)}
              </>
            );
          }
        },
        {
          title: "Income",
          children: [
            {
              title: "Before Tax",
              key: "totalIncomeToBeTaxed",
              render: (record, item, index) => {
                return (
                  <>
                    {"Kr. "}
                    {item.totalIncomeToBeTaxed.toFixed(2)}
                  </>
                );
              }
            },
            {
              title: "After Tax",
              key: "totalIncomeAfterTax",
              render: (record, item, index) => {
                return (
                  <>
                    {"Kr. "}
                    {item.totalIncomeAfterTaxed.toFixed(2)}
                  </>
                );
              }
            }
          ]
        },
        {
          title: "Driver Earning",
          key: "totalDriverEarning",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {item.totalDriverEarning.toFixed(2)}
              </>
            );
          }
        },
        {
          title: "Restaurant Earning",
          key: "totalRestaurantEarning",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {item.totalRestaurantEarning.toFixed(2)}
              </>
            );
          }
        },
        {
          title: "Profit",
          key: "totalProfit",
          render: (record, item, index) => {
            return (
              <>
                {"Kr. "}
                {item.totalProfit.toFixed(2)}
              </>
            );
          }
        }
      ]
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "3.5%",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="View" placement="top" color={"blue"}>
            <span>
              <NavLink
                to="/ordersperrestaurant"
                state={{
                  singleRestaurantId: record._id[0],
                  singleRestaurantName: record.restaurantName[0],
                  logo: record.restaurantLogo[0]
                }}
              >
                <EyeOutlined />
              </NavLink>
            </span>
          </Tooltip>
        </Space>
      )
    }
  ];
  const data = [];
  return (
    <>
      <div className="op-table">
        <Row className="justify-content-between">
          <Col xs={24} sm={12}>
            {" "}
            <h4>Oppgjør</h4>{" "}
          </Col>
          <Col xs={24} sm={12} className="flex justify-content-end flexColumn">
            <Button
              className="flex btnHistory justify-content-center align-items-center"
              onClick={() => onDateSelect(moment(), moment().format())}
            >
              14 Days Orders History: &nbsp;
              <div className="flex">
                <p className="mb-0">
                  {" "}
                  ({moment().subtract(13, "days").format("DD/MM/YYYY ")}
                </p>
                <p className="mb-0">
                  {" "}
                  &nbsp;to:{moment().endOf("day").format(" DD/MM/YYYY")})
                </p>
              </div>
            </Button>
            <Space direction="vertical">
              <DatePicker
                onChange={(date, dateString) =>
                  onDateSelect(date, dateString, "day")
                }
              />
            </Space>
            <Space direction="vertical">
              <DatePicker
                onChange={(date, dateString) =>
                  onDateSelect(date, dateString, "month")
                }
                picker="month"
              />
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Table
            scroll={{ x: true }}
            className="user-table"
            columns={columns}
            dataSource={oppogjorReports}
            striped
            bordered
            hover
          />
        </Row>
      </div>
    </>
  );
}
