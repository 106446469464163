import React, { useEffect, useRef, useState } from "react";
import { Input, Table, Button, notification, Row, Icon, Col, Form } from "antd";
import api from "../../commonFuncs/api";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
const { TextArea } = Input;

const EditDelivery = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let data = location.state;
  console.log("data", location.state);
  let [id, setId] = useState(data._id ? data._id : "");
  const [type, setType] = useState(data.type ? data.type : "");
  const [description, setDescription] = useState(
    data.description ? data.description : ""
  );
  const [price, setprice] = useState(data.price ? data.price : 0);
  const [minWeight, setMinWeight] = useState(
    data.weight.min ? data.weight.min / 1000 : 0
  );
  const [maxWeight, setMaxWeight] = useState(
    data.weight.max ? data.weight.max / 1000 : 0
  );
  const [length, setLength] = useState(
    data.dimensions.length ? data.dimensions.length : 0
  );
  const [breadth, setBreadth] = useState(
    data.dimensions.breadth ? data.dimensions.breadth : 0
  );
  const [height, setHeight] = useState(
    data.dimensions.height ? data.dimensions.height : 0
  );
  const [validation, setValidation] = useState(false);
  let [FileError, setFileError] = useState("");
  const [isShowMore, setIsShowMore] = useState(true);

  const toggleReadMore = () => {
    setIsShowMore(!isShowMore);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  // /addTFLProducts
  const addValue2 = async (data) => {
    if (data) {
      const token = localStorage.getItem("token");

      const res = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/updateDeliveryCharges`,
        data,
        {
          headers: {
            "x-auth-token": token
          }
        }
      );
      if (res.status == 200) {
        notification.open({
          message: "Your information saved successfully!",
          duration: 3
        });
        navigate("/postenCharges");
      }
    } else {
      setFileError("**please select valid data.");
    }
  };

  const addValue = () => {
    let data = {};
    let dimensions = {};
    dimensions.length = length ? length : 0;
    dimensions.breadth = breadth ? breadth : 0;
    dimensions.height = height ? height : 0;

    let weight = {};
    weight.min = minWeight ? minWeight * 1000 : 0;
    weight.max = maxWeight ? maxWeight * 1000 : 0;

    data.id = id ? id : "";
    data.type = type ? type : data.type;
    data.weight = weight;
    data.description = description ? description : data.description;
    data.price = price ? price : 0;
    data.dimensions = dimensions;

    addValue2(data);
  };

  return (
    <>
      <div className="user-list">
        <div className="add-r-main bg-white pb-2">
          <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
            <Col>
              <h3>
                Update Delivery
                <br />
                Charges
              </h3>
            </Col>
            <Col>
              <NavLink to="/postenCharges" className="common-btn">
                Return to the List
              </NavLink>
            </Col>
          </Row>

          <div className="form-r-main p-2">
            <Form onFinish={handleSubmit} className="form-horizontal box">
              <Row gutter={24} className="flex">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="type"
                    label="Posten Type"
                    rules={[
                      {
                        required: true,
                        message: "type is required!"
                      }
                    ]}
                  >
                    <Input
                      onChange={(e) => setType(e.target.value)}
                      defaultValue={type}
                      type="Text"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="price"
                    label="Delivery Charges"
                    rules={[
                      {
                        required: true,
                        message: "price is required!"
                      }
                    ]}
                  >
                    <Input
                      onChange={(e) => setprice(e.target.value)}
                      defaultValue={price}
                      type="Number"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="weight"
                    label="Weight(in kg)"
                    rules={[
                      {
                        required: true,
                        message: "Weight is required!"
                      }
                    ]}
                  >
                    <div className="flex">
                      <Input
                        onChange={(e) => setMinWeight(e.target.value)}
                        defaultValue={minWeight}
                        placeholder="minimum weight"
                        type="Number"
                      />
                      <Input
                        onChange={(e) => setMaxWeight(e.target.value)}
                        defaultValue={maxWeight}
                        placeholder="maximum weight"
                        type="Number"
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="dimesions"
                    label="Maximum Size(in cm)"
                    rules={[
                      {
                        required: true,
                        message: "Product Dimensions is required!"
                      }
                    ]}
                  >
                    <div className="flex">
                      <Input
                        onChange={(e) => setLength(e.target.value)}
                        defaultValue={length}
                        placeholder="length"
                        type="Number"
                      />
                      <Input
                        onChange={(e) => setBreadth(e.target.value)}
                        value={breadth}
                        placeholder="breadth"
                        type="Number"
                      />
                      <Input
                        onChange={(e) => setHeight(e.target.value)}
                        defaultValue={height}
                        placeholder="height"
                        type="Number"
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Description is required!"
                      }
                    ]}
                  >
                    <TextArea
                      rows={4}
                      allowClear
                      defaultValue={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row align="center">
              <Col>
                {FileError.length > 0 ? (
                  <p className="errorMsg">{FileError} </p>
                ) : (
                  ""
                )}
                <Button
                  type="primary"
                  className="city-search m-4"
                  onClick={() => addValue()}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDelivery;
