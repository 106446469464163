import {createSlice} from "@reduxjs/toolkit";

const oppgjorSlice=createSlice({
    name:"oppgjor",
    initialState:[],
    reducers:{
        fetchOppgjorReports:(state,action)=>{
            state=action.payload;
            return state;
        }
    }
});

export const {fetchOppgjorReports}=oppgjorSlice.actions;
export default oppgjorSlice.reducer;