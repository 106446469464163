import React from "react";
import { useSelector } from "react-redux";
import {
    MdAutoGraph,
    MdRestaurantMenu
} from 'react-icons/md';

import {
    FaUsers,
    FaCarSide
} from 'react-icons/fa';
import TopCard from './TopCard'
export default function DashCardMapping() {
    const dashBoardData = useSelector((state) => state.dashboardRed);
    const DashCardData = [
        {
            title: "Total Sales",
            text: dashBoardData.totalOrders,
            icon: <MdAutoGraph />
        },
        {
            title: "Total Users",
            text: dashBoardData.totalUsers,
            icon: <FaUsers />
        },
        {
            title: "Total Restaurants",
            text: dashBoardData.totalBranches,
            icon: <MdRestaurantMenu />
        },
        {
            title: "Total Drivers",
            text: dashBoardData.totalDrivers,
            icon: <FaCarSide />
        },
    ]
    return (
        <div className="flex justify-content-between topCardRow">
            {
                DashCardData.map((val, ind) => {
                    return <TopCard
                        title={val.title}
                        text={val.text}
                        icon={val.icon}
                    />
                })
            }
        </div>
    )
}
