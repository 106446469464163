import {Row, Col,Select,Button,Table,DatePicker, Space} from 'antd';
import React,{useState,useEffect} from 'react';
import  moment  from 'moment';
import { useDispatch,useSelector,} from 'react-redux';
import api from '../../commonFuncs/api';
import formatDateTwoWeeks from "./../../commonFuncs/moment/endStartTwoWeeks";
import openNotification from "./../../commonFuncs/notification";
import {fetchDriverOrders} from "./../../reducers/orders/orderPerDriverRed";
import startEndDay from "./../../commonFuncs/moment/endStart";
export default function AllOrders(props) {
    const {handlePageChange,driver}=props;
    console.log("driver data in view order of driver===>",driver)
    const [orderFetched,setOrderFetched]=useState(false);
    const [earningNet,setEarningNet]=useState(0);
    const [dateSelected,setDateSelected]=useState(moment(new Date()));
    const [dateChange,setDateChange]=useState(false);
    const [option,setOption]=useState("all");
    const [optionChange,setOptionChange]=useState(false);
    const [startDate,setStartDate]=useState(moment(dateSelected.startOf("day").toString()).format())
    // let startDate=moment(dateSelected.startOf("day").toString()).format();
    const [endDate,setEndDate]=useState(moment(dateSelected.endOf("day").toString()).format())
    // let endDate=moment(dateSelected.startOf("day").toString()).format()
    let totalEarning=0;
  const dispatch=useDispatch();
  useEffect(()=>{
    fetchOrders(driver._id)
  },[dateChange,optionChange]);
  const driverOrders=useSelector((state)=>state.driverOrder);
  driverOrders.forEach(order=>{
      totalEarning+=order.DriverEarningInThisOrder.earning
  })
  const fetchOrders=async(driverId)=>{
    try{
        let result=await api.post(`/api/v1/treeDriveAdmin/appdriver/driverallorders`,{
            driverId:driverId,
            startDate:startDate,
            endDate:endDate,
            delStatus:option
        });
        if(result.data.status==200){
          openNotification("success","SUCCESS",result.data.message);
          setOrderFetched(true);
          dispatch(fetchDriverOrders(result.data.data.data))
        }else{
          openNotification("error","ERROR",result.data.message);
          setOrderFetched(false);
        }
      }catch(err){
        console.log("error in order driver fethc===>",err.message);
        setOrderFetched(false);
        openNotification("error","ERROR",err.message);
      }
  }

 const deliveredTime=(time)=>{
   let hours=Math.floor(time/60);
   let min=time - hours*60;
   min=Math.ceil(min);
  if(time<0){
     hours=0;
     min=0;
  }
  let formatTime={
    hours:Math.floor(hours),
    minutes:min
  }
  return formatTime;
 }
 const onDateSelect=(date,dateString,dmy="Bi-Weekly")=>{
  let formattedDate;
  if(dmy=="Bi-Weekly"){
     formattedDate=formatDateTwoWeeks(date);
     setOption("all");
  }else{
     formattedDate=startEndDay(date,dmy);
  }
    setStartDate(formattedDate.startDate);
    setEndDate(formattedDate.endDate);
    setDateSelected(date);
    setDateChange(!dateChange);
 }

 const {Option}=Select;
 const onOptionsSelect=(e)=>{
   setOption(e);
   setOptionChange(!optionChange);
 }
  const columns=[
    {
      title: 'Restaurant Name',
      key: 'restName',
      render:(record,item,index)=>{
        return item.branchId.name
      }
    },
    {
      title: 'Order Status',
      key: 'orderStatus',
      render:(record,item,index)=>{
        return (
            <>
            <h5>
            CURRENT STATUS:{item.delieveryStatus.toUpperCase()}
            </h5>
            <h5>CONFIRMED AT:{item.confirmedAt?moment(item.confirmedAt).format("dddd DD/MMMM/YYYY hh:mm A"):"Not Confirmed"}</h5>
            <h5>PICKED AT:{item.pickedAt?moment(item.pickedAt).format("dddd DD/MMMM/YYYY hh:mm A"):"Not Picked"}</h5>
            <h5>DELIEVERED AT:{item.appOrderDeliveryTime?moment(item.appOrderDeliveryTime).format("dddd DD/MMMM/YYYY hh:mm A"):"Not Delievered"}</h5>
            {item.appOrderDeliveryTime?(<h4>TOTAL TIME TAKEN:{deliveredTime(item.delieveredInTime).hours}{" hours "}{deliveredTime(item.delieveredInTime).minutes}{" minutes"}</h4>):""}
            </>
        )}
    },
    {
      title: 'Distance Travelled',
      key: 'distanceTravelled',
      render:(record,item,index)=>{
        return item?.distance.toFixed(2) + " Km"
      }
    },
    {
        title: 'Per Km Fee',
        key: 'distanceTravelled',
        render:(record,item,index)=>{
          return " Kr. " + item?.driverFee?item.driverFee.perKm:"Calculated After Delievery"
        }
    },
    {
        title: 'Per Delivery Fee',
        key: 'distanceTravelled',
        render:(record,item,index)=>{
          return " Kr. " + item?.driverFee?item.driverFee.perDel:"Calculated After Delievery"
        }
    },
    {
        title: 'Earning Per Order',
        key: 'earningperorder',
        render:(record,item,index)=>{
            console.log("totalEarning===>",totalEarning);
          return "Kr. " + item?.DriverEarningInThisOrder?.earning.toFixed(2)
        }
      },
   
  ]
  const footerColumn=[
      {
            title: 'Total Earning',
            key: 'totalEarning',
            render:(record,item,index)=>{
              return "Kr. " + item?.total.toFixed(2)
            }
           
      }
  ]
  const footerData=[
      {
          total:totalEarning
      }
  ]
  return (
    <> 
          <Row className='justify-content-between align-items-center'>
            <Col>
              <h3 className='mb-0'>Driver's All Orders</h3>
            </Col>
            <Col className='col-3 flex justify-content-end'>
            <Button onClick={()=>{
              handlePageChange("VIEW");
            }} className="common-btn2">Return to the List</Button>
            </Col>
          </Row>

            <Row className="justify-content-between py-3">
              <Col>
            <Button className="flex" onClick={()=>onDateSelect(moment(),moment().format())}>14 Days Orders History.({moment().subtract(13,"days").format("DD/MM/YYYY")}<p>to{moment().endOf("day").format(" DD/MM/YYYY")})</p></Button>
            </Col>

            <Col>
            <Space direction="vertical">
   <DatePicker  onChange={(date,dateString)=>onDateSelect(date,dateString,"day")} />
</Space>
<Space direction="vertical">
   <DatePicker  onChange={(date,dateString)=>onDateSelect(date,dateString,"month")} picker="month" />
</Space>
<Select
      defaultValue="all"
      style={{
        width: 120,
      }}
      onChange={onOptionsSelect}
    >
      <Option value="all">ALL</Option>
      <Option value="confirmed">CONFIRMED</Option>
      <Option value="picked">
        PICKED
      </Option>
      <Option value="delievered">DELIVERED</Option>
    </Select>
            </Col>

            </Row>
       
        
        <Table className='user-table'  columns={columns} dataSource={driverOrders} footer={() =><Table  columns={footerColumn} dataSource={footerData}/>} striped bordered hover pagination={{
      pageSize: 4, 
    } }/>
         
    </>
  )
}


