import { Row, Col, Descriptions, Image, Avatar, Badge } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import s3url from "../../helpers/s3";

export default function ViewRestaurantData() {
  let location = useLocation();
  const { singleRestaurant } = location.state;
  console.log("passed data from indexjs====>", singleRestaurant);
  return (
    <div className="add-r-main bg-white pb-2">
      <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
        <Col>
          <h3>{singleRestaurant.name}</h3>
        </Col>
        <Col className="flex justify-content-end">
          <NavLink to="/restaurantlistmain" className="common-btn">
            Return to the List
          </NavLink>
        </Col>
      </Row>
      <Row className="flex flex-column p-5">
        <Avatar
          className="dispaly-img"
          src={
            <Image
              src={`${s3url}/${singleRestaurant.logo}`}
              style={{
                width: 200
              }}
            />
          }
        />

        <Descriptions layout="vertical" bordered className="pt-3">
          <Descriptions.Item label="Restaurant Name">
            {singleRestaurant.name}
          </Descriptions.Item>
          <Descriptions.Item label="Website">
            {singleRestaurant.website}
          </Descriptions.Item>
          <Descriptions.Item label="Taccount E-mail">
            {singleRestaurant.taccountantEmail}
          </Descriptions.Item>
          <Descriptions.Item label="E-mail">
            {singleRestaurant.email}
          </Descriptions.Item>
          <Descriptions.Item label="Address Line 1" span={3}>
            {singleRestaurant?.address?.line1}
          </Descriptions.Item>
          <Descriptions.Item label="Address Line 2" span={3}>
            {singleRestaurant?.address?.line2}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Badge status="processing" text="Active" />
          </Descriptions.Item>
          <Descriptions.Item label="Phone No.">
            {singleRestaurant?.phoneNo}
          </Descriptions.Item>
          <Descriptions.Item label="Tax No.">
            {singleRestaurant.taxNo}
          </Descriptions.Item>
          <Descriptions.Item label="City">
            {singleRestaurant?.address?.city}
          </Descriptions.Item>
          <Descriptions.Item label="State">
            {singleRestaurant?.address?.state}
          </Descriptions.Item>
          <Descriptions.Item label="PIN">
            {singleRestaurant?.address?.pin}
          </Descriptions.Item>
          <Descriptions.Item label="Country">
            {singleRestaurant?.address?.country}
          </Descriptions.Item>
          <Descriptions.Item label="Longitude">
            {singleRestaurant?.address?.location.coordinates[0]}
          </Descriptions.Item>
          <Descriptions.Item label="Latitude">
            {singleRestaurant?.address?.location.coordinates[1]}
          </Descriptions.Item>
          <Descriptions.Item label="Td Drive-out Charges Flat">
            {"Kr."}
            {singleRestaurant?.tdFlatRateDriveOut}
          </Descriptions.Item>
          <Descriptions.Item label="Td Drive-out Charges Extra">
            {"Kr."}
            {singleRestaurant?.tdExtraRateDriveOut}
          </Descriptions.Item>
          <Descriptions.Item label="Td Drive-out Distance Flat">
            {singleRestaurant?.tdFlatDriveOutDistance}
            {" Km."}
          </Descriptions.Item>
          <Descriptions.Item label="Tree Drive Extra Price Increase (%)">
            {singleRestaurant?.tdExtraPriceIncrease}
            {" %"}
          </Descriptions.Item>
          <Descriptions.Item label="Td Distance Allowed">
            {singleRestaurant?.tdDistance}
            {" Km."}
          </Descriptions.Item>
          <Descriptions.Item label="Tree Drive Service Charge">
            {"Kr."}
            {singleRestaurant?.tdServiceCharge}
          </Descriptions.Item>
          <Descriptions.Item label="Account Number">
            {singleRestaurant.accountNumber}
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Row>
          <NavLink
            to="/ordersperrestaurant"
            state={{
              singleRestaurantId: singleRestaurant._id,
              singleRestaurantName: singleRestaurant.name,
              logo: singleRestaurant.logo
            }}
            className="common-btn mx-5"
          >
            Order History
          </NavLink>
      </Row>
    </div>
  );
}
