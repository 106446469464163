import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import api from "../../commonFuncs/api";
import {
  Table,
  Space,
  Row,
  Col,
  Rate,
  Tag,
  Card,
  Input,
  Button,
  Tooltip,
  notification,
  Icon,
  Form,
  InputNumber,
  Popconfirm,
  Typography
} from "antd";
import CustomPopupConfirm from "./CustomPopupConfirm";
import "./postenCharges.css";

const PostCharges = () => {
  let [allData, setAllData] = useState([]);
  const [addId, setAddId] = useState();
  const [isPopup, setPopup] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  const showConformationDialog = (id) => {
    setAddId(id);
    setPopup(true);
  };

  const cancelConformationDialog = () => {
    setPopup(false);
  };

  const getList = async () => {
    try {
      let result = await api.get(
        `/api/v1/treeDriveAdmin/appsettings/DeliveryCharges`
      );
      if (result) {
        //console.log("data === ", result)

        setAllData(result.data.data);
      }
    } catch (error) {
      console.log("something went wrong");
    }
  };

  // ======================= delete api =============================== //

  const deleteApi = async () => {
    console.log("id == ", addId);
    try {
      let res = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/deleteDeliveryCharges`,
        { id: addId }
      );

      if (res.status == 200) {
        setPopup(false);
        notification.open({
          message: "Your data deleted successfully!",
          //icon: <Icon type="check-circle" style={{ color: "green" }} />,
          duration: 3
        });
        getList();
      }
    } catch (error) {
      console.log("something went wrong");
      setPopup(false);
    }
  };

  return (
    <>
      <div className="user-list">
        <Row className="justify-content-between py-2 align-items-center">
          <Col>
            <h4>
              List of Delivery <br />
              Charges
            </h4>
          </Col>
          <Col>
            <NavLink to="/addDeliveryCharges" className="common-btn">
              Add Delivery Charges
            </NavLink>
          </Col>
        </Row>

        <Row gutter={24}>
          {allData.map((e) => {
            return (
              <Col xs={24} sm={24} md={8} lg={8} xl={8} className="mb-4 deliveryListCard">
                <Card
                  className="postTitle"
                  title={`Parcel ${e.weight.min / 1000} - ${
                    e.weight.max / 1000
                  }kg`}
                  bordered={false}
                  actions={[
                    <NavLink
                      style={{ color: "green" }}
                      to="/editDeliveryCharges"
                      state={e}
                    >
                      <EditOutlined key="edit" />
                    </NavLink>,
                    <DeleteOutlined
                      style={{ color: "red" }}
                      onClick={() => showConformationDialog(e._id)}
                    />
                  ]}
                >
                  <p className="postDescription2" style={{ margin: "10px" }}>
                    From {`${e.price}`}.– kroner
                  </p>
                  <span className="postDescription" style={{ margin: "10px" }}>
                    {`${e.type} `}
                  </span>
                  <p className="postDescription" style={{ margin: "10px" }}>
                    Maximum Size ={" "}
                    {`${e.dimensions.length} x ${e.dimensions.breadth} x ${e.dimensions.height} cm`}
                  </p>
                  <p
                    className="postDescription"
                    style={{ margin: "10px", minHeight: "160px" }}
                  >
                    {e.description}
                  </p>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
      <CustomPopupConfirm
        visible={isPopup}
        cancel={cancelConformationDialog}
        productId={addId}
        action={deleteApi}
      />
    </>
  );
};

export default PostCharges;
