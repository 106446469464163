import React, { useEffect, useState } from "react";
import { Input, Table, Button, notification, Row, Icon, Col, Form } from "antd";
import "./TFLPoints.css";
import api from "../../commonFuncs/api";
import { InputNumber } from "antd";
import { GiTwoCoins } from "react-icons/gi";
import { IoMdCash } from "react-icons/io";
import { Checkbox } from "antd";
const { TextArea } = Input;

const AddTFLPoints = (props) => {
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [requiredPoints, setRequiredPoints] = useState("");
  const [amount, setAmount] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");
  const [availableQuantity, setavailableQuantity] = useState("");
  const [validation, setValidation] = useState(false);
  let [FileError, setFileError] = useState("");
  let [FileError2, setFileError2] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleLogoChange = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      //let errorsObj = { ...errors };
      let imageFile = e.target.files[0];
      let fileName = imageFile.name.toLowerCase();
      if (!fileName.match(/\.(jpg|jpeg|png|svg|gif|mp4)$/)) {
        setFileError("Please select valid image.");
        setValidation(false);
      } else {
        setValidation(true);
        setFileError("");
      }
      // let maxFileSize = 1024 * 10; // 10MB
      if (imageFile.size / 1024 / 1024 > 10) {
        // errorsObj.file.show = true;
        // errorsObj.file.message = "Image must smaller than 10MB!";
        //setErrors(errorsObj);
        notification.open({
          message: "Image must smaller than 10MB!",
          //icon: <Icon type="close-circle" style={{ color: "green" }} />,
          duration: 2
        });
        console.log("file size is tooo large");
        setValidation(false);
      }
      setImage(imageFile);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        // errorsObj.file.show = false;
        // errorsObj.file.message = "";
        // setIcon(reader.result);
      });
      reader.readAsDataURL(imageFile);
    }
    //submitAddvertisement(e);
  };

  // /addTFLProducts
  const addValue2 = async (data) => {
    if (validation) {
      console.log("abc");
      const formData = new FormData();
      formData.append("image", image, image.name);
      formData.append("productName", productName);
      formData.append("description", description);
      formData.append("RequiredPts", requiredPoints);
      formData.append("amount", amount);
      formData.append("purchasePrice", purchasePrice);
      formData.append("availableQuantity", availableQuantity);
      formData.append("weight", weight);
      formData.append("dimensions", JSON.stringify(data));
      const token = localStorage.getItem("token");

      const res = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/addTFLProducts`,
        formData,
        {
          headers: {
            "x-auth-token": token
          }
        }
      );
      if (res.status == 200) {
        notification.open({
          message: "Your product saved successfully!",
          duration: 3
        });
        props.indexPage();
      }
    } else {
      setFileError("**please select valid data.");
    }
  };

  const addValue = () => {
    let data = {};
    data.length = length;
    data.breadth = breadth;
    data.height = height;

    addValue2(data);
  };

  return (
    <div className="add-r-main bg-white pb-2">
      <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
        <Col>
          <h3>{props.edit ? "Edit Product" : "Add New Product"}</h3>
        </Col>
        <Col>
          <Button onClick={() => props.indexPage()} className="common-btn2">
            Return to the List
          </Button>
        </Col>
      </Row>

      <Form onFinish={handleSubmit} className="form-horizontal box p-5">
        <Row gutter={24} className="flex">
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            {" "}
            <Form.Item
              name="productName"
              label="Product Name"
              rules={[
                {
                  required: true,
                  message: "productName is required!"
                }
              ]}
            >
              <Input
                onChange={(e) => setProductName(e.target.value)}
                value={productName}
                placeholder="Name"
                type="Text"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            {" "}
            <Form.Item
              name="requiredPoints"
              label="Required Points"
              rules={[
                {
                  required: true,
                  message: "add Points !"
                }
              ]}
            >
              <Input
                onChange={(e) => setRequiredPoints(e.target.value)}
                value={requiredPoints}
                placeholder="Required Points"
                type="Number"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="image"
              label="Select Image"
              rules={[
                {
                  required: true,
                  message: "Image is required!"
                }
              ]}
            >
              <Input
                type="file"
                name="icon"
                accept="image/*"
                onChange={handleLogoChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="availableQuantity"
              label="Available Quantity"
              rules={[
                {
                  required: true,
                  message: "Available Quantity is required!"
                }
              ]}
            >
              <Input
                onChange={(e) => setavailableQuantity(e.target.value)}
                value={availableQuantity}
                placeholder="Available Quantity"
                type="Number"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            {" "}
            <Form.Item
              name="purchasePrice"
              label="Purchase Price"
              rules={[
                {
                  required: true,
                  message: "Purchase Price is required!"
                }
              ]}
            >
              <Input
                onChange={(e) => setPurchasePrice(e.target.value)}
                value={purchasePrice}
                placeholder="Amount"
                type="Number"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            {" "}
            <Form.Item
              name="amount"
              label="Sale Price"
              rules={[
                {
                  required: true,
                  message: "Amount is required!"
                }
              ]}
            >
              <Input
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                placeholder="Amount"
                type="Number"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            {" "}
            <Form.Item
              name="weight"
              label="Product Weight (in gm)"
              rules={[
                {
                  required: true,
                  message: "Product Weight is required!"
                }
              ]}
            >
              <Input
                onChange={(e) => setWeight(e.target.value)}
                value={weight}
                placeholder="weight"
                type="Number"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            {" "}
            <Form.Item
              name="dimesions"
              label="Product Dimensions(in cm)"
              rules={[
                {
                  required: true,
                  message: "Product Dimensions is required!"
                }
              ]}
            >
              <div className="flex">
                {" "}
                <Input
                  onChange={(e) => setLength(e.target.value)}
                  value={length}
                  placeholder="length"
                  type="Number"
                />
                <Input
                  onChange={(e) => setBreadth(e.target.value)}
                  value={breadth}
                  placeholder="breadth"
                  type="Number"
                />
                <Input
                  onChange={(e) => setHeight(e.target.value)}
                  value={height}
                  placeholder="height"
                  type="Number"
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Description is required!"
                }
              ]}
            >
              <TextArea
                rows={2}
                placeholder="Description"
                allowClear
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className="flex justify-content-center"
          >
            <Button
              type="primary"
              className="city-search m-4"
              onClick={() => addValue()}
            >
              ADD
            </Button>
            {FileError.length > 0 ? (
              <p className="errorMsg">{FileError} </p>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddTFLPoints;
