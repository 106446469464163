import React, { useEffect, useRef, useState } from "react";
import api from "../../commonFuncs/api";
import AddTFLPoints from "./AddTFLPoints";
import s3url from "../../helpers/s3";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  LeftCircleOutlined
} from "@ant-design/icons";
import CustomPopupConfirm from "./CustomPopupConfirm";
import {
  Table,
  Space,
  Row,
  Col,
  Rate,
  Tag,
  Input,
  Button,
  Tooltip,
  notification,
  Icon,
  Avatar,
  Image,
  Form,
  InputNumber,
  Popconfirm,
  Typography
} from "antd";
import EditTFLProd from "./EditTFLProd";
import { NavLink } from "react-router-dom";

const TFLPoints = () => {
  const [form] = Form.useForm();
  const [pageStatus, setPageStatus] = useState("LIST");
  let [allData, setAllData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [addId, setAddId] = useState();
  const [Id, setId] = useState("");
  const [isPopup, setPopup] = useState(false);
  const [imageEdit, setImageEdit] = useState(false);
  const [image, setImage] = useState("");
  const [validation, setValidation] = useState(false);
  let [FileError, setFileError] = useState("");
  const [singleTFLData, setSingleTFLData] = useState({});
  const isEditing = (record) => record.key === editingKey;

  // ============ get api for print all the points ============== //
  const indexPage = () => {
    setPageStatus("LIST");
    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  //-------------------- new key added (for row wise editing  ) --------------------- //
  console.log("data === ", allData);
  allData = allData.map((obj, index) => {
    obj["key"] = index.toString();
    return obj;
  });

  const getList = async () => {
    try {
      let result = await api.get(
        `/api/v1/treeDriveAdmin/appsettings/getTFLProducts`
      );
      if (result) {
        //console.log("data === ", result)

        setAllData(result.data.data);
      }
    } catch (error) {
      console.log("something went wrong");
    }
  };

  // ============= model open when click on delete btn =================== //

  const showConformationDialog = (id) => {
    setAddId(id);
    setPopup(true);
  };
  const cancelConformationDialog = () => {
    setPopup(false);
  };

  // ======================= delete api =============================== //

  const deleteApi = async () => {
    console.log("id == ", addId);
    try {
      let res = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/deleteTFLProducts`,
        { _id: addId }
      );

      if (res.status == 200) {
        setPopup(false);
        notification.open({
          message: "Your data  deleted successfully!",
          //icon: <Icon type="check-circle" style={{ color: "green" }} />,
          duration: 2
        });
        getList();
      }
    } catch (error) {
      console.log("something went wrong");
      setPopup(false);
    }
  };

  const columns = [
    {
      title: "Sr No",
      fixed: "left",
      key: "SNo",
      width: "8%",
      render: (record, item, index) => {
        return Number(record.key) + 1;
      }
    },
    {
      title: "Product Name",
      fixed: "left",
      key: "productName",
      width: "20%",
      dataIndex: "productName",
      render: (t, r) => {
        return (
          <>
            {/* <img style={{maxWidth: 90}} src={`${s3url}/${r.url}`} /> */}
            <p className="pt-2">{r.productName}</p>
          </>
        );
      }
    },
    {
      title: "Image",
      dataIndex: "url",
      key: "image",
      width: "10%",
      render: (t, r) => {
        return (
          <Avatar
            shape="square"
            size={64}
            src={<Image src={`${s3url}/${r.url}`} style={{ maxWidth: 300 }} />}
          />
        );
      }
    },
    // {
    //   title: "Required Coins",
    //   key: "requiredPoints",
    //   width: "10%",
    //   dataIndex: "RequiredPts",
    // },
    {
      title: "AvailQuantity",
      key: "availQuantity",
      width: "10%",
      dataIndex: "availableQuantity"
    },
    {
      title: "Weight (in gm)",
      key: "weight",
      width: "10%",
      dataIndex: "weight"
    },
    {
      title: "Purchase Price",
      key: "amount",
      width: "10%",
      //  editable: true,
      dataIndex: "purchasePrice",
      render: (record, item, index) => {
        return `Kr. ${item.purchasePrice}`;
      }
    },
    {
      title: "Sales Price",
      key: "amount",
      width: "10%",
      //  editable: true,
      dataIndex: "amount",
      render: (record, item, index) => {
        return `Kr. ${item.amount}`;
      }
    },
    {
      title: "Dimensions (in cm)",
      key: "description",
      width: "10%",
      render: (record, item, index) => {
        return (
          <>
            <p>{`L : ${item.dimensions.length} `}</p>
            <p>{`B : ${item.dimensions.breadth} `}</p>
            <p>{`H : ${item.dimensions.height} `}</p>
          </>
        );
      }
    },
    // {
    //   title: "Description",
    //   key: "description",
    //   width: "25%",
    //   dataIndex: "description",
    // },
    {
      title: "Action",
      dataIndex: "action",
      width: "15%",
      render: (_, record) => (
        <>
          <Space size="middle">
            <Tooltip title="View" placement="left" color={"blue"}>
              <span>
                <NavLink to="/viewTFL" state={record}>
                  <EyeOutlined />
                </NavLink>
              </span>
            </Tooltip>
            <Tooltip title="Edit" placement="top" color={"green"}>
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => {
                  setSingleTFLData((prevState) => ({ ...record }));
                  setPageStatus("EDIT");
                }}
              >
                <EditOutlined />
              </Typography.Link>
            </Tooltip>
            <Tooltip title="Delete" placement="bottom" color={"red"}>
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => showConformationDialog(record._id)}
              >
                <DeleteOutlined />
              </Typography.Link>
            </Tooltip>
          </Space>
        </>
      )
    }
    // {
    //   title: "Action",
    //   fixed: "right",
    //   key: "action",
    // //  width: "10%",
    //   render: (_, record) => (
    //     <Typography.Link
    //         disabled={editingKey !== ""}
    //         onClick={() => showConformationDialog(record._id)}
    //       >
    //         Delete
    //       </Typography.Link>
    //   )
    // ,
    //     width: "8%"
    // },
  ];

  const addPage = () => {
    return (
      <>
        <div className="user-list">
          <AddTFLPoints indexPage={indexPage} />
        </div>
      </>
    );
  };

  const editPage = () => {
    return (
      <div className="user-list">
        <EditTFLProd indexPage={indexPage} edit={true} data={singleTFLData} />
      </div>
    );
  };

  const listPage = () => {
    return (
      <div className="user-list">
        <Row className="justify-content-between py-2">
          <Col>
            <h4>List of TFL Products</h4>
          </Col>
          <Col>
            <Button
              className="common-btn2"
              onClick={() => {
                setPageStatus("ADD");
              }}
            >
              Add New TFL Products
            </Button>
          </Col>
        </Row>
        <Row>
          <Table
            scroll={{ x: true }}
            className="user-table"
            columns={columns}
            dataSource={allData}
            striped
            bordered
            hover
            pagination={{
              pageSize: 10
            }}
          />
        </Row>
      </div>
    );
  };

  return (
    <>
      {pageStatus === "LIST"
        ? listPage()
        : pageStatus === "EDIT"
        ? editPage()
        : addPage()}
      <CustomPopupConfirm
        visible={isPopup}
        cancel={cancelConformationDialog}
        productId={addId}
        action={deleteApi}
      />
    </>
  );
};

export default TFLPoints;
