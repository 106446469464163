import { Table,Space} from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {Row, Col, Divider, AutoComplete, Avatar, Image } from 'antd';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const mockVal = (str, repeat = 1) => ({
  value: str.repeat(repeat),
});
export default function ListAdminIcon(props) {
    const [value, setValue] = useState('');

    const [options, setOptions] = useState([]);

    const onSearch = (searchText) => {
      setOptions(
        !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)],
      );
    };
  
    const onSelect = (data) => {
      console.log('onSelect', data);
    };
  
    const onChange = (data) => {
      setValue(data);
    };


    const columns = [
        {
          title: 'S.No',
          dataIndex: 'sno',
          key: 'sno',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
            title: 'Icon',
            dataIndex: 'icon',
            key: 'icon',
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a><EditOutlined /></a>
              <a><EyeOutlined /></a>
              <a><DeleteOutlined /></a>
            </Space>
          ),
        },
      ];
      const data = [
        {
          sno: '1',
          name: 'Mike',
          icon: <Avatar
          src={
            <Image
              src="https://joeschmoe.io/api/v1/random"
              style={{
                width: 32,
              }}
            />
          }
        />,
          action: 'Edit View Delete',
        }
      ];
      return (
        <>
        <div className='user-list'>
        <Row className='justify-content-between'>
        <Col>
        <h4>List of Icons</h4>
        </Col>
        <Col>
        <NavLink to='/addadminicon' className="common-btn">Add Icon</NavLink>
        </Col>
        </Row>
        <Row justify='space-between'>
        <Col>
        {/* <Input placeholder="Basic usage" /> */}
        <AutoComplete className='input-box'
        value={value}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={onChange}
        placeholder="Search here"
      />
        </Col>
        </Row>
        <Divider/>
        <Row>
        <Table className='user-table' columns={columns} dataSource={data} />
        </Row>
        </div>
        </>
      );
    };

