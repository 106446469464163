import {
  Row,
  Col,
  Descriptions,
  Image,
  Avatar,
  Badge,
  Button,
  Table
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import PayModal from "./Paymodal";
import api from "../../commonFuncs/api";
import openNotification from "./../../commonFuncs/notification";
export default function ViewDriver(props) {
  const { driver, handlePageChange, changePayDriverStatus } = props;
  let driverss = useSelector((state) => state.drivers);
  let singleDriver = driverss.find((driv) => {
    return driv._id == driver._id;
  });
  const [modalVisible, setModalVisible] = useState(false);
  const onPayButtonClick = () => {
    setModalVisible(true);
  };
  const onModalClose = () => {
    setModalVisible(false);
  };
  const onPayOk = async (amount) => {
    try {
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appdriver/paydriver`,
        {
          driverId: driver._id,
          amountToBePaid: amount
        }
      );
      if (result.data.status == 200) {
        openNotification("success", "SUCCESS", result.data.message);
        props.changePayDriverStatus();
        setModalVisible(false);
      } else {
        openNotification("error", "ERROR", result.data.message);
      }
    } catch (err) {
      openNotification("error", "ERROR", err.message);
    }
  };
  let drivers = [];
  drivers.push(singleDriver);
  const coloumns = [
    {
      title: "Total Distance Covered (Km)",
      key: "totalDistance2",
      render: (record, item, index) => {
        return item.totalDistance.toFixed(2);
      }
    },
    {
      title: "Total Order Delieverd",
      key: "totalDell",
      render: (record, item, index) => {
        return item.totalOrderDelivered;
      }
    },
    {
      title: "Fee Per Delivery",
      key: "FeesPerDel",
      render: (record, item, index) => {
        return "Kr. " + item?.driverFee?.perDel;
      }
    },
    {
      title: "Fee Per Km",
      key: "feePerKm",
      render: (record, item, index) => {
        return "Kr. " + item.driverFee?.perKm;
      }
    },
    {
      title: "Total Earning",
      key: "totalEarnn",
      render: (record, item, index) => {
        return "Kr. " + item?.earning?.totalEarning.toFixed(2);
      }
    },
    {
      title: "Pending Earning",
      key: "pendingg",
      render: (record, item, index) => {
        return "Kr. " + item?.earning?.pendingEarning.toFixed(2);
      }
    },
    {
      title: "Payment Button",
      key: "paymentButton",
      render: (record, item, index) => {
        return (
          <Button className="common-btn-color" onClick={onPayButtonClick}>
            Pay
          </Button>
        );
      }
    },
    {
      title: "Payment History",
      key: "paymentButtonHistory",
      render: (record, item, index) => {
        return (
          <Button
            className="common-btn-color"
            onClick={() => handlePageChange("PAYMENTHISTORY")}
          >
            View
          </Button>
        );
      }
    },
    {
      title: "All Orders",
      key: "allorders",
      render: (record, item, index) => {
        return (
          <Button
            className="common-btn-color"
            onClick={() => handlePageChange("ALLORDERS")}
          >
            View
          </Button>
        );
      }
    }
  ];
  return (
    <>
      <PayModal
        driver={singleDriver}
        visible={modalVisible}
        onCancel={onModalClose}
        onSubmit={onPayOk}
      />
      <div className="add-r-main bg-white pb-2">
        <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
          <Col>
            <h3>Driver's Data</h3>
          </Col>
          <Col className="flex justify-content-end">
            <Button
              onClick={() => {
                handlePageChange("LIST");
              }}
              className="common-btn2"
            >
              Return to the List
            </Button>
          </Col>
        </Row>
          <Row className=" flex flex-column p-5">
              <Table
                className="user-table"
                columns={coloumns}
                dataSource={drivers}
                striped
                bordered
                hover
                pagination={{
                  pageSize: 10
                }}
              />

              <Descriptions layout="vertical" bordered className="pt-3">
                <Descriptions.Item label="First Name">
                  {driver.firstName}
                </Descriptions.Item>
                <Descriptions.Item label="Last Name">
                  {driver.lastName}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Gender">Female</Descriptions.Item> */}
                <Descriptions.Item label="E-mail">
                  {driver.email}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Address" span={3}>
                      No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Badge status="processing" text="Active" />
          </Descriptions.Item> */}
                <Descriptions.Item label="Phone No.">
                  {driver.phoneNumber}
                </Descriptions.Item>
                <Descriptions.Item label="City">
                  {driver.address?.city ? driver.address.city : ""}
                </Descriptions.Item>
                <Descriptions.Item label="State">
                  {driver.address?.state ? driver.address.state : ""}
                </Descriptions.Item>
                <Descriptions.Item label="PIN">
                  {driver.address?.pinCode ? driver.address.pinCode : ""}
                </Descriptions.Item>
                <Descriptions.Item label="Country">
                  {driver?.address?.country ? driver.address.country : ""}
                </Descriptions.Item>
                <Descriptions.Item label="Vehicle No">
                  {driver.vehicle?.vehicleRegNumber
                    ? driver.vehicle.vehicleRegNumber
                    : ""}
                </Descriptions.Item>
                <Descriptions.Item label="Vehicle Type">
                  {driver.vehicle?.vehicleType
                    ? driver.vehicle.vehicleType
                    : ""}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Vehicle Registration No">{driver.registrationNumber?driver.registrationNumber:""}</Descriptions.Item> */}
                <Descriptions.Item label="Licence No">
                  {driver.licenseNumber ? driver.licenseNumber : ""}
                </Descriptions.Item>
                <Descriptions.Item label="Vehicle Color">
                  {driver.vehicle?.vehicleColor
                    ? driver.vehicle.vehicleColor
                    : ""}
                </Descriptions.Item>
              </Descriptions>
          </Row>
      </div>
    </>
  );
}
