import {Table,Space,Row, Col, Divider,DatePicker,Empty,Button,Card} from 'antd';
import React ,{useEffect,useState} from "react";
import { useDispatch,useSelector } from 'react-redux';
import { NavLink ,Link} from 'react-router-dom';
import api from "./../../commonFuncs/api";
import s3url from '../../helpers/s3';
import pdfImage from "./../../assets/pdfImage.png";
import {fetchRestReports} from "../../reducers/reports/restaurantReport/restReportRed"
import openNotification from "./../../commonFuncs/notification";


const { RangePicker } = DatePicker;



const onChange = (value, dateString) => {
//   console.log('Selected Time: ', value);
//   console.log('Formatted Selected Time: ', dateString);
};

const onOk = (value) => {
  console.log('onOk: ', value);
};




export default function AllReports() {
  const [pathAbs,setPathAbs]=useState("")
  console.log("inside the reports======>")
  useEffect(()=>{
    fetchResReports();
  },[]);
  const getSignedUrl=async (pdfLink)=>{
    try{
      let result=await api.post(`/api/v1/treeDriveAdmin/reports/resreports/reportsingledownload`,{
        pdfLink:pdfLink,
      })
      // setPathAbs(result.data.data);
      // console.log("pathAbs value is======>",pathAbs);
      window.open(result.data.data, "_blank");
      console.log("inside getSigned Url result=====>",result.data.data);
    }catch(err){
      console.log("error in getSigned====>",err.message);
    }
  }
//   <img
//   src={`${s3url}/${item.logo}`}
//   style={{ maxWidth: 80, maxHeight: 100 }}
// />
  const dispatch=useDispatch();
  const restReports=useSelector((state)=>state.restReports);
  const fetchResReports=async (branchId)=>{
    try{
      let result=await api.get(`/api/v1/treeDriveAdmin/reports/resreports/reportfetch`);
      console.log("result in report reducer===>",result);
      dispatch(fetchRestReports(result.data.data));
    }catch(err){
      console.log("error--->",err.message);
    }
  }
  const renderPdf=restReports.map((rest)=>{
    return (
      <>
     
          <span  onClick={()=>{
        getSignedUrl(rest.pdf)
      }} >
        <img src={pdfImage} />DownLoad Pdf
      </span>
      
      
      </>
    )
  })
  return(
    <>
        <div className='op-table'>
            <Row className='justify-content-between'>
            <Col>
            <h4>Reports</h4>
            </Col>
            <Col>
            <Space direction="vertical" size={12}>
                <DatePicker onChange={onChange} onOk={onOk} />
            </Space>
            </Col>
            </Row>
            <Divider/>
            <Row className='bg-white flex justify-content-center'>
            {/* <Empty /> */}
            </Row> 
            {renderPdf}             
        </div>
    </>
  );
};