import {createSlice} from "@reduxjs/toolkit";

const orderPerRestaurantSlice=createSlice({
    name:"orderPerRestaurant",
    initialState:[],
    reducers:{
        fetchOrdersPerRestaurant:(state,action)=>{
            state=action.payload;
            return state;
        }
    }
});

export const {fetchOrdersPerRestaurant}=orderPerRestaurantSlice.actions;
export default orderPerRestaurantSlice.reducer;