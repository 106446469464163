import {
    UploadOutlined
  } from '@ant-design/icons';
  import {Row, Col, Divider, Input, Button,Upload, message} from 'antd';
  import React from 'react';
  import { NavLink } from 'react-router-dom';

  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === 'image/png';
  
      if (!isPNG) {
        message.error(`${file.name} is not a png file`);
      }
  
      return isPNG || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
    },
  }; 


  export default function AddAdminIcon() {
        return (
          <>
            <div className='add-r-main bg-white pb-2'>
              <Row className='justify-content-between align-items-center add-r-head px-4 py-3'>
                <Col>
                  <h3>Add Icon</h3>
                </Col>
                <Col>
                <NavLink to='/listadminiconview' className="common-btn">Return to the List</NavLink>
                </Col>
              </Row>
              <Divider></Divider>
              <div className='form-r-main p-3'>
              <Row>
                <Input placeholder="Add Icon Name" />
              </Row>
              <Divider/>
              <Row>
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>Upload png only</Button>
                </Upload>
              </Row>
              <Divider/>
              <Row>
              <Button className='common-btn save-btn'>Save</Button>
              </Row>
              </div>
            </div>
          </>
        );
      };
  
  