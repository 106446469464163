import { Button, Modal,Form,Input } from 'antd';
import React, { useState } from 'react';

const PayModal = (props) => {
  const [amount,setAmount]=useState(0);
  const [amountError,setAmountError]=useState(false);
  const [errMessage,setErrMessage]=useState("")
  const handleOk = () => {
    console.log("inside handleOk in modal======>",props.driver);
    if(props.driver?.earning?.pendingEarning<amount){
      setAmountError(true);
      console.log("amount error=====>",amountError);
      setErrMessage("Please Enter amount less than pending amount");
      return;
    }
    else if(amount<0){
      setAmountError(true);
      setErrMessage("Please Enter a Non-Negative Amount");
      return;
    }
    else{
      setAmountError(false);
      setErrMessage("");
      props.onSubmit(amount);
      setAmount(0);
      console.log("after on submit====>",amount);
    }
    
  };

  const handleCancel = () => {
    console.log("inside cancel----->")
    props.onCancel()
  };
  console.log("amount=====>",amount)
  return (
    <>
      <Modal className="payment-modal" title="Payment Modal" visible={props.visible} onOk={handleOk} onCancel={handleCancel}>
      <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
    >
      <Form.Item
        label="Amount"
        name="amount"
      
      >
        <Input value={amount} onChange={(e)=>setAmount(e.target.value)} />
        {
          amountError?(<p style={{color:"red"}}>{errMessage}</p>):""
        }
      </Form.Item>
    </Form>
      </Modal>
    </>
  );
};

export default PayModal;