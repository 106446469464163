import {createSlice} from "@reduxjs/toolkit";

const restaurantsSlice=createSlice({
    name:"restaurantFetch",
    initialState:[],
    reducers:{
        fetchRestaurants:(state,action)=>{
            state=action.payload;
            return state;
        }
    }
});

export const {fetchRestaurants}=restaurantsSlice.actions;
export default restaurantsSlice.reducer;