import React, { useEffect, useState } from "react";
import { Input, Table, Button, notification, Row, Icon, Col, Form } from "antd";
import "./TFLPoints.css";
import api from "../../commonFuncs/api";
import { InputNumber } from "antd";
import { GiTwoCoins } from "react-icons/gi";
import { IoMdCash } from "react-icons/io";
import { Checkbox } from "antd";
const { TextArea } = Input;

const EditTFLProd = (props) => {
  const tflData = props.data;
  let [Id, setId] = useState(tflData._id ? tflData._id : "");
  let [productName, setProductName] = useState(
    tflData.productName ? tflData.productName : ""
  );
  let [description, setDescription] = useState(
    tflData.description ? tflData.description : ""
  );
  let [image, setImage] = useState(tflData.url ? tflData.url : "");
  let [requiredPoints, setRequiredPoints] = useState(
    tflData.RequiredPts ? tflData.RequiredPts : ""
  );
  let [amount, setAmount] = useState(tflData.amount ? tflData.amount : "");
  let [purchasePrice, setPurchasePrice] = useState(
    tflData.purchasePrice ? tflData.purchasePrice : ""
  );
  let [weight, setWeight] = useState(tflData.weight ? tflData.weight : "");
  let [length, setLength] = useState(
    tflData.dimensions.length ? tflData.dimensions.length : ""
  );
  let [breadth, setBreadth] = useState(
    tflData.dimensions.breadth ? tflData.dimensions.breadth : ""
  );
  let [height, setHeight] = useState(
    tflData.dimensions.height ? tflData.dimensions.height : ""
  );
  let [availableQuantity, setavailableQuantity] = useState(
    tflData.availableQuantity ? tflData.availableQuantity : ""
  );
  const [validation, setValidation] = useState(false);
  //  const [ data , setData ] = useState([]);
  let [FileError, setFileError] = useState("");
  let [FileError2, setFileError2] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  console.log("propsssssssssssssss", tflData.productName);

  const handleLogoChange = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      //let errorsObj = { ...errors };
      let imageFile = e.target.files[0];
      let fileName = imageFile.name.toLowerCase();
      if (!fileName.match(/\.(jpg|jpeg|png|svg|gif|mp4)$/)) {
        setFileError("Please select valid image.");
        setValidation(false);
      } else {
        setValidation(true);
        setFileError("");
      }
      // let maxFileSize = 1024 * 10; // 10MB
      if (imageFile.size / 1024 / 1024 > 10) {
        // errorsObj.file.show = true;
        // errorsObj.file.message = "Image must smaller than 10MB!";
        //setErrors(errorsObj);
        notification.open({
          message: "Image must smaller than 10MB!",
          //icon: <Icon type="close-circle" style={{ color: "green" }} />,
          duration: 2
        });
        console.log("file size is tooo large");
        setValidation(false);
      }
      setImage(imageFile);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        // errorsObj.file.show = false;
        // errorsObj.file.message = "";
        // setIcon(reader.result);
      });
      reader.readAsDataURL(imageFile);
    }
    //submitAddvertisement(e);
  };

  // /addTFLProducts
  const addValue2 = async (dimension) => {
    console.log("data ----", image.length);

    let payload = {};
    payload.id = Id;
    payload.productName = productName;
    payload.description = description;
    payload.RequiredPts = requiredPoints;
    payload.amount = amount;
    payload.purchasePrice = purchasePrice;
    payload.availableQuantity = availableQuantity;
    payload.weight = weight;
    payload.dimensions = dimension;
    const token = localStorage.getItem("token");
    let res = await api.post(
      `/api/v1/treeDriveAdmin/appsettings/updateTFLProducts`,
      payload,
      {
        headers: {
          // "Content-Type": "application/json",
          "x-auth-token": token
        }
      }
    );
    if (res.status == 200) {
      notification.open({
        message: "Your product updated successfully!",
        duration: 3
      });
      props.indexPage();
    }

    if (image.length != 0) {
      const formData = new FormData();
      formData.append("id", Id);
      formData.append("image", image, image.name);
      const token = localStorage.getItem("token");
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/updateTFLImage`,
        formData,
        {
          headers: {
            "x-auth-token": token
          }
        }
      );
      if (result.status == 200) {
        notification.open({
          message: "Your product image updated successfully!",
          duration: 3
        });
        props.indexPage();
      }
      console.log("result", result);
    }
  };
  const addValue = () => {
    let data = {};
    data.length = length;
    data.breadth = breadth;
    data.height = height;

    addValue2(data);
  };

  return (
    <div className="add-r-main bg-white pb-2">
      <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
        <Col>
          <h3>{props.edit ? "Edit New Product" : "Add New Product"}</h3>
        </Col>
        <Col>
          <Button onClick={() => props.indexPage()} className="common-btn2">
            Return to the List
          </Button>
        </Col>
      </Row>

      <div className="form-r-main p-2">
        <Form onFinish={handleSubmit} className="form-horizontal box">
          <Row gutter={24} className="flex">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="productName"
                label="Product Name"
                rules={[
                  {
                    required: true,
                    message: "productName is required!"
                  }
                ]}
              >
                <Input
                  onChange={(e) => setProductName(e.target.value)}
                  defaultValue={productName}
                  name="productName"
                  type="Text"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="requiredPoints"
                label="Required Points"
                rules={[
                  {
                    required: true,
                    message: "add Points !"
                  }
                ]}
              >
                <Input
                  onChange={(e) => setRequiredPoints(e.target.value)}
                  defaultValue={requiredPoints}
                  type="Number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="image"
                label="Select Image"
                rules={[
                  {
                    required: true,
                    message: "Image is required!"
                  }
                ]}
              >
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="availableQuantity"
                label="Available Quantity"
                rules={[
                  {
                    required: true,
                    message: "Available Quantity is required!"
                  }
                ]}
              >
                <Input
                  onChange={(e) => setavailableQuantity(e.target.value)}
                  defaultValue={availableQuantity}
                  placeholder="Available Quantity"
                  type="Number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="purchasePrice"
                label="Purchase Price"
                rules={[
                  {
                    required: true,
                    message: "Purchase Price is required!"
                  }
                ]}
              >
                <Input
                  onChange={(e) => setPurchasePrice(e.target.value)}
                  defaultValue={purchasePrice}
                  placeholder="Amount"
                  type="Number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="amount"
                label="Sale Price"
                rules={[
                  {
                    required: true,
                    message: "Amount is required!"
                  }
                ]}
              >
                <Input
                  onChange={(e) => setAmount(e.target.value)}
                  defaultValue={amount}
                  placeholder="Amount"
                  type="Number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="weight"
                label="Product Weight (in gm)"
                rules={[
                  {
                    required: true,
                    message: "Product Weight is required!"
                  }
                ]}
              >
                <Input
                  onChange={(e) => setWeight(e.target.value)}
                  defaultValue={weight}
                  placeholder="Weight"
                  type="Number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="dimesions"
                label="Product Dimensions(in cm)"
                rules={[
                  {
                    required: true,
                    message: "Product Dimensions is required!"
                  }
                ]}

              >
               <div className="flex">
               <Input
                  onChange={(e) => setLength(e.target.value)}
                  defaultValue={length}
                  placeholder="length"
                  type="Number"
                />
                <Input
                  onChange={(e) => setBreadth(e.target.value)}
                  defaultValue={breadth}
                  placeholder="breadth"
                  type="Number"
                />
                <Input
                  onChange={(e) => setHeight(e.target.value)}
                  defaultValue={height}
                  placeholder="height"
                  type="Number"
                />
               </div>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Description is required!"
                  }
                ]}
              >
                <TextArea
                  placeholder="Description"
                  allowClear
                  rows={4}
                  defaultValue={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row align="center">
          <Col>
            {FileError.length > 0 ? (
              <p className="errorMsg">{FileError} </p>
            ) : (
              ""
            )}
            <Button
              type="primary"
              className="city-search m-4"
              onClick={() => addValue()}
            >
              UPDATE
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditTFLProd;
