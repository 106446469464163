import React, { useState } from "react";
import { Input, Table, Button, notification, Row, Icon, Col, Form } from "antd";
import api from "../../commonFuncs/api";
import './Donation.css'
const { TextArea } = Input;

const AddDonation = (props) => {
  
  const [donationType , setDonationType ] = useState("");
  const [description , setDescription ] = useState("");
  const [nok ,setNok] = useState("");
  let [FileError, setFileError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  // /addTFLProducts
  const addValue = async (data) =>{
    const token = localStorage.getItem("token");

    const res = await api.post(
      `/api/v1/treeDriveAdmin/appsettings/donationAdded`,
      data ,
        {
        headers: {
          // "Content-Type": "application/json",
          "x-auth-token": token,
        },
      },
    );
    if(res.status == 200){
      notification.open({
            message: "Your product saved successfully!",
            duration: 3,
          });
          props.indexPage();
    }
  }

  const submitBtn = () =>{
    if(donationType != "" && nok != "" && description != ""){
      let payload = {};
      payload.donationType = donationType;
      payload.description = description;
      payload.NOK = nok;
  
      addValue(payload);
    }else{
      setFileError("Please select all Values")
    }
   
  }


  return (
    <div className="add-r-main  justify-content-center bg-white pb-2">
      <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
        <Col>
          <h3>{props.edit ? "Edit Donation" : "Add New Donation"}</h3>
        </Col>
        <Col>
          <Button onClick={() => props.indexPage()} className="common-btn2">
            Return to the List
          </Button>
        </Col>
      </Row>

      <div className='form-r-main p-2'>
        <Form onFinish={handleSubmit} className="form-horizontal box">
          <Row align="center">
            <Col className="col-12">
              <Form.Item name="donationType" label="Donation Type"
                rules={[
                    {
                      required: true,
                      message: "Donation Type is required!"
                    }
                  ]}>
                <Input
                  onChange={(e) => setDonationType(e.target.value)}
                  value={donationType}
                  className="m-4"
                  //placeholder="Name"
                  style={{ width: "400px" }}
                  type="Text"
                />
              </Form.Item>
            </Col>
          
            <Col className="col-12">
              <Form.Item name="nok" label="NOK"
                rules={[
                    {
                      required: true,
                      message:"Donation NOK is required!"
                    }
                  ]}>
                <Input
                  onChange={(e) => setNok(e.target.value)}
                  value={nok}
                  className="m-4"
                  //placeholder="Required Points"
                  style={{ width: "400px" }}
                  type="Number"
                />
              </Form.Item>
              <Col className="col-12">
              <Form.Item name="description" label="Description" 
              rules={[
                    {
                      required: true,
                      message: "Donation description is required!"
                    }
                  ]}>
            <TextArea
                className="m-4"
               // placeholder="Description"
                allowClear
               //style={{ marginLeft: "25px" }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>
            </Col>
            </Col>
          </Row>
        </Form>
        <Row align="center">
        <Col>
        { FileError.length > 0 ? <p className= "errorMsg">{FileError} </p> : ""}
          <Button
            type="primary"
            className="city-search m-4"
            onClick={() => submitBtn()}
          >
            ADD
          </Button>
        </Col>
      </Row>
      </div>
    </div>
  )
}

export default AddDonation
