import React, { useEffect, useState } from "react";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { NavLink } from 'react-router-dom';
import {
  Table, Space, Row, Col, 
  Input, Tooltip, notification,
  Form, InputNumber, Popconfirm, Typography } from "antd";
import api from "../../commonFuncs/api";
import CustomPopupConfirm from "./CustomPopupConfirm";
const { TextArea } = Input;

//---------------------------------------- cell edit --------------------------------------------- //
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number"  ? <InputNumber  /> : <TextArea 
        autoSize={{
          minRows: 2,
          maxRows: 50,
          minCols: 5,
          maxCols:10}} />;
  return (
    
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};




const CityList = () => {
    const [form] = Form.useForm();
    let  [allData, setAllData] = useState([]);
    const [pageStatus, setPageStatus] = useState("LIST");
    const [inputType, setInputType] = useState("NoEditable");
    const [addId, setAddId] = useState();
    const [isPopup, setPopup] = useState(false);
    const [editingKey, setEditingKey] = useState("");
    const [nok, setNok] = useState("");
    const [Id, setId] = useState("");
    const isEditing = (record) => record.key === editingKey;
  
  
    useEffect(() => {
      listPoint();
    }, []);
  
    //-------------------- new key added (for row wise editing  ) --------------------- //
  
    allData = allData.map((obj , index )=> {
      obj['key'] = index.toString();
      return obj;
    });
  
    const edit = (record) => {
      console.log("edit", record)
      setId(record._id);
      form.setFieldsValue({
        key:"",
        SNo:"",
        points:"",
        requiredPoints: "",
        ...record,
      });
      setEditingKey(record.key);
    };
    const cancel = () => {
      setEditingKey("");
    };
  
  
    // ============ get api for print all the points ============== //
    const indexPage = () => {
      setPageStatus("LIST");
      listPoint();
    };
  

    const listPoint = async () => {
      const token = localStorage.getItem("token");
  
      const res = await api.get(
        `/api/v1/treeDriveAdmin/appsettings/TFLAllcity`,
      );
      if(res.status == 200){
        setAllData(res.data.data)
      }
  
    }
  
  
    // ============= model open when click on delete btn =================== //
  
    const showConformationDialog = (id) => {
      setAddId(id);
      setPopup(true);
    };
    const cancelConformationDialog = () => {
      setPopup(false);
    };
  
    // ======================= delete api =============================== //
  
    const deleteApi = async () => {
      console.log("id == ", addId);
      try {
        let res = await api.post(
          `/api/v1/treeDriveAdmin/appsettings/deleteTFLcity`,
          { id: addId }
        );
  
        if (res.status == 200) {
          setPopup(false);
          notification.open({
            message: "Your data  deleted successfully!",
            duration: 2,
          });
          listPoint();
        }
      } catch (error) {
        console.log("something went wrong");
        setPopup(false);
      }
    };
  
  
  
  // ======================= edit  api =============================== //
  
   
const save = async (key) => {
  try {
    const row = await form.validateFields();
    const newData = [...allData];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setAllData(newData);
      setEditingKey("");
    } else {
      newData.push(row);
      setAllData(newData);
      setEditingKey("");
    }
    if(editingKey){
      console.log("row", row)

      let payload = {};
      payload.id = Id;
      payload.deliveryCharges = row.deliveryCharges;
      const token = localStorage.getItem("token");
      let res = await api.post(
      `/api/v1/treeDriveAdmin/appsettings/updateTFLcity`,
    payload
      , {
        headers: {
          "x-auth-token": token,
        },
      },
    );
    if (res.status == 200) {
      setPopup(false);
      notification.open({
        message: "Your data updated successfully!",
        duration: 2,
      });
      listPoint();
    }
    }
  } catch (errInfo) {
    console.log("Validate Failed:", errInfo);
  }
};
  


    const columns = [
      {
        title: "SNo",
        fixed: "left",
        key: "SNo",
        width: "10%",
        editable: false,
        render: (record, item, index) => {
          return Number(record.key) + 1  ;
        },
      },
      {
        title: "City Name",
        fixed: "left",
        key: "name",
        width: "30%",
        dataIndex: "name",
        editable: false,
        defaultSortOrder:  'ascend', //'descend' , //'ascend' 
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: "Delivery Charges",
        fixed: "left",
        key: "deliveryCharges",
        width: "30%",
        dataIndex: "deliveryCharges",
        editable: true,
        //defaultSortOrder:  'descend', //'descend' , //'ascend' 
        sorter: (a, b) => a.deliveryCharges - b.deliveryCharges,
      },
      {
        title: "Action",
        dataIndex: "action",
        editable: false,
        width: "10%",
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Tooltip   disabled={editingKey !== ""}
              onClick={() => edit(record)} title="Edit" placement="left" color={'green'}>
<span><EditOutlined /></span>
</Tooltip> 
          );
        },
      },
      {
        title: "Action",
        fixed: "right",
        key: "action",
        width: "8%",
        render: (_, record) => (
          <Space size="middle">
        <Tooltip title="View" placement="top" color={'blue'}>
      <span><NavLink to='/viewcity' state={record} ><EyeOutlined /></NavLink></span>
        </Tooltip>
          </Space>
        )
      
      },
      {
        title: "Action",
        fixed: "right",
        key: "action",
        width: "8%",
        render: (_, record) => (
          <Space size="middle">
<Tooltip title="Delete" placement="bottom" color={'red'} > 
<Typography.Link
              disabled={editingKey !== ""}
              onClick={() => showConformationDialog(record._id)
              }
            >
              <DeleteOutlined />
            </Typography.Link>
            </Tooltip>
          </Space>
        )
      },
    ];
    
//==================== edit cell code (antd) ======================== //
const mergedColumns = columns.map((col) => {
  if (!col.editable) {
    return col;
  }
  return {
    ...col,
    onCell: (record) => ({
      record,
      inputType: col.dataIndex === "deliveryCharges" ? "number" : "text",
      dataIndex: col.dataIndex,
      title: col.title,
      editing: isEditing(record),
    }),
  };
});

    return (
      <>
         <div className="user-list">
          <Row className="justify-content-between py-2" >
            <Col>
              <h4>List of Cities</h4>
            </Col>
            <Col>
              <NavLink to='/addcity' className="common-btn">Add City</NavLink>
            </Col>
          </Row>
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={allData}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
              />
            </Form>
        </div>
        <CustomPopupConfirm
          visible={isPopup}
          cancel={cancelConformationDialog}
          productId={addId}
          action={deleteApi}
        />
      </>
    );
}

export default CityList
