import {createSlice} from "@reduxjs/toolkit";

const driverSlice=createSlice({
    name:"drivers",
    initialState:[],
    reducers:{
        fetchDrivers:(state,action)=>{
            state=action.payload;
            return state;
        }
    }
});

export const {fetchDrivers}=driverSlice.actions;
export default driverSlice.reducer;