import React from "react";
import { Navigate,Outlet} from "react-router-dom";
import openNotification from "../../../commonFuncs/notification";
const ProtectedRoute = ({
  redirectPath = '/',
  children,
}) => {
  const token=localStorage.getItem("token");
  if (!token) {
    console.log("inside protected route------>")
    openNotification("error","Not Logged In","You are not logged in.Please Log in.")
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
}

const IsLoggedIn=(props)=>{
  const token=localStorage.getItem("token");
  if(token){
    return <Navigate to="/dashboard" replace />;
  }
  
  return props.children;
}

export{ProtectedRoute,IsLoggedIn} ;