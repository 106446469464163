import openSocket from "socket.io-client";
import endpoint from "./Endpoint";
//export const socket = openSocket.connect();
export const socket = openSocket.connect(`${endpoint}`, {
  reconnect: true
});

socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err.message}`);
});

console.log("socket---------", socket);